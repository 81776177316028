import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../features/userSlice';
import supplyChainReducer from '../features/chainSlice'
import supplierProductReducer from '../features/supplierProductSlice';
import supplierReducer from '../features/supplierSlice';
import productReducer from '../features/productSlice';
import claimReducer from '../features/claimSlice';
import CertificateGoalsReducer from '../features/certificateSlice';
import ClaimCategoryFormReducer from '../features/categoryClaimFormSlice';
import categoryFormReducer from '../features/categoryQuestionFormSlice';
import qustionFormReducer from '../features/questionsFormSlice';
import certificateProofPointReducer from '../features/certificateProofPointSlice';
import singleChainReducer from '../features/ChainByIdSlice'

const appStore = configureStore({
    reducer: {
        user: userReducer,
        supplier:supplierReducer,
        product:supplierProductReducer,
        chain:supplyChainReducer,
        productEngine:productReducer,
        claim:claimReducer,
        certificatesGoals:CertificateGoalsReducer,
        categoryClaimForm:ClaimCategoryFormReducer,
        questionForm:qustionFormReducer,
        categoryForm:categoryFormReducer,
        certificate:certificateProofPointReducer,
        chainId:singleChainReducer,
    },
});

export default appStore;
