// apiSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '../service/http.service';
import { RestUrlsConstants } from '../constants/rest-urls.constants';

const initialState = {
  chainData: [],
  loading: false,
  error: null,
};

export const fetchSupplyChainData = createAsyncThunk(
  'supply-chain',
  async (page, { rejectWithValue }) => {
    try {
        const response = await httpService.get(`${RestUrlsConstants.supplyChain}/?page=${page}`, {
            headers: {
              Authorization: localStorage.getItem('Authorization'),
            },
          });
      return response?.data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const fetchSupplyChainDataWithSearch = createAsyncThunk(
  'supply-chain/dataWithSearch',
  async (term, { rejectWithValue }) => {
    try {
        const response = await httpService.get(`${RestUrlsConstants.supplyChain}/?query=${term}`, {
            headers: {
              Authorization: localStorage.getItem('Authorization'),
            },
          });
      return response?.data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const fetchSupplyChainDataWithProduct = createAsyncThunk(
  'supply-chain/dataWithProduct',
  async ({hasProducts,currentPage}, { rejectWithValue }) => {
    try {
        const response = await httpService.get(`${RestUrlsConstants.supplyChain}/?products=${hasProducts==="Yes"?true:false}&page=${currentPage}`, {
            headers: {
              Authorization: localStorage.getItem('Authorization'),
            },
          });
      return response?.data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const fetchSupplyChainDataWithSort = createAsyncThunk(
  'supply-chain/dataWithSort',
  async ({sort,currentPage}, { rejectWithValue }) => {
    try {
        const response = await httpService.get(`${RestUrlsConstants.supplyChain}/?sortBy=${sort}&orderBy=asc&page=${currentPage}`, {
            headers: {
              Authorization: localStorage.getItem('Authorization'),
            },
          });
      return response?.data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const fetchSupplyChainDataWithSupplier = createAsyncThunk(
  'supply-chain/dataWithSupplier',
  async ({hasSuppliers,currentPage}, { rejectWithValue }) => {
    try {
        const response = await httpService.get(`${RestUrlsConstants.supplyChain}/?suppliers=${hasSuppliers==="Yes"?true:false}&page=${currentPage}`, {
            headers: {
              Authorization: localStorage.getItem('Authorization'),
            },
          });
      return response?.data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
const supplyChainSlice = createSlice({
  name: 'chain',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSupplyChainData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSupplyChainData.fulfilled, (state, action) => {
        state.loading = false;
        state.chainData = action.payload;
      })
      .addCase(fetchSupplyChainData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchSupplyChainDataWithSearch.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSupplyChainDataWithSearch.fulfilled, (state, action) => {
        state.loading = false;
        state.chainData = action.payload;
      })
      .addCase(fetchSupplyChainDataWithSearch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchSupplyChainDataWithProduct.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSupplyChainDataWithProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.chainData = action.payload;
      })
      .addCase(fetchSupplyChainDataWithProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchSupplyChainDataWithSupplier.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSupplyChainDataWithSupplier.fulfilled, (state, action) => {
        state.loading = false;
        state.chainData = action.payload;
      })
      .addCase(fetchSupplyChainDataWithSupplier.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default supplyChainSlice.reducer;
