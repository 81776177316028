import React, { useEffect, useState } from 'react';
import { Tree, TreeNode } from 'react-organizational-chart';
import { Box, Button, Grid, IconButton, Snackbar, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { decryptId } from '../utils/encryptDecrypt';
import SettingsIcon from '@mui/icons-material/Settings';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import SelectSupplier from './selectSupplier';
import SelectProduct from './selectProduct';
import { fetchSupplierProduct } from '../../features/supplierProductSlice';
import { fetchSupplier } from '../../features/supplierSlice';
import UpdateSupplyChain from './UpdateSupplyChain';
import { fetchProducts } from '../../features/productSlice';
// import { TransformWrapper, TransformComponent, useControls } from 'react-zoom-pan-pinch';
// import RestartAltIcon from '@mui/icons-material/RestartAlt';
import PATH from '../utils/path';
import { IoMdArrowBack } from 'react-icons/io';
import Suppliers from './SupplierNode';
import Products from './ProductNode';
import { fetchChainById } from '../../features/ChainByIdSlice';

function Organization({ org, handleSelectSupplier }) {
  return (
    <Box
      sx={{
        borderRadius: 16,
        p: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box sx={{ display: 'flex', background: '#101010', justifyContent: 'space-between', alignItems: 'center', py: 1, borderRadius: 10, gap: '10px', px: 2 }}>
        <Typography sx={{ fontSize: '12px', textAlign: 'center', color: '#fff' }}>{org?.name}</Typography>
        <IconButton onClick={() => handleSelectSupplier(org?.id)}>
          <AddIcon sx={{ color: '#fff', fontSize: '20px' }} />
        </IconButton>
      </Box>
    </Box>
  );
}

// const Controls = () => {
//   const { zoomIn, zoomOut, resetTransform } = useControls();
//   const { t } = useTranslation();
//   return (
//     <Box sx={{ display: 'flex', gap: '10px' }}>
//       <Button size="small" color="success" variant="outlined" onClick={() => zoomIn()}>
//         {t('zoom_in')}
//       </Button>
//       <Button size="small" color="warning" variant="outlined" onClick={() => zoomOut()}>
//         {t('zoom_out')}
//       </Button>
//       <Button startIcon={<RestartAltIcon />} size="small" color="error" variant="outlined" onClick={() => resetTransform()}>
//         {t('reset')}
//       </Button>
//     </Box>
//   );
// };

function Node({ parent }) {
  const params = useParams();
  let currentPage = params?.page;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedSupplyChain, setSelectedSupplyChain] = useState({});
  const [openSelectSupplier, setOpenSelectSupplier] = useState(false);
  const [snackBar, setSnackBar] = useState({ open: false, message: '' });
  const [openSelectProduct, setOpenSelectProduct] = useState(false);
  const [supplierId, setSupplierId] = useState('');
  const [selectedNodeId, setSelectedNodeId] = useState('');
  const [productId, setProductId] = useState('');
  const { productData, loading } = useSelector((state) => state.product);
  const { suppliers } = useSelector((state) => state.supplier);
  const { products, productLoading } = useSelector((state) => state.productEngine);
  const [updateSupplyChain, setUpdateSupplyChain] = useState(false);
  const [page, setPage] = useState(1);
  const { chainById } = useSelector((state) => state.chainId);

  const itemsPerPage = 11;
  const pageCount = Math.ceil(suppliers?.totalRecords / itemsPerPage);
  const snackbarClose = () => {
    setSnackBar({ open: false, message: '' });
  };

  useEffect(() => {
    dispatch(fetchSupplier({ page, itemsPerPage }));
  }, [dispatch, page]);
  useEffect(() => {
    dispatch(fetchChainById(decryptId(params.id)));
  }, [dispatch, params]);
  useEffect(() => {
    setSelectedSupplyChain(chainById);
  }, [chainById]);

  const handleSelectSupplier = () => {
    setOpenSelectSupplier(true);
    // dispatch(fetchSupplier({page,itemsPerPage}));
  };

  const handleSelectProduct = () => {
    setOpenSelectProduct(true);
    dispatch(fetchSupplierProduct(supplierId));
  };

  const closeSelectSupplier = () => {
    setOpenSelectSupplier(false);
  };

  const renderTree = (node) => {
    if (!node) return null;

    if (node.suppliers) {
      return node.suppliers.map((supplier) => (
        <TreeNode
          key={String(supplier._id)}
          label={
            <Suppliers
              supplier={supplier}
              setSelectedNodeId={setSelectedNodeId}
              setSupplierId={setSupplierId}
              handleSelectProduct={handleSelectProduct}
              selectedNodeId={selectedNodeId}
              chainId={selectedSupplyChain?.id}
            />
          }
        >
          {supplier.products &&
            supplier.products.map((product) => (
              <TreeNode
                key={product._id}
                label={
                  <Products
                    products={product}
                    handleSelectSupplier={handleSelectSupplier}
                    setSelectedNodeId={setSelectedNodeId}
                    setProductId={setProductId}
                    setSupplierId={setSupplierId}
                    supplier={supplier}
                    selectedNodeId={selectedNodeId}
                    chainId={selectedSupplyChain?.id}
                  />
                }
              >
                {renderTree(product)}
              </TreeNode>
            ))}
        </TreeNode>
      ));
    }

    if (node.products) {
      return node.products.map((product) => (
        <TreeNode
          key={product._id}
          label={
            <Products
              products={product}
              handleSelectSupplier={handleSelectSupplier}
              setSelectedNodeId={setSelectedNodeId}
              setProductId={setProductId}
              setSupplierId={setSupplierId}
              supplier={node}
              selectedNodeId={selectedNodeId}
              chainId={selectedSupplyChain?.id}
            />
          }
        >
          {renderTree(product)}
        </TreeNode>
      ));
    }

    return null;
  };

  const T = parent
    ? TreeNode
    : (props) => (
        <>
          <Grid className="example" container spacing={2}>
            <Grid item xs={12}>
              <Button
                startIcon={<IoMdArrowBack />}
                onClick={() => navigate(`${PATH.SUPPLYCHAIN}/1`)}
                sx={{ bgcolor: 'none', ':hover': { bgcolor: 'none' }, color: '#000', borderRadius: 5, textTransform: 'capitalize', px: 2 }}
              >
                {t('back')}
              </Button>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Button
                onClick={() => {
                  setUpdateSupplyChain(true);
                  dispatch(fetchProducts());
                }}
                size="small"
                startIcon={<SettingsIcon />}
                sx={{ ml: 2, bgcolor: 'gray', ':hover': { bgcolor: 'gray' }, color: '#ffff', borderRadius: 5, textTransform: 'capitalize', px: 2 }}
              >
                {t('edit_supply_chain')}
              </Button>
            </Grid>
            <Grid className="example" item xs={12} sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', pt: 2 }}>
              {/* <TransformWrapper >
                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                  <>
                  <Box sx={{display:"flex",justifyContent:"left",alignItems:"end"}}>
                  <Controls/>
                  </Box>
                    <TransformComponent contentStyle={{height:"500px",width:"100%"}}>
                    <Box sx={{height:"100%",width:"100%",mt:2}}> */}
              <Tree {...props} lineWidth={'2px'} lineColor={'#bbc'} lineBorderRadius={'12px'}>
                {props.children}
              </Tree>
              {/* </Box>
                    </TransformComponent>
                  </>
                )}
              </TransformWrapper> */}
            </Grid>
          </Grid>
        </>
      );

  return (
    <>
      <T label={<Organization org={selectedSupplyChain} handleSelectSupplier={handleSelectSupplier} />}>{renderTree(selectedSupplyChain)}</T>

      <SelectSupplier
        supplier={suppliers}
        openSelectSupplier={openSelectSupplier}
        closeSelectSupplier={closeSelectSupplier}
        setOpenSelectSupplier={setOpenSelectSupplier}
        selectedSupplyChain={selectedSupplyChain}
        productId={productId}
        selectedNodeId={selectedNodeId}
        setSelectedNodeId={setSelectedNodeId}
        page={page}
        setPage={setPage}
        pageCount={pageCount}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
      />
      <UpdateSupplyChain updateSupplyChain={updateSupplyChain} setUpdateSupplyChain={setUpdateSupplyChain} selectedSupplyChain={selectedSupplyChain} products={products} loading={productLoading} />
      <SelectProduct
        supplierProduct={productData?.supplierProducts}
        openSelectProduct={openSelectProduct}
        setOpenSelectProduct={setOpenSelectProduct}
        supplierId={supplierId}
        setSupplierId={setSupplierId}
        loading={loading}
        selectedSupplyChain={selectedSupplyChain}
        setSelectedSupplyChain={setSelectedSupplyChain}
        selectedNodeId={selectedNodeId}
        setSelectedNodeId={setSelectedNodeId}
        currentPage={currentPage}
      />
      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        message={snackBar.message}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={snackbarClose}
        className="snackBarColor"
        key="snackbar"
      />
    </>
  );
}

export default Node;
