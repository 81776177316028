export const CategoryStatus = {
  pending: 'PENDING',
  selected: 'SELECTED',
  submitted: 'SUBMITTED',
  reviewed: 'REVIEWED',
  verified: 'VERIFIED',
  rejected: 'REJECTED',
  proven: 'PROVEN',
  declared: 'DECLARED',
};

export const RolesStatus = {
  system: 'SYSTEM',
  client: 'CLIENT',
};
export const SupplierMapStatus = {
  product: 'PRODUCT',
  service: 'SERVICE',
};
export const CertificateStatus = {
  pending: 'PENDING',
  valid: 'VALID',
  notValid: 'NOT_VALID',
};
