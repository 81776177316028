import React from 'react';

const OrderEngine = () => {
  return (
    <div>
      <h2>Order Engine Page</h2>
    </div>
  );
};

export default OrderEngine;
