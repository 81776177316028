import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import httpService from '../../service/http.service';
import { RestUrlsConstants } from '../../constants/rest-urls.constants';
import { Box, IconButton, ListItemText, Menu, MenuItem, Typography } from '@mui/material';
import AlertDialog from '../utils/AlertDialog';
import image from '../../images/logo.png';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useParams } from 'react-router-dom';
import { decryptId } from '../utils/encryptDecrypt';
import { fetchChainById } from '../../features/ChainByIdSlice';

function Suppliers({ supplier, setSelectedNodeId, setSupplierId, handleSelectProduct, chainId, selectedNodeId,currentPage }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const handleClick = (event, nodeId, id) => {
    setAnchorEl(event.currentTarget);
    setSupplierId(id);
    setSelectedNodeId(nodeId);
  };
  const params = useParams();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleDeleteProduct = async () => {
    try {
      const response = await httpService.delete(`${RestUrlsConstants.supplyChain}/${chainId}/node/${selectedNodeId}`, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
        },
      });
      if (response?.data?.status) {
        dispatch(fetchChainById(decryptId(params.id)));
        setOpenDialog(false);
      }
    } catch (error) {
      console.log(error?.response?.data?.message);
    }
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleClose = (option) => {
    if (option === t('product_')) {
      handleSelectProduct();
    } else if (option === t('delete')) {
      setOpenDialog(true);
    }
    setAnchorEl(null);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
  };

  const options = [t('product_'), t('delete')];

  return (
    <>
      <Box
        sx={{
          borderRadius: 16,
          p: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            background: '#0e1090',
            justifyContent: 'space-between',
            alignItems: 'center',
            p: 1,
            borderRadius: 10,
            gap: '3px',
          }}
        >
          <img src={supplier?.url ? supplier?.url : image} alt="" height={'40px'} width={'40px'} style={{ borderRadius: '40px' }} />
          <Typography sx={{ fontSize: '12px', cursor: 'pointer', textAlign: 'center', color: '#fff' }}>
            {supplier?.vanityName ? supplier?.vanityName : supplier?.name} <br />
            <span style={{ color: 'gray' }}>
              <LocationOnIcon sx={{ fontSize: '18px', color: '#fff' }} />
              {supplier?.city ? supplier?.city : ''} {supplier?.country ? supplier?.country : ''}
            </span>
          </Typography>
          <IconButton onMouseEnter={(event) => handleClick(event, String(supplier?._id), supplier?.supplierId)} size="small">
            <MoreVertIcon sx={{ color: '#fff', cursor: 'pointer' }} />
          </IconButton>
        </Box>
        <Menu
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleMouseLeave}
          MenuListProps={{
            onMouseLeave: handleMouseLeave,
          }}
        >
          {options.map((option) => (
            <MenuItem key={option} onClick={() => handleClose(option)}>
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Menu>
      </Box>
      <AlertDialog
        btnName={t('delete')}
        title={t('delete_supplier')}
        description={t('are_you_sure_you_want_to_delete_this_chain_supplier')}
        open={openDialog}
        handleClick={handleDeleteProduct}
        handleCloseDialog={handleCloseDialog}
      />
    </>
  );
}

export default Suppliers;
