import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import englishTranslation from "./constants/EnglishTranslation";
import turkishTranslation from "./constants/TurkishTransaltion";
import italianTranslation from "./constants/ItalianTranslation";
import frenchTranslation from "./constants/FrenchTranslation";



i18n
//   .use(i18nBackend)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    // lng: getCurrentLang(),
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: englishTranslation
      },
      tr: {
        translation:turkishTranslation
      },
      it: {
        translation: italianTranslation
      },
      fr: {
        translation: frenchTranslation
      },
    },
  });

export default i18n;