import * as React from 'react';
import { IconButton, MenuItem, Box, Typography, Button, ListItemText, Popover, InputLabel, FormControl, Select } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useTranslation } from 'react-i18next';
import httpService from '../../service/http.service';
import { RestUrlsConstants } from '../../constants/rest-urls.constants';

export default function FilterProduct(props) {
  const { fetchProducts, handleFilterProductCollection, currentPage } = props;
  const { t } = useTranslation();
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
  const open = Boolean(menuAnchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [hasCollection, setHasCollection] = React.useState('');
  const [isArchived, setIsArchived] = React.useState('');
  const [isQrId, setIsQrId] = React.useState('');
  const [collection, setCollection] = React.useState([]);

  const handleMenuOpen = (event) => {
    setMenuAnchorEl(event.currentTarget);
    fetchCollectionList();
  };

  const handleClose = () => {
    setMenuAnchorEl(null);
  };

  const handleFilterProduct = (filterType, value) => {
    if (filterType === 'collection') setHasCollection(value);
    if (filterType === 'archived') setIsArchived(value);
    if (filterType === 'qrId') setIsQrId(value);
    handleFilterProductCollection(
      filterType === 'collection' ? value : hasCollection,
      filterType === 'archived' ? value : isArchived,
      filterType === 'qrId' ? value : isQrId
    );
    handleClose();
  };

  const fetchCollectionList = async () => {
    try {
      const response = await httpService.get(`${RestUrlsConstants.product}/collection`, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
        },
      });
      if (response?.data?.status) {
        setCollection(response?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClearAll = () => {
    fetchProducts(currentPage);
    handleClose();
    setHasCollection('');
    setIsArchived('');
    setIsQrId('');
  };

  return (
    <Box sx={{ minWidth: 170 }}>
      <IconButton onClick={handleMenuOpen} sx={{ bgcolor: '#0e1090', borderRadius: '30px', ':hover': { bgcolor: '#0e1090' } }}>
        <FilterListIcon sx={{ color: '#fff' }} />
      </IconButton>
      <Popover
        id={id}
        open={menuAnchorEl}
        anchorEl={menuAnchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ width: 300, padding: 2, border: '1px solid #ddd', borderRadius: 1 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
              {t('filter')} <FilterAltIcon sx={{ fontSize: '20px' }} />
            </Typography>
            <Button onClick={handleClearAll} sx={{ fontSize: '14px', fontWeight: 'bold', textTransform: 'capitalize', color: '#0e1090' }}>
              {t('clear_all')}
            </Button>
          </Box>

          {/* Collection Filter */}
          <InputLabel sx={{ fontSize: '14px' }}>{t('collection')}</InputLabel>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <Select
              variant="standard"
              size="small"
              value={hasCollection}
              onChange={(e) => handleFilterProduct('collection', e.target.value)}
            >
              {collection?.map((name) => (
                <MenuItem key={name} value={name} sx={{ fontSize: '12px', textTransform: 'capitalize' }}>
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Archived Filter */}
          <InputLabel sx={{ fontSize: '14px' }}>{t('is_archived')}</InputLabel>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <Select
              variant="standard"
              size="small"
              value={isArchived}
              onChange={(e) => handleFilterProduct('archived', e.target.value)}
            >
              {[t('yes'), t('no')].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* QR ID Filter */}
          <InputLabel sx={{ fontSize: '14px' }}>{t('qr_code')}</InputLabel>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <Select
              variant="standard"
              size="small"
              value={isQrId}
              onChange={(e) => handleFilterProduct('qrId', e.target.value)}
            >
              {[t('yes'), t('no')].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Popover>
    </Box>
  );
}
