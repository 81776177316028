import { Box, Button, Card, CardContent, CardMedia, Grid, IconButton, InputAdornment, Menu, MenuItem, Pagination, Snackbar, TextField, Typography } from '@mui/material';
import CubeIcon from '../../images/icons/cubeIcon';
import SearchIcon from '@mui/icons-material/Search';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import img1 from '../../images/claim.jpg';
import httpService from '../../service/http.service';
import { RestUrlsConstants } from '../../constants/rest-urls.constants';
import AlertDialog from '../utils/AlertDialog';
import ProductServiceIcon from '../../images/icons/productServiceIcon';
import CreateProductIcon from '../../images/icons/createProductIcon';
import { SupplierMapStatus } from '../../constants/common.constants';
import FilterSupplierProduct from '../utils/FilterSupplierProduct';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSupplierProduct, fetchSupplierProductWithSearch } from '../../features/supplierProductSlice';
import Loader from '../utils/loader';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { decryptId, encryptId } from '../utils/encryptDecrypt';
import UploadCSVFileDialog from './UploadCSVFileDialog';
import PATH from '../utils/path';

const SupplyForm = () => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [productSuppierId, setProductSupplierId] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [snackBar, setSnackBar] = useState({ open: false, message: '' });
  const open = Boolean(anchorEl);
  const [openUploadCSVSupplier, setOpenUploadCSVSupplier] = useState(false);
  const supplierId = decryptId(params?.supplierId);
  const [openDialog, setOpenDialog] = useState(false);
  const { productData, loading } = useSelector((state) => state.product);
  const [page, setPage] = useState(1);

  const dispatch = useDispatch();
  useEffect(() => {
    if (supplierId) {
      dispatch(fetchSupplierProduct({supplierId,page}));
    }
  }, [dispatch, supplierId,page]);

  const handleSearchChange = async (e) => {
    setSearchTerm(e.target.value);
    const term = e.target.value;
    if (term.trim() === '') {
      dispatch(fetchSupplierProduct(supplierId));
    } else {
      dispatch(fetchSupplierProductWithSearch({ supplierId, term }));
    }
  };
  const handleDuplicateSupplierProduct = async (id, productId) => {
    try {
      const response = await httpService.post(
        `${RestUrlsConstants.supplier}/${id}/product/${productId}/duplicate`,
        {},
        {
          headers: {
            Authorization: localStorage.getItem('Authorization'),
          },
        }
      );
      if (response?.data?.status) {
        setSnackBar({ open: true, message: response?.data?.message });
        dispatch(fetchSupplierProduct(supplierId));
        setProductSupplierId('');
      }
    } catch (error) {
      console.log(error?.response?.data?.message);
      setSnackBar({ open: true, message: error?.response?.data?.message });
    }
  };

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setProductSupplierId(id);
  };
  const handleClose = (option) => {
    if (option === t('edit')) {
      navigate(`${PATH.ADDSUPPLIERPRODUCT}/${encryptId(productSuppierId)}`);
    } else if (option === t('delete')) {
      setOpenDialog(true);
    } else if (option === t('duplicate')) {
      handleDuplicateSupplierProduct(supplierId, productSuppierId);
    }
    setAnchorEl(null);
  };

  const handleDeleteSupplierProduct = async () => {
    try {
      const response = await httpService.delete(`${RestUrlsConstants.supplier}/${supplierId}/product/${productSuppierId}`, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
        },
      });
      if (response?.data?.status) {
        setSnackBar({ open: true, message: response?.data?.message });
        setOpenDialog(false);
        setProductSupplierId('');
        dispatch(fetchSupplierProduct(supplierId));
      }
    } catch (error) {
      console.log(error?.response?.data?.message);
      setSnackBar({ open: true, message: error?.response?.data?.message });
    }
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const snackbarClose = () => {
    setSnackBar({ open: false, message: '' });
  };
  const options = [t('edit'), t('delete'), t('duplicate')];

  const totalPages = Math.ceil(productData?.totalRecords/10);
  const handleSupplierProductPageChange = (event, value) => {
    setPage(value);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Outlet />
        </Grid>
        <Grid item xs={4} mt={'50px'} height={'84vh'} className="example" sx={{ overflowY: 'auto' }}>
          <Card sx={{ boxShadow: 'none', p: 0 }}>
            <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <CubeIcon />
                <span style={{ color: '#000', fontWeight: 'bold', marginRight: '10px' }}>{productData?.totalSuppliersProducts}</span>
                <Typography sx={{ color: '#EC895A', fontWeight: 'bold' }}>{t('products')}</Typography>
              </Box>
              <Box>
                <Button
                  startIcon={<UploadFileIcon sx={{ fontSize: '20px' }} />}
                  sx={{ bgcolor: '#000', color: '#fff', ':hover': { bgcolor: '#000' }, borderRadius: 6, textTransform: 'capitalize', px: 2 }}
                  onClick={() => {
                    setOpenUploadCSVSupplier(true);
                  }}
                >
                  {t('upload_csv')}
                </Button>
                <InfoRoundedIcon sx={{ fontSize: '20px', color: 'gray' }} />
              </Box>
            </CardContent>
          </Card>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
            <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              <TextField
                disabled={!supplierId}
                size="small"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '20px',
                  },
                }}
                variant="outlined"
                placeholder={t('search')}
                value={searchTerm}
                onChange={handleSearchChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <FilterSupplierProduct supplierId={supplierId} />
            </Box>
          </Box>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'end' }} mt={2}>
            {productData?.totalRecords > 0 && (
              <Pagination
                sx={{
                  '& .MuiPaginationItem-root': {
                    color: 'gray',
                  },
                  '& .MuiPaginationItem-root.Mui-selected': {
                    backgroundColor: '#EC895A',
                    color: '#fff',
                  },
                }}
                count={totalPages}
                page={page}
                onChange={handleSupplierProductPageChange}
              />
            )}
          </Grid>
          <Button
            sx={{
              mt: 2,
              width: '100%',
              bgcolor: !supplierId ? 'gray' : '#0e1090',
              textTransform: 'capitalize',
              display: 'flex',
              textAlign: 'justify',
              justifyContent: 'space-between',
              ':hover': { bgcolor: !supplierId ? 'gray' : '#0e1090' },
              color: '#fff',
            }}
            disabled={!supplierId}
            onClick={() => {
              navigate(`${PATH.ADDSUPPLIERPRODUCT}/:productId`);
            }}
          >
            <Box>
              <Typography> {t('add_new_products')}</Typography>
              <Typography sx={{ fontSize: '12px' }}>{t('suppliers_have_products_or_services')}</Typography>
            </Box>
            <Typography>
              <AddIcon sx={{ fontSize: '40px' }} />
            </Typography>
          </Button>
          {loading ? (
            <Loader />
          ) : (
            <>
              {supplierId && (
                <>
                  {productData?.supplierProducts?.map((val, index) => (
                    <Card key={index} sx={{ display: 'flex', position: 'relative', width: '100%', p: 1, borderRadius: 4, mt: 2, pb: '0px', boxShadow: 'none' }}>
                      {val?.urls?.length > 0 && <CardMedia component="img" sx={{ width: 100, height: 170, borderRadius: 4 }} image={val?.urls && val?.urls[0]?.url} alt="" />}
                      <CardContent sx={{ pt: 0, pr: 0, width: '100%' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <Typography sx={{ color: '#000', fontWeight: 'bold', display: 'flex', alignItems: 'center', gap: '5px' }}>
                            {val?.title} {val?.type === SupplierMapStatus.product ? <CreateProductIcon color={'black'} /> : <ProductServiceIcon color={'black'} />}{' '}
                          </Typography>
                          <IconButton
                            sx={{ position: 'absolute', top: '10px', right: '5px', color: '#000' }}
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? 'long-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={(e) => handleClick(e, val?.id)}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            id="long-menu"
                            MenuListProps={{
                              'aria-labelledby': 'long-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={() => handleClose(null)}
                          >
                            {options.map((option) => (
                              <MenuItem key={option} selected={option === 'Pyxis'} onClick={() => handleClose(option)}>
                                {option}
                              </MenuItem>
                            ))}
                          </Menu>
                        </Box>
                        <Typography color="text.secondary" sx={{ fontSize: '12px', fontWeight: 'bold', mt: 2 }}>
                          {t('suppliers_have_products_or_services')}
                        </Typography>
                        <Typography sx={{ color: '#000', fontWeight: 'bold', fontSize: '12px' }}>SKU-{val?.skuId}</Typography>
                        <Typography color="text.secondary" sx={{ fontWeight: 'bold', fontSize: '12px' }}>
                          {val?.titleVanityName?.value}
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', mt: 2 }}>
                          <IconButton
                            onClick={() => navigate(`${PATH.ADDSUPPLIERPRODUCT}/${encryptId(val?.id)}`)}
                            size="small"
                            sx={{ bgcolor: '#0e1090', color: '#fff', borderRadius: '5px', ':hover': { bgcolor: '#0e1090' } }}
                          >
                            <ArrowForwardIosIcon />
                          </IconButton>
                        </Box>
                      </CardContent>
                    </Card>
                  ))}
                </>
              )}
            </>
          )}
        </Grid>
      </Grid>
      <UploadCSVFileDialog setOpenUploadCSVSupplier={setOpenUploadCSVSupplier} openUploadCSVSupplier={openUploadCSVSupplier} />
      <AlertDialog
        btnName={t('delete')}
        title={t('delete_supplier_product')}
        description={t('are_you_sure_you_want_to_delete_this_supplier_product')}
        open={openDialog}
        handleClick={handleDeleteSupplierProduct}
        handleCloseDialog={handleCloseDialog}
      />
      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        message={snackBar.message}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={snackbarClose}
        className="snackBarColor"
        key="snackbar"
      />
    </>
  );
};
export default SupplyForm;
