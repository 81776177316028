import React from 'react';
import { Box, Typography, Button } from '@mui/material';

const PageNotFound = () => {
  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#EFEFEF', // Background color
        color: '#fff',
        textAlign: 'center',
      }}
    >
      {/* Error Icon */}
      <Box
        component="div"
        sx={{
          width: 80,
          height: 80,
          marginBottom: 4,
          background: 'url(https://cdn-icons-png.flaticon.com/512/2748/2748558.png) no-repeat center', // External icon or local file path
          backgroundSize: 'contain',
        }}
      />

      {/* Error Text */}
      <Typography variant="h3" component="h1" gutterBottom>
        404 Error
      </Typography>
      <Typography sx={{color:'#FF6F61'}} variant="h6" component="p" gutterBottom>
        Sorry, page not found
      </Typography>

      {/* Back to Home Button */}
      <Button
        variant="contained"
        onClick={() => (window.location.href = '/')}
        sx={{
          mt: 4,
          backgroundColor: '#000',
          color: '#fff',
          '&:hover': { backgroundColor: '#000' },
        }}
      >
        Back to home
      </Button>
    </Box>
  );
};

export default PageNotFound;
