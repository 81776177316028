import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '../service/http.service';
import { RestUrlsConstants } from '../constants/rest-urls.constants';

const initialState = {
  claimCategoryForm: [],
  loading: false,
  error: null,
};

export const fetchClaimCategoryForm = createAsyncThunk(
  'claim/category/claimForm', 
  async (_, { rejectWithValue }) => {
    try {
      const response = await httpService.get(`${RestUrlsConstants.categoryUrl}?mode=${'SELECTION'}`, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
        },
      });
      return response?.data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data || error.message); 
    }
  }
);

const claimCategorySlice = createSlice({
  name: 'categoryClaimForm',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchClaimCategoryForm.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchClaimCategoryForm.fulfilled, (state, action) => {
        state.loading = false;
        state.claimCategoryForm = action.payload;
      })
      .addCase(fetchClaimCategoryForm.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default claimCategorySlice.reducer;
