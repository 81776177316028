import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { InputLabel, Tooltip } from '@mui/material';

export default function CommonInput(props) {
  const { type, autoComplete, variant, label, handleChange, value, placeholder, required, tooltip, disabled, error, helperText } = props;
  return (
    <Box mt={4}>
      <Box>
        <Tooltip title={tooltip} placement="top-start">
          <InputLabel sx={{ color: '#000', fontWeight: '600', fontSize: '14px', cursor: 'pointer' }}>{label}</InputLabel>
        </Tooltip>
      </Box>
      <TextField
        sx={{
          width: '100%',
          '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
              borderColor: 'none',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'black',
            },
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: '#000',
          },
          '&:hover .MuiInput-underline:before': {
            borderBottom: '1px solid gray',
          },
        }}
        inputProps={{ min: 0 }}
        placeholder={placeholder}
        type={type}
        autoComplete={autoComplete}
        variant={variant}
        onChange={handleChange}
        value={value}
        required={required}
        disabled={disabled}
        error={error}
        helperText={helperText}
      />
    </Box>
  );
}
