import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import httpService from '../../service/http.service';
import { RestUrlsConstants } from '../../constants/rest-urls.constants';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Snackbar,
  Stack,
  TextField,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { deepOrange } from '@mui/material/colors';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { IoCheckmarkDone } from 'react-icons/io5';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { CategoryStatus, RolesStatus } from '../../constants/common.constants';
import BlockIcon from '@mui/icons-material/Block';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UserClaimsDetail from './UserClaimsDetail';
import img1 from '../../images/claim.jpg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { fetchClaimByStatus } from '../../features/claimSlice';
import { IoMdArrowBack } from 'react-icons/io';
import DocumentCard from './DocumentCard';
import { useNavigate } from 'react-router-dom';
import PATH from '../utils/path';
import { encryptId } from '../utils/encryptDecrypt';
import AutorenewIcon from '@mui/icons-material/Autorenew';

const SelectedClaims = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    fetchClaim,
    data,
    claimForm,
    handleClientFormStatus,
    showUserClaimDetail,
    setShowUserClaimDetail,
    claimDetail,
    fetchClaimData,
    setOpenClaimForm,
    setLoading,
    goals,
    loading,
    updatingItemId,
    handleUpdateClaim,
    fetchClaimQuestionForm,
  } = props;
  const [searchTerm, setSearchTerm] = useState('');
  const [filterClaim, setFilterClaim] = useState([]);

  const [snackBar, setSnackBar] = useState({ open: false, message: '' });
  const [ids, setIds] = useState({ claimId: null, catId: null });
  const user = useSelector((store) => store.user);
  const { claimByStatus } = useSelector((state) => state.claim);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchClaimByStatus());
  }, [dispatch]);

  const [expanded, setExpanded] = useState(null);

  const handleExpand = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };
  const claimsData = [
    { status: t('VERIFIED'), color: '#28a745' },
    { status: t('PROVEN'), color: '#218838' },
    { status: t('DECLARED'), color: '#1e7e34' },
    { status: t('REJECTED'), color: '#c82333' },
    { status: t('PENDING'), color: '#0e1090' },
  ];

  useEffect(() => {
    fetchClaim();
  }, []);
  useEffect(() => {
    setFilterClaim(data);
  }, [data]);

  const updateSelectedClaim = async (status, id) => {
    try {
      const response = await httpService.patch(
        `${RestUrlsConstants.categoryUrl}${id}`,
        { selected: status === CategoryStatus.selected ? false : true },
        { headers: { Authorization: localStorage.getItem('Authorization') } }
      );
      if (response?.data?.status) {
        setSnackBar({ open: true, message: `${response?.data?.message}` });
        fetchClaim(id);
      }
    } catch (error) {
      console.error('Error updating claim:', error);
    }
  };

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    if (term.trim() === '') {
      setFilterClaim(data);
    } else {
      const filtered = data.filter((claim) => claim.name.toLowerCase().includes(term.trim().toLowerCase()));
      setFilterClaim(filtered);
    }
  };

  useEffect(() => {
    if (claimByStatus) {
      const statuses = ['VERIFIED', 'PROVEN', 'DECLARED', 'REJECTED'];
      for (let status of statuses) {
        if (claimByStatus[status] && claimByStatus[status].length > 0) {
          const firstClaim = claimByStatus[status][0];
          setIds({ claimId: firstClaim.id, catId: firstClaim.categoryId });
          break;
        }
      }
    }
  }, [claimByStatus]);

  const getClaimStatusLength = () => {
    const statuses = ['VERIFIED', 'PROVEN', 'DECLARED', 'REJECTED'];
    let claimLengths = {};
    for (let status of statuses) {
      if (claimByStatus[status] && claimByStatus[status].length > 0) {
        claimLengths[status] = claimByStatus[status].length;
        break;
      } else {
        claimLengths[status] = 0;
      }
    }

    return claimLengths;
  };

  const lengths = getClaimStatusLength();
  const handleClose = () => {
    setSnackBar({ open: false, message: '' });
  };
  const rotateAnimation = {
    '@keyframes rotate': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
  };

  return (
    <Box mt={2}>
      {user?.userType === RolesStatus.system && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <Card sx={{ borderRadius: '10px', boxShadow: 'none' }}>
              <CardContent>
                <Box>
                  <Typography sx={{ fontWeight: '700', fontSize: '16px' }}>{t('reviewd_category')}</Typography>
                  <Typography sx={{ fontSize: '12px', color: 'gray' }}>{t('these_are_the_claim_which_are_approved_pending_and_rejected_please_take_the_action_as_requested')}</Typography>
                </Box>

                <Box my={2} sx={{ display: 'flex', flexDirection: 'column', columnGap: '10px', rowGap: '20px' }}>
                  {data?.map((item, index) => {
                    return (
                      <>
                        {item?.status === CategoryStatus.reviewed && (
                          <>
                            <Typography mt={2} sx={{ color: '#0e1090', fontWeight: '600' }}>
                              {item?.name} <CheckCircleIcon sx={{ fontSize: '20px', cursor: 'pointer' }} />
                            </Typography>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', columnGap: '10px', rowGap: '20px' }}>
                              {item?.claims?.map((list) => (
                                <Stack direction="row" spacing={1}>
                                  <Chip
                                    label={
                                      <div>
                                        {list?.claimName}
                                        <Chip label={list?.label} size="small" sx={{ ml: 1, height: '20px', fontSize: '10px', bgcolor: '#fff', color: '#000' }} />
                                      </div>
                                    }
                                    onClick={() => {
                                      handleUpdateClaim(item?.id, list?._id);
                                      fetchClaimQuestionForm(item?.id);
                                    }}
                                    sx={{
                                      borderRadius: '30px',
                                      cursor: 'pointer',
                                      color: '#fff',
                                      bgcolor:
                                        list?.claimStatus === CategoryStatus.verified
                                          ? '#28a745'
                                          : list?.claimStatus === CategoryStatus.proven
                                          ? '#218838'
                                          : list?.claimStatus === CategoryStatus.declared
                                          ? '#1e7e34'
                                          : '#c82333',
                                      ':hover': {
                                        bgcolor:
                                          list?.claimStatus === CategoryStatus.verified
                                            ? '#28a745'
                                            : list?.claimStatus === CategoryStatus.proven
                                            ? '#218838'
                                            : list?.claimStatus === CategoryStatus.declared
                                            ? '#1e7e34'
                                            : '#c82333',
                                      },
                                    }}
                                  />
                                </Stack>
                              ))}
                            </Box>
                            <Divider sx={{ width: '100%', background: 'gray' }} />
                          </>
                        )}
                      </>
                    );
                  })}
                </Box>
                <Box>
                  <Typography sx={{ fontWeight: '700', fontSize: '16px' }}>{user?.userType === CategoryStatus.system ? t('categories_to_be_reviewed') : t('requested_category')}</Typography>
                  <Typography sx={{ fontSize: '12px', color: 'gray' }}>{t('these_are_the_claim_which_are_approved_pending_and_rejected_please_take_the_action_as_requested')}</Typography>
                </Box>
                <Box mt={2} sx={{ display: 'flex', flexWrap: 'wrap', columnGap: '10px', rowGap: '20px' }}>
                  {!data?.some((item) => item?.status === CategoryStatus.submitted) && <BlockIcon sx={{ color: 'gray' }} />}
                  {data?.map((item, index) => (
                    <>
                      {item?.status === CategoryStatus.submitted && (
                        <Tooltip title={item.description} placement="top" arrow>
                          <Stack direction="row" spacing={1}>
                            <Chip
                              label={
                                <div>
                                  {item?.name}
                                  <Chip label={CategoryStatus.pending} size="small" sx={{ ml: 1, height: '20px', fontSize: '12px', bgcolor: '#545454', color: '#fff' }} />
                                </div>
                              }
                              sx={{ bgcolor: '#0e1090', color: '#fff' }}
                            />
                          </Stack>
                        </Tooltip>
                      )}
                    </>
                  ))}
                </Box>
              </CardContent>
            </Card>
            <Card sx={{ borderRadius: '10px', boxShadow: 'none', mt: 3 }}>
              <CardContent>
                <>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box>
                      <Typography sx={{ fontWeight: '700', fontSize: '16px' }}>{t('select_category')}</Typography>
                      <Typography sx={{ fontSize: '12px', color: 'gray' }}>
                        {t('select_the_values_you_want_to_claim_to_easily_aggregate_your_data_in_the_section_below_updates_automatically')}
                      </Typography>
                    </Box>
                  </Box>
                  <Box mt={2} sx={{ display: 'flex', flexWrap: 'wrap', columnGap: '10px', rowGap: '20px' }}>
                    {data?.map((item, index) => (
                      <>
                        {item?.status === CategoryStatus.selected && (
                          <Tooltip title={item.description} placement="top" arrow>
                            <Chip
                              label={item?.name}
                              sx={{ bgcolor: '#0e1090', color: '#fff' }}
                              onDelete={() => updateSelectedClaim(item?.status, item?.id)}
                              deleteIcon={<CloseIcon style={{ color: '#fff', fontSize: '18px' }} />}
                            />
                          </Tooltip>
                        )}
                      </>
                    ))}
                  </Box>
                  <Card sx={{ borderRadius: '10px', height: '250px', boxShadow: 'none', border: '1px solid lightgray', mt: 2 }}>
                    <CardContent
                      sx={{
                        overflowY: 'auto',
                        '&::-webkit-scrollbar': {
                          width: '0.4em',
                        },
                        '&::-webkit-scrollbar-track': {
                          boxShadow: 'inset 0 0 6px rgba(0,0,0,0.1)',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          backgroundColor: 'rgba(0,0,0,.1)',
                          borderRadius: 8,
                        },
                        height: '250px',
                      }}
                    >
                      <Box sx={{ pb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography sx={{ fontWeight: '700', fontSize: '16px', display: 'flex', gap: '10px', color: 'gray' }}>
                          {t('suggested_category')}
                          <Avatar sx={{ bgcolor: deepOrange[500], height: '22px', width: '22px', color: '#fff', fontSize: '10px' }}>
                            {filterClaim?.filter((category) => category?.status === 'PENDING')?.length}
                          </Avatar>
                        </Typography>
                        <TextField
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              borderRadius: '20px',
                            },
                          }}
                          size="small"
                          variant="outlined"
                          placeholder={t('search')}
                          value={searchTerm}
                          onChange={(e) => handleSearchChange(e)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton>
                                  <SearchIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', columnGap: '10px', rowGap: '20px' }}>
                        {filterClaim
                          ?.sort((a, b) => a.name.localeCompare(b.name))
                          ?.map((item, index) => (
                            <>
                              {item?.status === CategoryStatus.pending && (
                                <Tooltip title={item.description} placement="top" arrow>
                                  <Chip
                                    sx={{ fontWeight: 'bold' }}
                                    variant="outlined"
                                    label={item.name}
                                    icon={<AddIcon onClick={() => updateSelectedClaim(item?.status, item?.id)} sx={{ color: '#fff', fontSize: '18px', cursor: 'pointer' }} />}
                                  />
                                </Tooltip>
                              )}
                            </>
                          ))}
                      </Box>
                    </CardContent>
                  </Card>
                </>
              </CardContent>
            </Card>
            <Card sx={{ boxShadow: 'none', borderRadius: '10px', mt: 3, p: 3 }}>
              <Grid container rowSpacing={3}>
                <Grid item xs={12}>
                  <Box>
                    <Typography sx={{ fontWeight: '700', fontSize: '16px' }}>{t('set_of_questions_to_be_answered')}</Typography>
                    <Typography sx={{ fontSize: '12px', color: 'gray' }}>
                      {t('select_the_values_you_want_to_claim_to_easily_aggregate_your_data_in_the_section_below_updates_automatically')}
                    </Typography>
                  </Box>
                </Grid>
                {claimForm?.map((val, index) => (
                  <Grid key={index} item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6}>
                        <CardMedia component="img" sx={{ height: '200px', width: '100%', borderRadius: '20px' }} image={val.imageUrl} title={val.title} />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Grid container spacing={8}>
                          <Grid item xs={12}>
                            <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }} variant="h6">
                              {val.title}
                            </Typography>
                            <Typography sx={{ fontSize: '12px' }} color="text.secondary">
                              {val.shortDescription}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Box>
                              <Typography sx={{ fontWeight: 'bold', fontSize: '16px' }}>
                                {val.totalAnswers}/{val.totalQuestions}
                              </Typography>
                              <Typography sx={{ fontWeight: 'bold', fontSize: '16px', color: 'gray' }}>{t('questions_answered')}</Typography>
                            </Box>
                            <Box>
                              <Button
                                size="small"
                                variant="contained"
                                sx={{ borderRadius: '20px', background: '#0e1090', ':hover': { background: '#0e1090' }, textTransform: 'capitalize' }}
                                onClick={() => {
                                  navigate(`${PATH.QUESTIONFORM}/${encryptId(val?.id)}`);
                                }}
                              >
                                {t('verify')}
                                <NavigateNextIcon />
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Card>
            <Card sx={{ borderRadius: '10px', boxShadow: 'none', mt: 3 }}>
              <CardContent>
                <Box>
                  <Typography sx={{ fontWeight: '700', fontSize: '16px' }}>{t('submit_the_detail_and_wait_for_approval')}</Typography>
                  <Typography sx={{ fontSize: '12px', color: 'gray' }}>{t('select_the_values_you_want_to_claim_to_easily_aggregate_your_data_in_the_section_below_updates_automatically')}</Typography>
                  <Box sx={{ mt: 2 }}>
                    <Button
                      onClick={handleClientFormStatus}
                      sx={{ fontWeight: 'bold', textTransform: 'none', color: '#fff', borderRadius: '10px', background: '#0e1090', ':hover': { background: '#0e1090' } }}
                    >
                      <IoCheckmarkDone style={{ fontSize: '20px' }} />
                      {t('submit')}
                    </Button>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Card sx={{ borderRadius: '10px', boxShadow: 'none' }}>
              <CardContent>
                <Box>
                  <Typography sx={{ fontWeight: 'bold', fontSize: '16px', color: '#000' }}>{t('review_categories')} </Typography>
                  <Typography sx={{ color: 'gray', fontSize: '12px' }}>{t('select_the_value_you_want_to_claim_to_easily_aggregate_your_data_in_the_section_below')} </Typography>
                </Box>
                <Box mt={2} sx={{ display: 'flex', gap: '20px', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Box>
                    <span style={{ fontWeight: 'bold' }}>{data?.filter((category) => category?.status === CategoryStatus.submitted || category?.status === CategoryStatus.reviewed)?.length}</span>
                    <br />
                    <span style={{ fontWeight: 'bold', color: 'gray' }}>{t('categories')}</span>
                  </Box>
                  <Button
                    variant="contained"
                    sx={{ borderRadius: '20px', background: '#0e1090', ':hover': { background: '#0e1090' }, textTransform: 'capitalize' }}
                    onClick={() => {
                      setOpenClaimForm(true);
                      setLoading(true);
                      fetchClaim();
                    }}
                    disabled={data?.filter((category) => category?.status === CategoryStatus.submitted || category?.status === CategoryStatus.reviewed)?.length === 0}
                  >
                    {t('review')}
                    <NavigateNextIcon />
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
      {user?.userType === RolesStatus.client && (
        <Grid container spacing={2}>
          {!showUserClaimDetail ? (
            <>
              <Grid item xs={12} sm={12} md={8} lg={8}>
                <DocumentCard />
                <Card sx={{ borderRadius: '10px', boxShadow: 'none', mt: 3 }}>
                  <CardContent>
                    <>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box>
                          <Typography sx={{ fontWeight: '700', fontSize: '16px' }}>{t('select_category')}</Typography>
                          <Typography sx={{ fontSize: '12px', color: 'gray' }}>{t('select_the_value_you_want_to_communicate')}</Typography>
                        </Box>
                        <TextField
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              borderRadius: '20px',
                            },
                          }}
                          size="small"
                          variant="outlined"
                          placeholder={t('search')}
                          value={searchTerm}
                          onChange={(e) => handleSearchChange(e)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton>
                                  <SearchIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                      <Card sx={{ borderRadius: '10px', boxShadow: 'none', mt: 2 }}>
                        <CardContent sx={{ bgcolor: '#f2f2f2' }}>
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', columnGap: '10px', rowGap: '20px' }}>
                            {filterClaim
                              ?.sort((a, b) => a.name.localeCompare(b.name))
                              ?.map((item, index) => (
                                <Box key={index}>
                                  {(item?.status === CategoryStatus.pending || item?.status === CategoryStatus.selected) && (
                                    <Tooltip title={item.description} placement="top" arrow>
                                      <Chip
                                        sx={{
                                          fontWeight: 'bold',
                                          boxShadow: '1px 1px 1px #e0e0e0',
                                          bgcolor: item?.status === CategoryStatus.selected ? '#0e1090' : '#fff',
                                          color: item?.status === CategoryStatus.selected ? '#fff' : '#000',
                                          ':hover': { bgcolor: item?.status === CategoryStatus.selected ? '#0e1090' : '#fff' },
                                        }}
                                        label={
                                          <div>
                                            {item.name}
                                            {loading && item?.id === updatingItemId ? (
                                              <AutorenewIcon sx={{ fontSize: '18px', ml: 1, animation: 'rotate 1s linear infinite', ...rotateAnimation }} />
                                            ) : (
                                              item?.status === CategoryStatus.selected && <CheckCircleIcon sx={{ fontSize: '18px', color: '#fff', ml: 1 }} />
                                            )}
                                          </div>
                                        }
                                        onClick={() => updateSelectedClaim(item?.status, item?.id)}
                                      />
                                    </Tooltip>
                                  )}
                                </Box>
                              ))}
                          </Box>
                        </CardContent>
                      </Card>
                    </>
                    <Box sx={{ display: 'flex', justifyContent: 'end', mt: 2 }}>
                      <Button
                        onClick={() => navigate(PATH.CLAIMMANAGEMENTFORM)}
                        variant="contained"
                        sx={{ textTransform: 'capitalize', borderRadius: '20px', background: '#0e1090', ':hover': { background: '#0e1090' } }}
                      >
                        {t('next')}
                        <NavigateNextIcon />
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Card sx={{ borderRadius: '10px', padding: '0px', boxShadow: 'none' }}>
                  <CardContent>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Typography sx={{ fontWeight: 'bold', fontSize: '14px', color: '#000' }}>{t('reviewed_claims')}</Typography>
                      {(lengths?.VERIFIED !== 0 || lengths?.PROVEN !== 0 || lengths?.DECLARED !== 0 || lengths?.REJECTED !== 0) && (
                        <Button onClick={() => fetchClaimData(ids.claimId)} size="small" sx={{ textTransform: 'capitalize', color: '#0e1090' }}>
                          {t('view_all')}
                        </Button>
                      )}
                    </Box>
                    <Box>
                      {claimsData.map((claim, index) => (
                        <Accordion
                          key={claim.status}
                          expanded={expanded === index}
                          onChange={handleExpand(index)}
                          sx={{
                            backgroundColor: expanded === index ? '#f2f2f2' : 'white',
                            boxShadow: 'none',
                          }}
                        >
                          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${claim.status}-content`} id={`${claim.status}-header`}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                              <Avatar sx={{ bgcolor: claim.color, height: '30px', width: '30px', color: '#fff', fontSize: '16px' }}>
                                {claim.status === CategoryStatus.pending
                                  ? data?.filter((category) => category?.status === CategoryStatus.submitted)?.length
                                  : !claimByStatus[claim.status]
                                  ? `0`
                                  : claimByStatus[claim.status]?.length}
                              </Avatar>
                              <Typography sx={{ flexShrink: 0, fontSize: '14px', fontWeight: 'bold' }}>
                                {claim.status}
                                <Tooltip title={claim.status} placement="top">
                                  <InfoRoundedIcon sx={{ fontSize: '18px', color: 'lightgray' }} />
                                </Tooltip>
                              </Typography>
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails sx={{ borderBottom: 'none' }}>
                            {claim.status === CategoryStatus.pending ? (
                              <Box my={2} sx={{ display: 'flex', flexWrap: 'wrap', columnGap: '10px', rowGap: '20px' }}>
                                {data?.map((item, index) => (
                                  <>
                                    {item?.status === CategoryStatus.submitted && (
                                      <Stack direction="row" spacing={1}>
                                        <Chip
                                          onClick={() => navigate(`${PATH.CATEGORYQUESTIONS}/${encryptId(item?.id)}`)}
                                          label={
                                            <div>
                                              {item?.name}
                                              <Chip label={'Pending'} size="small" sx={{ ml: 1, height: '20px', fontSize: '10px', bgcolor: '#fff', color: '#000' }} />
                                            </div>
                                          }
                                          sx={{
                                            borderRadius: '30px',
                                            cursor: 'pointer',
                                            background: '#0e1090',
                                            ':hover': { background: '#0e1090' },
                                            color: '#fff',
                                          }}
                                        />
                                      </Stack>
                                    )}
                                  </>
                                ))}
                              </Box>
                            ) : (
                              <Box sx={{ display: 'flex', flexWrap: 'wrap', columnGap: '10px', rowGap: '20px' }}>
                                {claimByStatus[claim.status]?.map((item, index) => (
                                  <Stack key={index} direction="row" spacing={1}>
                                    <Chip
                                      label={
                                        <div>
                                          {item?.claimName}
                                          <Chip label={item?.label} size="small" sx={{ ml: 1, height: '20px', fontSize: '10px', bgcolor: '#fff', color: '#000' }} />
                                        </div>
                                      }
                                      onClick={() => {
                                        if (user?.userType === RolesStatus.client) {
                                          fetchClaimData(item?.id);
                                        }
                                      }}
                                      sx={{
                                        borderRadius: '30px',
                                        cursor: 'pointer',
                                        color: '#fff',
                                        bgcolor:
                                          item?.claimStatus === CategoryStatus.verified
                                            ? '#28a745'
                                            : item?.claimStatus === CategoryStatus.proven
                                            ? '#218838'
                                            : item?.claimStatus === CategoryStatus.declared
                                            ? '#1e7e34'
                                            : '#c82333',
                                        ':hover': {
                                          bgcolor:
                                            item?.claimStatus === CategoryStatus.verified
                                              ? '#28a745'
                                              : item?.claimStatus === CategoryStatus.proven
                                              ? '#218838'
                                              : item?.claimStatus === CategoryStatus.declared
                                              ? '#1e7e34'
                                              : '#c82333',
                                        },
                                      }}
                                    />
                                  </Stack>
                                ))}
                              </Box>
                            )}
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    </Box>
                  </CardContent>
                </Card>
                <Card sx={{ borderRadius: '10px', padding: '0px', boxShadow: 'none', mt: 3 }}>
                  <CardContent sx={{ p: 3 }}>
                    <Box sx={{ flexDirection: 'column', justifyContent: 'space-between' }}>
                      <Box>
                        <Typography sx={{ fontWeight: 'bold', fontSize: '16px', color: '#000' }}>{t('goals_targets')} </Typography>
                        <Typography sx={{ color: 'gray', fontSize: '12px' }}>{t('your_ongoing_jurney_as_a_transparent_and_commited_companies_continues_here')} </Typography>
                      </Box>
                    </Box>
                    <Box mt={2} sx={{ height: '150px', width: '100%' }}>
                      <img src={img1} style={{ height: '100%', width: '100%', objectFit: 'cover', objectPosition: 'center', borderRadius: '10px' }} alt="" />
                    </Box>
                    <Box mt={2} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'gray' }}>
                        <span style={{ fontSize: '16px', color: '#000' }}>{goals?.length}</span>
                        <br />
                        {t('goals_sets')}
                      </Typography>
                      <Button
                        variant="contained"
                        disabled={goals?.length > 0 ? false : true}
                        sx={{ borderRadius: '20px', background: '#0e1090', ':hover': { background: '#0e1090' } }}
                        onClick={() => {
                          navigate(`${PATH.QUESTIONFORM}/goals`);
                        }}
                      >
                        {t('begin')}
                        <NavigateNextIcon />
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <Button
                  startIcon={<IoMdArrowBack />}
                  onClick={() => setShowUserClaimDetail(false)}
                  sx={{ bgcolor: 'none', ':hover': { bgcolor: 'none' }, color: '#000', borderRadius: 5, textTransform: 'capitalize', px: 2 ,mb:2}}
                >
                  {t('back')}
                </Button>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={8}>
                <UserClaimsDetail setShowUserClaimDetail={setShowUserClaimDetail} claimDetail={claimDetail} />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Card>
                  <CardContent>
                    <Typography sx={{ fontWeight: '600', fontSize: '16px', display: 'flex', gap: '10px', color: '#000' }}>{t('categories_and_claims')}</Typography>
                    <Box mt={2}>
                      {claimsData.map((claim, index) => (
                        <Accordion
                          key={claim.status}
                          expanded={expanded === index}
                          onChange={handleExpand(index)}
                          sx={{
                            backgroundColor: expanded === index ? 'rgba(0, 0, 0, 0.04)' : 'white',
                            boxShadow: 'none',
                          }}
                        >
                          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${claim.status}-content`} id={`${claim.status}-header`}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <Typography sx={{ flexShrink: 0, fontSize: '14px', fontWeight: 'bold', display: 'flex', gap: '5px' }}>
                                {claim.status}
                                <Avatar sx={{ bgcolor: claim.color, height: '25px', width: '25px', color: '#fff', fontSize: '14px' }}>
                                  {claim.status === CategoryStatus.pending
                                    ? data?.filter((category) => category?.status === CategoryStatus.submitted)?.length
                                    : !claimByStatus[claim.status]
                                    ? `0`
                                    : claimByStatus[claim.status]?.length}
                                </Avatar>
                              </Typography>
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails sx={{ borderBottom: 'none' }}>
                            {claim.status === CategoryStatus.pending ? (
                              <Box my={2} sx={{ display: 'flex', flexWrap: 'wrap', columnGap: '10px', rowGap: '20px' }}>
                                {data?.map((item, index) => (
                                  <>
                                    {item?.status === CategoryStatus.submitted && (
                                      <Stack direction="row" spacing={1}>
                                        <Chip
                                          onClick={() => navigate(`${PATH.CATEGORYQUESTIONS}/${encryptId(item?.id)}`)}
                                          label={
                                            <div>
                                              {item?.name}
                                              <Chip label={'Pending'} size="small" sx={{ ml: 1, height: '20px', fontSize: '10px', bgcolor: '#fff', color: '#000' }} />
                                            </div>
                                          }
                                          sx={{
                                            borderRadius: '30px',
                                            cursor: 'pointer',
                                            background: '#0e1090',
                                            ':hover': { background: '#0e1090' },
                                            color: '#fff',
                                          }}
                                        />
                                      </Stack>
                                    )}
                                  </>
                                ))}
                              </Box>
                            ) : (
                              <Box sx={{ display: 'flex', flexWrap: 'wrap', columnGap: '10px', rowGap: '20px' }}>
                                {claimByStatus[claim.status]?.map((item, index) => (
                                  <Stack key={index} direction="row" spacing={1}>
                                    <Chip
                                      label={
                                        <div>
                                          {item?.claimName}
                                          <Chip label={item?.label} size="small" sx={{ ml: 1, height: '20px', fontSize: '10px', bgcolor: '#fff', color: '#000' }} />
                                        </div>
                                      }
                                      onClick={() => {
                                        if (user?.userType === RolesStatus.client) {
                                          fetchClaimData(item?.id);
                                        }
                                      }}
                                      sx={{
                                        borderRadius: '30px',
                                        cursor: 'pointer',
                                        color: '#fff',
                                        bgcolor:
                                          item?.claimStatus === CategoryStatus.verified
                                            ? '#28a745'
                                            : item?.claimStatus === CategoryStatus.proven
                                            ? '#218838'
                                            : item?.claimStatus === CategoryStatus.declared
                                            ? '#1e7e34'
                                            : '#c82333',
                                        ':hover': {
                                          bgcolor:
                                            item?.claimStatus === CategoryStatus.verified
                                              ? '#28a745'
                                              : item?.claimStatus === CategoryStatus.proven
                                              ? '#218838'
                                              : item?.claimStatus === CategoryStatus.declared
                                              ? '#1e7e34'
                                              : '#c82333',
                                        },
                                      }}
                                    />
                                  </Stack>
                                ))}
                              </Box>
                            )}
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </>
          )}
        </Grid>
      )}

      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        message={snackBar.message}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handleClose}
        className="snackBarColor"
        key="snackbar"
      />
    </Box>
  );
};

export default SelectedClaims;
