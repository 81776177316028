import { Avatar, Box, Card, CardContent, Divider, Grid, Paper, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import httpService from '../service/http.service';
import { RestUrlsConstants } from '../constants/rest-urls.constants';
import moment from 'moment';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DownloadIcon from '@mui/icons-material/Download';

const UserProfile = () => {
  const { t } = useTranslation();
  const user = useSelector((store) => store.user);
  const [brand, setBrand] = useState({});
  let brandId = user?.brand?.id;
  const handleUpdateBrand = async (id) => {
    try {
      const response = await httpService.get(`${RestUrlsConstants.brandUrl}/${id}`, {
        headers: { Authorization: localStorage.getItem('Authorization') },
      });
      if (response?.data?.status) {
        setBrand(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching brands:', error);
    }
  };
  useEffect(() => {
    handleUpdateBrand(brandId);
  }, [brandId]);
  let valueStyle = {
    fontSize: '14px',
    color: 'gray',
    fontWeight: 600,
  };
  let headStyle = {
    fontSize: '14px',
    fontWeight: 600,
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Card sx={{ borderRadius: '20px', width: '500px' }}>
            <CardContent sx={{}}>
              <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                <Avatar variant="square" sx={{ background: '#ec895a', height: '100px', width: '100px', borderRadius: '5px' }} alt={user?.name} src="/broken-image.jpg">
                  {user?.name?.split(' ')[0] && user?.name.split(' ')[0][0] && user?.name.split(' ')[0][0].toUpperCase()}
                  {user?.name?.split(' ')[1] && user?.name.split(' ')[1][0] && user?.name.split(' ')[1][0].toUpperCase()}
                </Avatar>
                <Typography mt={1} sx={{ fontSize: '16px', color: 'gray', textTransform: 'capitalize' }}>
                  {user?.name}
                </Typography>
              </Box>

              <Box className="example" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', overflowX: 'scroll' }}>
                <Typography mt={4} sx={{ fontSize: '16px', padding: '0px', fontWeight: 600 }}>
                  {t('user_details')}
                </Typography>
                <Divider sx={{ mb: 1, height: '1px', background: '#101010' }} />
                <Grid container rowSpacing={2}>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        ...headStyle,
                      }}
                    >
                      {t(`full_name`)}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ ...valueStyle, textTransform: 'capitalize' }}>{user?.name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        ...headStyle,
                      }}
                    >
                      {t(`email_address`)}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ ...valueStyle }}>{user?.email}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        ...headStyle,
                      }}
                    >
                      {t(`brand_name`)}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ ...valueStyle, textTransform: 'capitalize' }}>{user?.brand?.name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        ...headStyle,
                      }}
                    >
                      {t(`unique_Id`)}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ ...valueStyle, textTransform: 'capitalize' }}>{brand?.uniqueId}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        ...headStyle,
                      }}
                    >
                      {t(`max_users`)}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ ...valueStyle, textTransform: 'capitalize' }}>{brand?.maxUsers}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        ...headStyle,
                      }}
                    >
                      {t(`max_products`)}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ ...valueStyle, textTransform: 'capitalize' }}>{brand?.maxProducts}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        ...headStyle,
                      }}
                    >
                      {t(`features`)}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ ...valueStyle, textTransform: 'capitalize' }}>{brand?.features?.join(', ')}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        ...headStyle,
                      }}
                    >
                      {t(`contract_start_date`)}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ ...valueStyle, textTransform: 'capitalize' }}>{moment(brand.contractStartDate).format('DD-MM-YYYY')}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        ...headStyle,
                      }}
                    >
                      {t(`contract_end_date`)}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ ...valueStyle, textTransform: 'capitalize' }}>{moment(brand.contractEndDate).format('DD-MM-YYYY')}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        ...headStyle,
                      }}
                    >
                      {t(`logo`)}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Paper sx={{ height: '50px', width: '50px', borderRadius: '5px' }}>
                      <Avatar variant="square" sx={{ height: '100%', width: '100%', borderRadius: '5px' }}>
                        <img src={brand?.logo} alt="" height={'100%'} width={'100%'} />
                      </Avatar>
                    </Paper>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        ...headStyle,
                      }}
                    >
                      {t(`contract_files`)}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sx={{ display: 'flex', flexWrap: 'nowrap', gap: '15px' }}>
                    {brand?.contractFiles?.map((file, index) => (
                      <Paper key={index} sx={{ height: '50px', width: '50px', borderRadius: '5px', position: 'relative' }}>
                        <Avatar variant="square" sx={{ bgcolor: '#f44336', height: '100%', width: '100%', borderRadius: '5px' }}>
                          <PictureAsPdfIcon />
                        </Avatar>
                        <a className="download1" style={{ position: 'absolute', top: -10, right: -10 }} href={`${file}`} rel="noopener noreferrer">
                          <DownloadIcon
                            sx={{
                              fontSize: '16px',
                              color: '#fff',
                              backgroundColor: '#101010',
                              borderRadius: '10px',
                              zIndex: 100,
                              cursor: 'pointer',
                            }}
                          />
                        </a>
                        <Tooltip title={file?.substring(file?.lastIndexOf('/') + 1)}>
                       <Typography sx={{width:"100%", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',fontSize:"10px"}}>{file?.substring(file?.lastIndexOf('/') + 1)}</Typography>
                       </Tooltip>
                      </Paper>
                    ))}
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};
export default UserProfile;
