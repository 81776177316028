import { useNavigate } from 'react-router-dom';
import useLoginHook from '../hooks/login.hook';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';

const Login = () => {
  const { showPassword, snackBar, email, password, handleClickShowPassword, handleClose, handleMouseDownPassword, signIn } = useLoginHook();

  const navigate = useNavigate();

  const handleForgotPasswordClick = () => {
    navigate('/forgot-password');
  };

  return (
    <div className="w-100">
      <div className="m-top-6">
        <div className="w-100 center">
          <img src={require('../images/renoon_app_icon.png')} alt="panda" className="w-5 b-radius-20 m-50px"></img>
        </div>
        <div className="w-100 center">
          <div className="login-container">
            <div>
              <p className="login-text">Welcome to Renoon</p>
              <form onSubmit={(e) => e.preventDefault()} className="p-hor-15">
                <TextField placeholder="Email" id="outlined-basic" label="Email" variant="outlined" fullWidth inputRef={email} required type="email" />
                <FormControl variant="outlined" fullWidth margin="normal" required>
                  <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                  <OutlinedInput
                    inputRef={password}
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                    inputProps={{ minLength: 8 }}
                  />
                </FormControl>
                <Button
                  variant="contained"
                  fullWidth
                  type="submit"
                  style={{
                    background: '#101010',
                    fontWeight: '1000',
                    marginTop: '10px',
                  }}
                  onClick={() => signIn()}
                >
                  Log In
                </Button>
                <Button
                  fullWidth
                  style={{
                    marginTop: '10px',
                  }}
                  onClick={handleForgotPasswordClick}
                >
                  Forgot Password?
                </Button>
              </form>
            </div>
            <Snackbar
              open={snackBar.open}
              autoHideDuration={2000}
              message={snackBar.message}
              anchorOrigin={{
                vertical: snackBar.vertical,
                horizontal: snackBar.horizontal,
              }}
              onClose={handleClose}
              key={snackBar.vertical + snackBar.horizontal}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
