export const RestUrlsConstants = {
  loginUrl: `${process.env.REACT_APP_DASHBOARD_API_URL}/user/login`,
  userUrl: `${process.env.REACT_APP_DASHBOARD_API_URL}/user/`,
  brandUrl: `${process.env.REACT_APP_DASHBOARD_API_URL}/brand/`,
  authUrl: `${process.env.REACT_APP_DASHBOARD_API_URL}/user/auth/`,
  claimUrl: `${process.env.REACT_APP_DASHBOARD_API_URL}/claims/`,
  categoryUrl: `${process.env.REACT_APP_DASHBOARD_API_URL}/category/`,
  roleUrl: `${process.env.REACT_APP_DASHBOARD_API_URL}/role/`,
  verifyEmailUrl: `${process.env.REACT_APP_DASHBOARD_API_URL}/user/verify-email`,
  formUrl: `${process.env.REACT_APP_DASHBOARD_API_URL}/forms/`,
  formQueUrl: `${process.env.REACT_APP_DASHBOARD_API_URL}/forms/question`,
  goalCertificateUrl: `${process.env.REACT_APP_DASHBOARD_API_URL}/category/goals-certifications`,
  certificateVarify: `${process.env.REACT_APP_DASHBOARD_API_URL}/certificate`,
  certificateDownload: `${process.env.REACT_APP_DASHBOARD_API_URL}/certificate/download`,
  product: `${process.env.REACT_APP_DASHBOARD_API_URL}/products`,
  product_care_instruction: `${process.env.REACT_APP_DASHBOARD_API_URL}/products/care-instruction`,
  product_search: `${process.env.REACT_APP_DASHBOARD_API_URL}/products/search-product`,
  product_image_delete: `${process.env.REACT_APP_DASHBOARD_API_URL}/products/delete-image`,
  sort_product: `${process.env.REACT_APP_DASHBOARD_API_URL}/products/filter`,
  supplier: `${process.env.REACT_APP_DASHBOARD_API_URL}/supplier`,
  supplierProduct: `${process.env.REACT_APP_DASHBOARD_API_URL}/product`,
  supplyChain: `${process.env.REACT_APP_DASHBOARD_API_URL}/supply-chain`,
  CSVFILES: `${process.env.REACT_APP_DASHBOARD_API_URL}/files`,
  QRDOWNLOAD: `${process.env.REACT_APP_DASHBOARD_API_URL}/products/getQRPDF`,
  CERTIFICATE:`${process.env.REACT_APP_DASHBOARD_API_URL}/certificate`
};
