import React, { useState, useEffect } from 'react';
import httpService from '../../service/http.service';
import { RestUrlsConstants } from '../../constants/rest-urls.constants';
import { Box, Card, CardContent, Chip, IconButton, InputAdornment, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Loader from '../utils/loader';

const CSVFiles = () => {
  const { t } = useTranslation();
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterFile, setFilterFiles] = useState([]);

  useEffect(() => {
    fetchFiles();
  }, []);
  useEffect(() => {
    setFilterFiles(files);
  }, [files]);

  const fetchFiles = async () => {
    setLoading(true);
    try {
      const response = await httpService.get(`${RestUrlsConstants.CSVFILES}`, {
        headers: { Authorization: localStorage.getItem('Authorization') },
      });
      setFiles(response.data.data);
    } catch (error) {
      console.error('Error fetching brands:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    if (term.trim() === '') {
      fetchFiles();
    } else {
      const filtered = files?.filter((file) => {
        const lowerCaseTerm = term.trim().toLowerCase();
        const matchesName = file.name.toLowerCase().includes(lowerCaseTerm);
        const matchesDate = file.date && new Date(file.date).toLocaleDateString().includes(lowerCaseTerm);
        const matchesType = file.type.toLowerCase().includes(lowerCaseTerm);
        const matchesDescription = file.description.toLowerCase().includes(lowerCaseTerm);

        return matchesName || matchesDate || matchesType || matchesDescription;
      });

      setFilterFiles(filtered);
    }
  };

  const columns = [t('name'), t('file_type'), t('date'), t('description'), t('download')];

  return (
    <>
      <Card className="example" sx={{ borderRadius: '20px', bgcolor: '#fff' }}>
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography sx={{ fontWeight: 'bold', fontSize: '24px' }}>{t('files')}</Typography>
            <TextField
              size="small"
              variant="outlined"
              placeholder={t('search')}
              value={searchTerm}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '20px',
                },
              }}
              onChange={handleSearchChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box>
            <TableContainer>
              <Table sx={{ cursor: 'pointer' }}>
                <TableHead>
                  <TableRow>
                    {columns.map((column, index) => (
                      <TableCell sx={{ fontWeight: '700', fontSize: '12.5px' }} key={index}>
                        {column}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell colSpan={10}>
                        <Loader />
                      </TableCell>
                    </TableRow>
                  ) : (
                    <>
                      {filterFile?.map((file) => (
                        <TableRow key={file.id}>
                          <TableCell sx={{ fontWeight: '700', fontSize: '12px' }}>{file?.name}</TableCell>
                          <TableCell sx={{ fontWeight: '700', fontSize: '12px' }}>{file?.type}</TableCell>
                          <TableCell sx={{ fontWeight: '700', fontSize: '12px' }}>{moment(file.uploadedAt).format('DD-MM-YYYY')}</TableCell>
                          <TableCell sx={{ fontWeight: '700', fontSize: '12px' }}>{file?.description}</TableCell>
                          <TableCell>
                            <a className="download" href={`${file.url}`} rel="noopener noreferrer">
                              <Chip sx={{ fontSize: '12px', bgcolor: '#ec895a', color: '#fff' }} size="small" label={t('download')} />
                            </a>
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default CSVFiles;
