import { useState } from 'react';

const useForgotPasswordHook = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [snackBar, setSnackBar] = useState({ open: false, message: '', vertical: 'top', horizontal: 'center' });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const resetPassword = (newPassword) => {
    setSnackBar({ open: true, message: 'Password reset successful', vertical: 'top', horizontal: 'center' });
  };

  const handleClose = () => {
    setSnackBar({ ...snackBar, open: false });
  };

  return {
    showPassword,
    snackBar,
    handleClickShowPassword,
    handleMouseDownPassword,
    resetPassword,
    handleClose,
  };
};

export default useForgotPasswordHook;
