const GermanyIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 640 480">
        <g fill-rule="evenodd" stroke-width="1pt">
          <path fill="#000" d="M0 0h640v160H0z"/>
          <path fill="#d00" d="M0 160h640v160H0z"/>
          <path fill="#ffce00" d="M0 320h640v160H0z"/>
        </g>
      </svg>
      
    );
  };
  
  export default GermanyIcon;
  