const italianTranslation = {
  home: 'Home',
  user_management: 'Gestione utenti',
  user: 'Utente',
  brand: 'Brand',
  role: 'Ruolo',
  measures: 'Measures',
  claim_management: 'Gestione Claim',
  order_engine: 'Order Engine',
  product_engine: 'Gestione Prodotti',
  create_new_product: 'Aggiungi prodotto',
  supplier_map: 'Mappa Fornitori',
  compliance: 'Conformità',
  over_view: 'Panoramica',
  product_compliance: 'Conformità del prodotto',
  reports: 'Report',
  reporting: 'Reportistica',
  product_lca: 'LCA di Prodotto',
  clustring: 'Clustring',
  impact_label: 'Impact Label',
  eCom_widget: 'E-com Widget',
  qr_codes: 'QR Codes',
  analytics: 'Analytics',
  settings: 'Impostazioni',
  back: 'Indietro',
  demo_account: 'Demo Account',
  your_product: 'Your Product',
  retailer: 'Retailer',
  status: 'Status',
  approved: 'Approved',
  approve: 'Approve',
  submit_for_review: 'Invia per revisione',
  submitted_for_review: 'Inviato per revisione',
  verified: 'Verificato',
  pending: 'In sospeso',
  percentile: 'percentile',
  renoon_score: 'Renoon Score',
  brand_name: 'Nome brand',
  contract_start_date: 'Data inizio contratto',
  contract_end_date: 'Data fine contratto',
  max_products: 'Max prodotti',
  max_users: 'Max utenti',
  features: 'Servizi',
  contract_files: 'File contratto',
  logo: 'Logo',
  actions: 'Azioni',
  add_brand: 'Aggiungi brand',
  brands: 'BRAND',
  page: 'Pagina',
  download: 'Scaricamento',
  update_brand: 'MODIFICA BRAND',
  brand_onboarding_form: 'MODULO DI ONBOARD DEL BRAND',
  update: 'Aggiorna',
  submit: 'Invia',
  cancel: 'Cancella',
  delete: 'Elimina',
  duplicate: 'Duplicato',
  delete_brand: 'Elimina brand',
  are_you_sure_you_want_to_delete_this_brand: 'Sei sicuro di voler eliminare questo brand?',
  are_you_sure_you_want_to_delete_this_user: 'Sei sicuro di voler eliminare questo utente?',
  brand_name_required: 'Il nome del marchio è obbligatorio',
  contract_start_date_required: 'La data di inizio del contratto è obbligatoria',
  contract_end_date_required: 'La data di fine del contratto è obbligatoria',
  max_product_required: 'Il numero massimo di prodotti è obbligatorio',
  max_user_required: 'Il numero massimo di utenti è obbligatorio',
  features_required: 'I servizi sono obbligatori',
  contract_files_required: 'Il file del contratto è obbligatorio',
  logo_required: 'Il logo è obbligatorio',
  add_role: 'Aggiungi ruolo',
  role_name: 'Nome del ruolo',
  resources: 'Risorse',
  loading: 'Caricamento',
  roles: 'RUOLI',
  edit: 'Modifica',
  update_role: 'Aggiorna ruolo',
  create_role: 'Crea ruolo',
  role_form: 'Crea ruolo',
  add_users: 'Aggiungi utente',
  update_user: 'Aggiorna utente',
  user_onboarding_form: 'Modulo di inserimento utente',
  first_name: 'Nome',
  last_name: 'Cognome',
  email_address: 'Indirizzo e-mail',
  password: 'Password',
  select_role: 'Seleziona ruolo',
  select_brand: 'Seleziona brand',
  create_user_account: 'Crea un account utente',
  users: 'UTENTI',
  email: 'E-mail',
  begin: 'Inizia',
  manage: 'Gestisci',
  goals_targets: 'Obiettivi e traguardi',
  your_ongoing_jurney_as_a_transparent_and_commited_companies_continues_here: 'Il tuo impegno verso la trasparenza continua qui',
  goals_sets: 'Obiettivi prefissati',
  certifications_and_proof_points: 'Certificazioni e documentazione',
  select_category: 'Seleziona claim',
  suggested_category: 'Claim suggeriti',
  discovery_mode: 'Modalità scoperta',
  questions_answered: 'Risposte alle domande',
  select_the_values_you_want_to_claim_to_easily_aggregate_your_data_in_the_section_below_updates_automatically:
    'Select the values you want to claim to easily aggregate your data in the section below - updates automatically.',
  view_and_reply_to_as_many_questions_as_you_can_to_discover_which_values_you_could_claim_based_on_your_data_and_documentation_of_prooof:
    'View & reply to as many questions as you can to discover which values you could claim based on your data and documentation of proof.',
  next: 'Avanti',
  prev: 'Indietro',
  uploaded: 'Caricato',
  you_have_set_this_as_a_goal: 'Impostato come traguardo',
  set_as_future_goal: 'Imposta come obiettivo',
  choose_file: 'Seleziona file',
  file_types: 'pdf, doc, docx, csv, txt, xlsx, ppt, pptx, png, jpg, jpeg',
  extra_notes: 'Descrivi di più',
  pick_from_library: 'Seleziona dalla libreria',
  create_new: 'Crea nuovo',
  certificate_details: 'Dettagli certificazione',
  attachment: 'Allegato',
  insert_name: 'Inserisci nome',
  select_type: 'Seleziona tipo',
  emitted_by: 'Emesso da',
  insert_date_of_emission: 'Data di emissione',
  insert_expiration_date: 'Data di scadenza',
  select_certificate_owner: 'Seleziona proprietario documento',
  create: 'Crea',
  attachment_is_required: 'Allegato richiesto',
  name_is_required: 'Il nome è obbligatorio',
  document_type_is_required: 'Document type is required',
  emitted_by_is_required: 'Emitted by is required',
  emission_date_is_required: 'Emission date is required',
  expiration_date_is_required: 'Expiration date is required',
  certificate_owner_is_required: 'Certificate owner is required',
  pick_certificate: 'Seleziona documento',
  preview: 'Anteprima',
  name: 'Nome',
  date_of_emission: 'Data di emissione',
  expiration_date: 'Data di scadenza',
  alerts: 'Allarme scadenza',
  thirty_days_before_expiration_date: '30 giorni prima della scadenza',
  validated: 'Valido',
  form_issuing_today: 'Validità documento',
  certifications_and_proof_points_uploaded: 'Certificazioni e documenti di prova caricati',
  verified_date: 'Data di validazione',
  privacy: 'Privacy',
  search: 'Ricerca',
  view_profile: 'Profilo',
  user_name: 'Nome utente',
  user_profile: 'Profilo utente',
  log_out: 'Log Out',
  user_details: 'Dettagli utente',
  full_name: 'Nome',
  form_status: 'Stato del questionario',
  visibility: 'Visibilità',
  image: 'Anteprima',
  E_commerce_item_code: 'Codice articolo e-commerce',
  title: 'Titolo',
  supply_chain: 'Catena di fornitura',
  collection: 'Collezione',
  uploaded_products: 'Prodotti caricati',
  upload_product: 'Carica prodotti',
  new_product: 'Nuovo prodotto',
  additional_product_facts_form: 'Modulo informazioni aggiuntive sul prodotto',
  total: 'Totale',
  product_title: 'Titolo prodotto',
  enter_sku: 'SKU',
  e_commerce_id: "Codice identificativo dell'e-commerce",
  item_code: 'Codice articolo',
  insert_collection: 'Aggiungi collezione',
  composition: 'Composizione',
  care_instruction: 'Istruzioni per il lavaggio',
  additional_care_info: 'Istruzioni di cura supplementari',
  weight: 'Peso',
  colors: 'Colori',
  choose_category: 'Seleziona claim',
  price: 'Prezzo',
  save: 'Salva',
  enter_detail_here: 'Inserisci i dettagli qui',
  enter_collection_name: 'Nome collezione',
  mandatory_for_product_creation: "Obbligatorio per l'inserimento del widget",
  mandatory_for_qr_generation: 'Obbligatorio per la creazione di codici QR',
  attach_image: 'Aggiungi immagini',
  upload_files: 'Carica files',
  upload_CSV_file: 'Carica file CSV',
  upload_images_zip: 'Carica immagini o file zip',
  update_product: 'Aggiorna il prodotto',
  material: 'Materiale',
  percentage: 'Percentuale',
  add: 'Aggiungi',
  qr_id: 'QR Id',
  mandatory_ecommerceId: 'Codice e-commerce obbligatorio',
  weave: 'Tessitura',
  title_is_required: 'Il titolo è obbligatorio',
  weave_is_required: 'Weave is required',
  sku_id_is_required: "L'SKU è obbligatorio",
  ecommerce_id_is_required: "L'ID e-commerce è obbligatorio",
  cannot_contain_slash_or_underscore: 'Non può contenere / o _',
  no_files_selected: 'Nessun file selezionato',
  invalid_file_format: 'Formato file non valido (supportati solo .jpeg, .png)',
  file_size_exceeds_limit: 'La dimensione del file supera il limite di 1 MB',
  total_file_size_exceeds_limit: 'La dimensione totale dei file supera il limite di 5 MB',
  active: 'Attivo',
  archive: 'Archivia',
  download_QR: 'Scarica QR',
  no_product_found: 'Non ci sono prodotti',
  these_are_the_claim_which_are_approved_pending_and_rejected_please_take_the_action_as_requested: "Questi sono i claim approvati, in sospeso e respinti. Si prega di eseguire l'azione richiesta.",
  requested_category: 'Claim richiesti',
  submit_the_detail_and_wait_for_approval: 'Invia le risposte e attendi la revisione',
  submitted: 'Inviato',
  set_of_questions_to_be_answered: 'Domande',
  verify: 'Verifica',
  categories_to_be_reviewed: 'Claim da revisionare',
  are_you_sure_you_want_to_delete_this_certificate: 'Sicuro di voler cancellare questo documento?',
  delete_certificate: 'Cancella documento',
  select_the_value_you_want_to_claim_to_easily_aggregate_your_data_in_the_section_below: 'Select the value you want to claim to easily aggregate your data in the section below',
  review_categories: 'Revisiona i claim',
  review: 'Revisiona',
  categories: 'Claim',
  categories_and_claims: 'Claim',
  note_before_you_start_you_can_save_and_edit_your_questions_anytime_before_submitting: 'Note before you start you can save and edit your questions anytime before submitting.',
  enjoy_the_process: 'Goditi il ​​processo.',
  eco_packaging: 'Eco packaging',
  submit_the_detail: 'Invia i dettagli',
  claim_status: 'Stato del claim',
  create_the_claim: 'Crea il claim',
  save_claim: 'Salva claim',
  reject_document: 'Rifiuta documento',
  reject: 'Rifiuta',
  reason_for_rejection: 'Motivo del rifiuto',
  locally_made: 'Prodotto localmente',
  reviewd_category: 'Claim revisionati',
  document_already_uploaded: 'Documento già caricato',
  select_or_enter_a_claim: 'Seleziona o inserisci un claim',
  reason: 'Motivo',
  explain_for_selecting_the_particular_status_for_the_claim: 'Explain for selecting the particular status for the claim',
  assign_a_label: "Assegna un'etichetta",
  label_name: "Nome dell'etichetta",
  label_eg: 'Es. Collezione 2024',
  description: 'Descrizione',
  assign_document: 'Assegna documento',
  document_attach: 'Allegare documento',
  assigned_document: 'Documento assegnato',
  label_assigned: 'Etichetta assegnata',
  all: 'Tutto',
  supply_chain_management: 'Gestione catena di fornitura',
  supplier: 'Fornitore',
  suppliers:"Fornitori",
  upload_csv: 'Carica CSV',
  add_suppliers: 'Aggiungi fornitori',
  view_claims: 'Vedi claim',
  add_proudcts: 'Aggiungi merce o servizio',
  create_supply_chain: 'Crea catena di fornitura',
  suppliers_have_products_or_services: 'I fornitori offrono merci o servizi',
  claim_name: 'Nome claim',
  claims: 'Claim',
  list_of_claims: 'Lista dei claim',
  add_supplier: 'Aggiungi fornitore',
  name_eg: 'Es. Renoon',
  vanity_name: 'Nome alternativo fornitore',
  products: 'Prodotti connessi',
  website_url: 'Sito web',
  website_eg: 'Es. https://renoon.com',
  vat_eg: 'Es. IT123456789',
  vat_code: 'P.IVA',
  vat: 'VAT',
  address: 'Indirizzo',
  state: 'Stato',
  city: 'Città',
  region_country: 'Regione',
  street: 'Via',
  upload_images: 'Carica immagini',
  contact: 'Contatti',
  no_of_employee: 'Numero di dipendenti',
  no_of_factories: 'Numero di impianti',
  add_a_new_supplier_by_submitting_this_details: 'Add a new supplier by submitting this details',
  add_new_products: 'Aggiungi prodotto fornitore',
  document: 'Documentazione',
  select_products: 'Seleziona prodotti',
  more_custom_properties_may_be_added_like_colors_etc: 'More custom properties may be added like colors etc.',
  they_are_not_always_relevent_and_not_needed_for_the_mvp: 'They are not always relevent and not needed for the MVP.',
  vanity_name_is_required: 'Vanity name is required',
  website_url_is_required: 'Website URL is required',
  vat_is_required: 'Vat is required',
  description_is_required: 'Description is required',
  contact_name_is_required: 'Contact name is required',
  contact_email_is_required: 'Contact email is required',
  please_enter_a_valid_email: 'Please enter a valid email',
  street_is_required: 'Street is required',
  city_name_is_required: 'City name is required',
  country_is_required: 'Country is required',
  state_is_required: 'State is required',
  add_new: 'Aggiungi nuovo',
  stage: 'Fase',
  sku: 'SKU',
  sku_eg: 'Es. FW24CBSHEJ66',
  add_new_product_or_service: 'Aggiungi merce/servizio',
  stage_is_required: 'La fase è obbligatoria',
  delete_supplier: 'Cancella fornitore',
  are_you_sure_you_want_to_delete_this_supplier: 'Sicuro di voler cancellare questo fornitore?',
  view_claim: 'Vedi claim',
  search_products: 'Cerca una merce',
  create_product: 'Crea una merce',
  create_service: 'Crea un servizio',
  image_type: 'png, jpg, jpeg',
  are_you_sure_you_want_to_delete_this_supplier_product: 'Sei sicuro di voler eliminare questo prodotto fornitore?',
  search_services: 'Cerca un servizio',
  edit_supply_chain: 'Modifica catena di fornitura',
  are_you_sure_you_want_to_delete_this_supply_chain: 'Sei sicuro di voler cancellare questa catena di fornitura?',
  delete_supply_Chain: 'Cancella catena di fornitura',
  continue: 'Continua',
  select_the_supplier: 'Seleziona fornitore',
  edit_product: 'Modifica servizio',
  save_draft: 'Salva bozza',
  detailed_view: 'Dettagli',
  they_are_not_always_relevant_and_not_needed_for_the_mvp: 'They are not always relevant and not needed for the MVP.',
  add_products: 'Aggiungi prodotti',
  select_the_supplier_product: 'Seleziona il servizio del fornitore',
  is_in_supply_chain: 'È nella catena di fornitura',
  country: 'Stato',
  has_suppliers_products: 'Ha prodotti del fornitore',
  sort_by: 'Ordina per',
  filter: 'Filtri',
  clear_all: 'Reset',
  has_products: 'Con prodotti assegnati',
  is_in_a_supply_chain: 'Inserito in una supply chain',
  service: 'Servizio',
  product: 'Prodotto',
  type: 'Tipo di prodotto',
  has_suppliers: 'Ha fornitori',
  are_you_sure_you_want_to_delete_this_chain_product: 'Sei sicuro di voler eliminare il prodotto di questa catena?',
  are_you_sure_you_want_to_delete_this_chain_supplier: 'Sei sicuro di voler eliminare il fornitore di questa catena?',
  zoom_in: 'Ingrandisci',
  zoom_out: 'Rimpicciolisci',
  reset: 'Reset',
  no_certificate_found: 'Nessuna documento trovato',
  reviewed_claims: 'CLAIM REVISIONATI',
  view_all: 'Visualizza tutto',
  unique_Id: 'ID univoco',
  view_docs: 'Vedi documenti',
  select_the_value_you_want_to_communicate: 'Seleziona i valori che desideri comunicare. Ci occupiamo noi di convalidarli e tradurli in claim conformi.',
  edit_supplier: 'Modifica fornitore',
  submit_for_approval: "Invia per l'approvazione",
  fill_the_forms: 'Compila i moduli',
  the_forms_are_saved_automatically_You_can_submit_the_forms_once_all_the_questions_are_answered:
    "I questionari sottostanti contengono tutte le domande rilevanti ai fini dell'ottenimento dei claim selezionati, più alcune domande generiche.I moduli vengono salvati automaticamente. È possibile inviare i questionari dopo aver risposto a tutte le domande.",
  opens_the_selected_document_for_reading_with_zoom_and_scroll_functionality_Allows_quick_access_without_making_changes:
    'In questa sezione puoi accedere a tutti i documenti che sono stati caricati. Puoi controllare facilmente le certificazioni che stanno per scadere, ricevere notifiche di scadenza e intraprendere azioni per il loro rinnovo. Inoltre, hai la possibilità di modificare e aggiornare qualsiasi informazione associata a questi documenti, garantendo che tutti i dati siano sempre accurati e aggiornati.',
  documents: 'Documenti e certificazioni',
  create_brand: 'Crea brand',
  select_claim: 'Seleziona claim',
  select_supplier: 'Seleziona fornitore',
  select_product: 'Seleziona prodotto',
  filter_by: 'Filtra',
  additional_settings: 'Informazioni aggiuntive',
  stage_vanity_name: 'Nome alternativo della fase',
  vanity_title: 'Titolo alternativo',
  has_not_product: 'Has not product',
  read_more: 'Read more',
  read_less: 'Read less',
  add_user: 'Aggiungi utente',
  files: 'File',
  file_type: 'Formato del file',
  deselect_all: 'Deseleziona tutto',
  select_all: 'Seleziona tutto',
  is_archived: 'È archiviato',
  qr_code: 'QR Code',
  date: 'data',
  only_image_files_PNG_JPEG_JPG_are_allowed: 'Sono consentiti solo file immagine (PNG, JPG, JPEG).',
  only_PDF_files_are_allowed: 'Sono ammessi solo file PDF',
  download_qr: 'Scarica il codice QR',
  no_category_found: 'Nessun claim trovato',
  yes: 'Si',
  no: 'NO',
  collectionName_: 'nomeraccolta',
  date_: 'data',
  title_: 'titolo',
  name_: 'nome',
  Name: 'Nome',
  Date: 'Data',
  Title: 'Titolo',
  Stage: 'Fase',
  PRODUCT: 'PRODOTTO',
  SERVICE: 'SERVIZIO',
  Verified: 'Verificato',
  Proven: 'Comprovato',
  Declared: 'Dichiarato',
  Rejected: 'Respinto',
  additional_information: 'Informazioni aggiuntive',
  upload: 'Caricamento',
  SUPPLIER: 'FORNITORE',
  SUPPLIER_CHAIN: 'CATENA DI FORNITORI',
  SUPPLIER_PRODUCT: 'FORNITORE-PRODOTTO',
  BRAND_PRODUCT: 'PRODOTTO DI MARCA',
  Less_than_9: 'Meno di 9',
  ten_to_49: '10-49',
  fifty_to_249: '50-249',
  More_than_250: 'Più di 250',
  VERIFIED: 'VERIFICATO',
  PROVEN: 'PROVATO',
  DECLARED: 'DICHIARATO',
  REJECTED: 'RESPINTO',
  PENDING: 'IN ATTESA',
  product_: 'Prodotto +',
  supplier_: 'Fornitore +',
  email_is_required:"L'e-mail è obbligatoria",
  sku_id:"Codice SKU",
  collection_name:"Nome della raccolta",
  category:"Categoria",
};
export default italianTranslation;
