import AmericaIcon from "../images/icons/americaIcon";
// import FranceIcon from "../images/icons/franceIcon";
import ItalyIcon from "../images/icons/italyIcon";
// import TurkeyIcon from "../images/icons/trukeyIcon";

export const LANGUAGES = [
  { code: 'en', name: 'English', flag: <AmericaIcon /> },
  // { code: 'tr', name: 'Turkish', flag: <TurkeyIcon /> },
  // { code: 'fr', name: 'French', flag: <FranceIcon /> },
  { code: 'it', name: 'Italiano', flag: <ItalyIcon /> },
  ];

