import { MenuItem, Select, FormControl } from '@mui/material';
import AmericaIcon from '../../images/icons/americaIcon';
import TurkeyIcon from '../../images/icons/trukeyIcon';
import FranceIcon from '../../images/icons/franceIcon';
import ItalyIcon from '../../images/icons/italyIcon';
import GermanyIcon from '../../images/icons/germanyIcon';

const LanguageSelector = ({ setLang, selectLang, disable }) => {
  const handleChange = (event) => {
    setLang(event.target.value);
  };
  const languages = [
    { code: 'en', name: 'English', flag: <AmericaIcon /> },
    { code: 'tr', name: 'Türkçe', flag: <TurkeyIcon /> },
    { code: 'fr', name: 'Français', flag: <FranceIcon /> },
    { code: 'it', name: 'Italiano', flag: <ItalyIcon /> },
    { code: 'de', name: 'Deutsch', flag: <GermanyIcon/> },
  ];
  return (
      <FormControl>
        <Select
          disabled={disable}
          labelId="demo-select-small-label"
          id="demo-select-small"
          sx={{
            borderRadius: '20px',
            bgcolor: '#fff',
          }}
          size="small"
          value={selectLang}
          onChange={(event) => handleChange(event)}
          disableUnderline
        >
          {languages?.map((lang) => (
            <MenuItem sx={{ display: 'flex', alignItems: 'center', wordSpacing: '5px' }} key={lang.code} value={lang.code}>
              <span style={{ marginRight: '5px', borderRadius: '5px' }}>{lang.flag}</span>
              {lang.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
  );
};

export default LanguageSelector;
