
import axios, { AxiosRequestConfig, AxiosInstance } from 'axios';

class HttpService {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      timeout: this.getReqTimeOut(),
    });

    this.axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          // Redirect to login page
          localStorage.removeItem('Authorization');
          localStorage.removeItem('resources');
          window.location.href = '/login';
        }
        return Promise.reject(error);
      }
    );
  }

  private getDefaultOptions(): AxiosRequestConfig {
    return {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      timeout: this.getReqTimeOut(),
    };
  }

  /**
   * this method will help you to execute get request over any server with the help of axios
   * @param url pass the url
   * @param options pass the options
   */
  async get(url: string, options?: AxiosRequestConfig) {
    const mergedOptions = { ...this.getDefaultOptions(), ...options };
    return this.axiosInstance.get(url, mergedOptions);
  }

  /**
   * this method will help you to execute patch request over any server with the help of axios
   * @param url pass the url
   * @param requestBody pass the requestBody
   * @param options pass the options
   */
  patch(url: string, requestBody: any, options?: AxiosRequestConfig) {
    const mergedOptions = { ...this.getDefaultOptions(), ...options };
    return this.axiosInstance.patch(url, requestBody, mergedOptions);
  }

  /**
   * this method will help you to execute put request over any server with the help of axios
   * @param url pass the url
   * @param requestBody pass the requestBody
   * @param options pass the options
   */
  put(url: string, requestBody: any, options?: AxiosRequestConfig) {
    const mergedOptions = { ...this.getDefaultOptions(), ...options };
    return this.axiosInstance.put(url, requestBody, mergedOptions);
  }

  /**
   * this method will help you to execute post request over any server with the help of axios
   * @param url pass the url
   * @param requestBody pass the requestBody
   * @param options pass the options
   */
  post(url: string, requestBody?: any, options?: AxiosRequestConfig) {
    const mergedOptions = { ...this.getDefaultOptions(), ...options };
    return this.axiosInstance.post(url, requestBody, mergedOptions);
  }

  /**
   * this method will help you to execute delete request over any server with the help of axios
   * @param url pass the url
   * @param options pass the options
   */
  delete(url: string, options?: AxiosRequestConfig) {
    const mergedOptions = { ...this.getDefaultOptions(), ...options };
    return this.axiosInstance.delete(url, mergedOptions);
  }

  /**
   * Return the request timeout
   */
  private getReqTimeOut() {
    return Number.parseFloat(process.env.AXIOS_TIMEOUT || '29000');
  }
}

const httpService = new HttpService();

export default httpService;
