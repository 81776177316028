import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '../service/http.service';
import { RestUrlsConstants } from '../constants/rest-urls.constants';

const initialState = {
  categoryQuestionForm: [],
  loading: false,
  error: null,
};

export const fetchCategoryQuestionForm = createAsyncThunk(
  'category/question-form', 
  async (formId, { rejectWithValue }) => {
    try {
      const response = await httpService.get(`${RestUrlsConstants.categoryUrl}${formId}`, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
        },
      });
      return response?.data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data || error.message); 
    }
  }
);

const CategoryQuestionFormSlice = createSlice({
  name: 'categoryForm',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategoryQuestionForm.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCategoryQuestionForm.fulfilled, (state, action) => {
        state.loading = false;
        state.categoryQuestionForm = action.payload;
      })
      .addCase(fetchCategoryQuestionForm.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default CategoryQuestionFormSlice.reducer;
