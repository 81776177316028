import { useState } from 'react';
import { Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link, Outlet, useLocation } from 'react-router-dom';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import Snackbar from '@mui/material/Snackbar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import BasicList from './avatar';
import Auth from '../hooks/auth.hook';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import BrandSearch from './brandSearch';
import { LanguageSelector } from '../constants/Translator';
import ClaimManagementIcon from '../images/icons/claimManagementIcon';
import ProductEngineIcon from '../images/icons/productEngineIcon';
import SupplierMapIcon from '../images/icons/SupplierMapIcon';
import HomeIcon from '../images/icons/homeIcon';
import { RolesStatus } from '../constants/common.constants';
import Logo from '../images/icons/Logo';

const Nav = () => {
  const { snackBar, handleClose } = Auth();
  const [showLogout, setShowLogout] = useState(false);
  const user = useSelector((store) => store.user);
  const location = useLocation();
  const isSelected = (path) => location.pathname === path;

  const selectedStyle = {
    cursor: 'none',
    pointerEvents: 'none',
  };
  // const resources = JSON.parse(localStorage.getItem('resources'));

  const { t } = useTranslation();

  const headerName = (name) => {
    const words = name.split(' ');
    return (
      <Typography variant="h5" color="#000" fontWeight={'bold'} textTransform={'capitalize'}>
        {words.map((word, index) => {
          let color;
          if (index === 0) {
            color = '#ec895a';
          } else {
            color = '#000';
          }
          return (
            <span key={index} style={{ color }}>
              {word}
              {index < words.length - 1 && ' '}
            </span>
          );
        })}
      </Typography>
    );
  };

  return (
    <>
      <Grid container sx={{ width: '100%', bgcolor: '#101010' }}>
        <Grid item xs={2}>
          <Box className="example" sx={{ bgcolor: '#101010', height: '100vh', overflowY: 'auto' }}>
            <Menu style={{}}>
              <Box sx={{ display: 'flex', alignItems: 'center',pt:3,pl:3 }}>
                {/* <img src={require('../images/renoonLogo.png')} alt="panda" className="logo-dashboard" /> */}
                <Logo/>
              </Box>
              <Box mt={4} pl={2} sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <MenuItem
                  style={
                    isSelected('/')
                      ? { ...selectedStyle, borderTopLeftRadius: '50px', borderBottomLeftRadius: '50px' }
                      : { borderTopLeftRadius: '50px', borderBottomLeftRadius: '50px' }
                  }
                  component={<Link to="/" className={`link ${isSelected('/') ? 'selected' : ''}`} />}
                  icon={<ClaimManagementIcon color={isSelected('/') ? '#000' : '#fff'} />}
                >
                  {t('claim_management')}
                </MenuItem>
                {user?.userType === RolesStatus.system && (
                  <>
                    {/* <MenuItem
                      style={isSelected('/') ? { ...selectedStyle, borderTopLeftRadius: '50px', borderBottomLeftRadius: '50px' } : { borderTopLeftRadius: '50px', borderBottomLeftRadius: '50px' }}
                      component={<Link to="/" className={`link ${isSelected('/') ? 'selected' : ''}`} />}
                      icon={<HomeIcon color={isSelected('/') ? '#000' : '#fff'} />}
                    >
                      {t('home')}
                    </MenuItem> */}
                    <SubMenu className="user-management" style={{ borderTopLeftRadius: '50px', borderBottomLeftRadius: '50px' }} label={t('user_management')} icon={<ManageAccountsIcon />}>
                      <MenuItem
                        style={
                          isSelected('/brand') ? { ...selectedStyle, borderTopLeftRadius: '50px', borderBottomLeftRadius: '50px' } : { borderTopLeftRadius: '50px', borderBottomLeftRadius: '50px' }
                        }
                        component={<Link to="/brand" className={`link ${isSelected('/brand') ? 'selected' : ''}`} />}
                      >
                        {t('brand')}
                      </MenuItem>
                      <MenuItem
                        style={
                          isSelected('/role') ? { ...selectedStyle, borderTopLeftRadius: '50px', borderBottomLeftRadius: '50px' } : { borderTopLeftRadius: '50px', borderBottomLeftRadius: '50px' }
                        }
                        component={<Link to="/role" className={`link ${isSelected('/role') ? 'selected' : ''}`} />}
                      >
                        {t('role')}
                      </MenuItem>
                      <MenuItem
                        style={
                          isSelected('/user') ? { ...selectedStyle, borderTopLeftRadius: '50px', borderBottomLeftRadius: '50px' } : { borderTopLeftRadius: '50px', borderBottomLeftRadius: '50px' }
                        }
                        component={<Link to="/user" className={`link ${isSelected('/user') ? 'selected' : ''}`} />}
                      >
                        {t('user')}
                      </MenuItem>
                      <MenuItem
                        style={
                          isSelected('/files') ? { ...selectedStyle, borderTopLeftRadius: '50px', borderBottomLeftRadius: '50px' } : { borderTopLeftRadius: '50px', borderBottomLeftRadius: '50px' }
                        }
                        component={<Link to="/files" className={`link ${isSelected('/files') ? 'selected' : ''}`} />}
                      >
                        {t('files')}
                      </MenuItem>
                    </SubMenu>
                  </>
                )}
                <MenuItem
                  style={
                    isSelected('/product-engine/1') ? { ...selectedStyle, borderTopLeftRadius: '50px', borderBottomLeftRadius: '50px' } : { borderTopLeftRadius: '50px', borderBottomLeftRadius: '50px' }
                  }
                  component={<Link to="/product-engine/1" className={`link ${isSelected('/product-engine/1') ? 'selected' : ''}`} />}
                  icon={<ProductEngineIcon color={isSelected('/product-engine/1') ? '#000' : '#fff'} />}
                >
                  {t('product_engine')}
                </MenuItem>
                <MenuItem
                  style={
                    isSelected('/supply-chain-management/1')
                      ? { ...selectedStyle, borderTopLeftRadius: '50px', borderBottomLeftRadius: '50px' }
                      : { borderTopLeftRadius: '50px', borderBottomLeftRadius: '50px' }
                  }
                  component={<Link to="/supply-chain-management/1" className={`link ${isSelected('/supply-chain-management/1') ? 'selected' : ''}`} />}
                  icon={<SupplierMapIcon color={isSelected('/supply-chain-management/1') ? '#000' : '#fff'} />}
                >
                  {t('supplier_map')}
                </MenuItem>
                {/* {resources?.settings && (
                  <MenuItem
                    style={
                      isSelected('/settings') ? { ...selectedStyle, borderTopLeftRadius: '50px', borderBottomLeftRadius: '50px' } : { borderTopLeftRadius: '50px', borderBottomLeftRadius: '50px' }
                    }
                    component={<Link to="/settings" className={`link ${isSelected('/settings') ? 'selected' : ''}`} />}
                    icon={<SettingsApplicationsRoundedIcon />}
                  >
                    {t('settings')}
                  </MenuItem>
                )} */}
              </Box>
            </Menu>
          </Box>
        </Grid>
        <Grid item xs={10} sx={{ borderTopLeftRadius: '30px', borderBottomLeftRadius: '30px', bgcolor: '#EFEFEF', height: '100vh' }}>
          <Box
            sx={{
              borderTopLeftRadius: '30px',
              display: 'flex',
              alignItems: 'center',
              height: '15vh',
              justifyContent: 'space-between',
              px: 4,
              position: 'sticky',
              top: '0px',
              bgcolor: '#EFEFEF',
              zIndex: '100',
            }}
          >
            {headerName(t(location.pathname.split('/')[1].replace(/^\//, '').replaceAll('-', '_')))}
            <Box sx={{ display: 'flex', gap: '10px' }}>
              {user?.userType === RolesStatus.system && <BrandSearch user={user}></BrandSearch>}
              <LanguageSelector />
              <div onClick={() => setShowLogout(true)} style={{ display: 'flex', alignItems: 'center' }}>
                <Avatar sx={{ bgcolor: '#ec895a', marginRight: '10px', height: '40px' }} alt="Remy Sharp" src="/broken-image.jpg">
                  {user?.name?.split(' ')[0] && user.name.split(' ')[0][0] && user?.name.split(' ')[0][0].toUpperCase()}
                  {user?.name?.split(' ')[1] && user.name.split(' ')[1][0] && user?.name.split(' ')[1][0].toUpperCase()}
                </Avatar>
              </div>
            </Box>
          </Box>
          <Box sx={{ height: '84vh', overflowY: 'auto', zIndex: '10', pb: 2 }} onClick={() => setShowLogout(false)} px={2}>
            <Outlet />
          </Box>
        </Grid>
      </Grid>
      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        message="Your session has been expired"
        anchorOrigin={{
          vertical: snackBar.vertical,
          horizontal: snackBar.horizontal,
        }}
        onClose={handleClose}
        className="snackBarColor"
        key={snackBar.vertical + snackBar.horizontal}
      />
      <BasicList name={user?.name} email={user?.email} userType={user?.userType} showLogout={showLogout} setShowLogout={setShowLogout} />
    </>
  );
};

export default Nav;
