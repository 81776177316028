import { useTranslation } from 'react-i18next';
import { Box, Button, Card, CardContent, Checkbox, Chip, Grid, IconButton, InputAdornment, Pagination, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import user from '../../images/user.png';
import { useDispatch, useSelector } from 'react-redux';
import { encryptId } from '../utils/encryptDecrypt';
import PATH from '../utils/path';
import { useNavigate } from 'react-router-dom';
import FilterSupplier from '../utils/FilterSupplier';
import { fetchSupplierWithSearch, fetchSupplier } from '../../features/supplierSlice';

export default function SelectClaimSupplier({ claimSupplier, setClaimSupplier }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const { suppliers } = useSelector((state) => state.supplier);

  const itemsPerPage = 12;
  const pageCount = Math.ceil(suppliers?.suppliers?.length / itemsPerPage);
  const dispatch = useDispatch();

  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    dispatch(fetchSupplier({ page, itemsPerPage }));
  }, [dispatch, page]);

  const toggleSupplierSelection = (selectedSupplier) => {
    setClaimSupplier((prevData=[]) => {
      const isSelected = prevData?.some((supplier) => supplier.supplierId === selectedSupplier?.id);

      if (isSelected) {
        return prevData.filter((supplier) => supplier.supplierId !== selectedSupplier?.id);
      } else {
        return [
          ...prevData ,
          {
            supplierId: selectedSupplier?.id,
            name: selectedSupplier?.name,
          },
        ];
      }
    });
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    let term = e.target.value;
    dispatch(fetchSupplierWithSearch({term }));
  };

  const selectAllSuppliers = () => {
    if (claimSupplier?.length === suppliers.suppliers?.length) {
      setClaimSupplier([]);
    } else {
      const allSuppliers = suppliers.suppliers?.map((supplier) => ({
        supplierId: supplier.id,
        name: supplier?.name,
      }));
      setClaimSupplier(allSuppliers);
    }
  };

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  return (
    <Card>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <TextField
              size="small"
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '20px',
                },
              }}
              variant="outlined"
              placeholder={t('search')}
              value={searchTerm}
              onChange={handleSearchChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <FilterSupplier page={page} itemsPerPage={itemsPerPage} />
          </Box>
          <Pagination
            sx={{
              '& .MuiPaginationItem-root': {
                color: 'gray',
              },
              '& .MuiPaginationItem-root.Mui-selected': {
                backgroundColor: '#EC895A',
                color: '#fff',
              },
            }}
            count={pageCount}
            page={page}
            onChange={handleChange}
          />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', mt: 1 }}>
          <Button onClick={selectAllSuppliers} sx={{ bgcolor: '#101010', ':hover': { bgcolor: '#101010' }, color: '#fff', borderRadius: '20px', px: 2, textTransform: "capitalize" }}>
            {claimSupplier?.length === suppliers.suppliers?.length ? t('deselect_all') : t('select_all')}
          </Button>
          <Button sx={{ bgcolor: 'gray', ':hover': { bgcolor: 'gray' }, color: '#fff', borderRadius: '20px', px: 1, textTransform: "capitalize" }}>{`Selected: ${claimSupplier?.length}`}</Button>
        </Box>
        <Grid mt={1} container spacing={2}>
          {suppliers?.suppliers?.map((supplier) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={supplier.id}>
              <Card>
                <CardContent sx={{ textAlign: 'center', height: '270px', position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
                  <img
                    onClick={() => navigate(`${PATH.ADDSUPPLIER}/${encryptId(supplier?.id)}`)}
                    src={supplier?.urls ? supplier?.urls[0]?.url : user}
                    alt=""
                    style={{ width: 50, height: 50, borderRadius: '30px', border: '1px solid gray', cursor: 'pointer' }}
                  />
                  <Checkbox
                    sx={{ position: 'absolute', top: '0px', right: '0px' }}
                    {...label}
                    icon={<CheckCircleIcon color="gray" />}
                    checkedIcon={<CancelIcon color="error" />}
                    checked={claimSupplier?.some((s) => s.supplierId === supplier.id)}
                    onChange={() => toggleSupplierSelection(supplier)}
                  />
                  <Typography variant="body2" sx={{ color: '#000', fontWeight: 600 }}>
                    {supplier?.name}
                  </Typography>
                  <Typography sx={{ color: '#0e1090', fontWeight: 500, fontSize: '14px' }}>{supplier?.vanityName?.value}</Typography>
                  <Typography sx={{ color: '#000', fontSize: '12px', fontWeight: 700 }}>
                    <span style={{ color: 'gray' }}>{t('vat')}</span> {supplier?.vat}
                  </Typography>
                  <Typography sx={{ color: 'gray', fontSize: '12px' }}>
                    <LocationOnIcon sx={{ fontSize: '16px', color: '#000' }} />
                    {supplier?.address?.street} {supplier?.address?.city} {supplier?.address?.state} {supplier?.address?.country}
                  </Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Chip label={`${supplier?.numberOfProducts} ${t("product")}`} sx={{ fontSize: '10px', height: '20px', bgcolor: 'gray', color: '#fff' }} />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
}
