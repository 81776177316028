const turkishTranslation = {
  home: 'Ev',
  user_management: 'Kullanıcı yönetimi',
  user: 'Kullanıcı',
  brand: 'Marka',
  role: 'Rol',
  measures: 'Miktar',
  claim_management: 'Talep Yönetimi',
  order_engine: 'Sipariş Motoru',
  product_engine: 'Ürün Motoru',
  supplier_map: 'Tedarikçi Haritası',
  compliance: 'uyma',
  over_view: 'Genel Bakış',
  product_compliance: 'Ürün Uyumluluğu',
  reports: 'Raporlar',
  reporting: 'Raporlama',
  product_lca: "Ürün LCA'sı",
  clustring: 'Kümeleme',
  impact_label: 'Etki Etiketi',
  eCom_widget: "E-com Widget'ı",
  qr_codes: 'QR Kodları',
  analytics: 'Analitik',
  settings: 'Ayarlar',
  back: 'Geri',
  demo_account: 'Demo Hesabı',
  your_product: 'Ürününüz',
  retailer: 'Perakendeci',
  status: 'Durum',
  approved: 'Onaylı',
  approve: 'Onaylamak',
  submit_for_review: 'İnceleme için gönder',
  submitted_for_review: 'İnceleme için gönderildi',
  verified: 'Doğrulandı',
  pending: 'Askıda olması',
  percentile: 'yüzdelik',
  renoon_score: 'Renoon Skoru',
  brand_name: 'Marka adı',
  contract_start_date: 'Sözleşme Başlangıç ​​Tarihi',
  contract_end_date: 'Sözleşme Bitiş Tarihi',
  max_products: 'Maksimum Ürünler',
  max_users: 'Maksimum Kullanıcı',
  features: 'Özellikler',
  contract_files: 'Sözleşme Dosyaları',
  logo: 'Logo',
  actions: 'Hareketler',
  add_brand: 'Marka Ekle',
  brands: 'MARKALAR',
  page: 'Sayfa',
  download: 'İndirmek',
  update_brand: 'MARKAYI GÜNCELLE',
  brand_onboarding_form: 'MARKA KATILIM FORMU',
  update: 'Güncelleme',
  submit: 'Göndermek',
  cancel: 'İptal etmek',
  delete: 'Silmek',
  delete_brand: 'Markayı Sil',
  are_you_sure_you_want_to_delete_this_brand: 'Bu markayı silmek istediğinizden emin misiniz?',
  are_you_sure_you_want_to_delete_this_user: 'Bu markayı silmek istediğinizden emin misiniz Kullanıcı ?',
  brand_name_required: 'Marka Adı gerekli',
  contract_start_date_required: 'Sözleşme Başlangıç ​​Tarihi gerekli',
  contract_end_date_required: 'Sözleşme Bitiş Tarihi gerekli',
  max_product_required: 'Maksimum Ürün gereklidir',
  max_user_required: 'Maksimum Kullanıcı gerekli',
  features_required: 'Özellikler gereklidir',
  contract_files_required: 'Sözleşme Dosyaları gereklidir',
  logo_required: 'Logo gerekli',
  add_role: 'Rol Ekle',
  role_name: 'Rol ismi',
  resources: 'Kaynaklar',
  loading: 'Yükleniyor',
  roles: 'ROLLER',
  edit: 'Düzenlemek',
  update_role: 'Rolü Güncelle',
  create_role: 'Rol Oluştur',
  role_form: 'Rol Formu',
  add_users: 'Kullanıcı Ekle',
  update_user: 'Kullanıcıyı Güncelle',
  user_onboarding_form: 'Kullanıcı Katılım Formu',
  first_name: 'İlk adı',
  last_name: 'Soy isim',
  email_address: 'E-posta Adresi',
  password: 'Şifre',
  select_role: 'Rol Seçin',
  select_brand: 'Marka Seçiniz',
  create_user_account: 'Kullanıcı Hesabı Oluştur',
  users: 'KULLANICILAR',
  email: 'E-posta',
  begin: 'Başlamak',
  manage: 'Üstesinden gelmek',
  goals_targets: 'Amaçlar ve hedefler',
  your_ongoing_jurney_as_a_transparent_and_commited_companies_continues_here: 'Şeffaf ve kararlı şirketler olarak devam eden yolculuğunuz burada devam ediyor',
  goals_sets: 'HEDEF SETLERİ',
  certifications_and_proof_points: 'Sertifikalar ve kanıt noktaları',
  select_category: 'Kategori seç',
  suggested_category: 'Önerilen Kategori',
  discovery_mode: 'Keşif modu',
  questions_answered: 'SORULAR CEVAPLANDI',
  select_the_values_you_want_to_claim_to_easily_aggregate_your_data_in_the_section_below_updates_automatically:
    'Aşağıdaki bölümde verilerinizi kolayca toplamak için talep etmek istediğiniz değerleri seçin - otomatik olarak güncellenir.',
  view_and_reply_to_as_many_questions_as_you_can_to_discover_which_values_you_could_claim_based_on_your_data_and_documentation_of_prooof:
    'Verilerinize ve kanıt belgelerinize dayanarak hangi değerleri talep edebileceğinizi keşfetmek için mümkün olduğunca çok soruyu görüntüleyin ve yanıtlayın.',
  next: 'Sonraki',
  prev: 'Önceki',
  uploaded: 'Yüklendi',
  you_have_set_this_as_a_goal: 'Bunu bir hedef olarak belirlediniz!',
  set_as_future_goal: 'Gelecek hedefi olarak belirleyin',
  choose_file: 'Dosya seçin',
  file_types: 'pdf, doc, docx, csv, txt, xlsx, ppt, pptx, png, jpg, jpeg',
  extra_notes: 'Ekstra notlar',
  pick_from_library: 'Kütüphaneden Seç',
  create_new: 'Yeni oluşturmak',
  certificate_details: 'Sertifika ayrıntıları',
  attachment: 'EK',
  insert_name: 'İsim girin',
  select_type: 'Tür seçin',
  emitted_by: 'Gönderen',
  insert_date_of_emission: 'Emisyon tarihini girin',
  insert_expiration_date: 'Son kullanma tarihi girin',
  select_certificate_owner: 'Sertifika sahibini seçin',
  create: 'Yaratmak',
  attachment_is_required: 'Ek gerekli',
  name_is_required: 'İsim gerekli',
  document_type_is_required: 'Belge türü gerekli',
  emitted_by_is_required: 'Gönderen gereklidir',
  emission_date_is_required: 'Emisyon tarihi gerekli',
  expiration_date_is_required: 'Son kullanma tarihi gerekli',
  certificate_owner_is_required: 'Sertifika sahibi gerekli',
  pick_certificate: 'Sertifika Seç',
  preview: 'Ön izleme',
  name: 'İsim',
  date_of_emission: 'Emisyon tarihi',
  expiration_date: 'Son kullanma tarihi',
  alerts: 'Uyarılar',
  thirty_days_before_expiration_date: 'Son kullanma tarihinden 30 gün önce',
  validated: 'Doğrulandı',
  form_issuing_today: 'Bugün yayımlanmasından itibaren',
  certifications_and_proof_points_uploaded: 'Sertifikalar ve kanıt noktaları yüklendi',
  verified_date: 'Doğrulanma Tarihi',
  privacy: 'Mahremiyet',
  search: 'Aramak',
  view_profile: 'Profili Görüntüle',
  user_name: 'Kullanıcı adı',
  user_profile: 'Kullanıcı profili',
  log_out: 'Çıkış Yap',
  user_details: 'Kullanıcı detayları',
  full_name: 'Ad Soyad',
  form_status: 'Form durumu',
  visibility: 'Görünürlük',
  image: 'Resim',
  E_commerce_item_code: 'E-ticaret Ürün Kodu',
  title: 'Başlık',
  supply_chain: 'Tedarik zinciri',
  collection: 'Toplamak',
  uploaded_products: 'Yüklenen Ürünler',
  upload_product: 'Ürünleri Yükle',
  new_product: 'Yeni ürün',
  additional_product_facts_form: 'Ek ürün bilgileri formu',
  total: 'Toplam',
  product_title: 'Ürün başlığı',
  enter_sku: "SKU'yu girin",
  e_commerce_id: 'E-ticaret kimliği',
  item_code: 'Ürün Kodu',
  insert_collection: 'Koleksiyon Ekle',
  composition: 'Kompozisyon',
  care_instruction: 'Bakım Talimatları',
  additional_care_info: 'Ek Bakım Bilgisi',
  weight: 'Ağırlık',
  colors: 'Renkler',
  choose_category: 'Kategori Seçin',
  price: 'Fiyat',
  save: 'Kaydetmek',
  enter_detail_here: 'Detayı buraya girin',
  enter_collection_name: 'Koleksiyon adını girin',
  mandatory_for_product_creation: 'Ürün oluşturma için zorunlu',
  mandatory_for_qr_generation: 'QR üretimi için zorunlu',
  create_new_product: 'Yeni ürün oluştur',
  attach_image: 'Resim Ekle',
  upload_files: 'Dosyaları yükle',
  upload_CSV_file: 'CSV dosyasını yükle',
  upload_images_zip: 'Resimleri veya zip dosyasını yükleyin',
  update_product: 'Ürünü Güncelle',
  material: 'Malzeme',
  percentage: 'Yüzde',
  add: 'Eklemek',
  qr_id: 'QR Kimliği',
  mandatory_ecommerceId: 'Zorunlu e-ticaret kimliği',
  weave: 'Örgü',
  title_is_required: 'Başlık gerekli',
  vanity_title_is_required: 'Vanity Başlığı gereklidir',
  weave_is_required: 'Örgü gereklidir',
  sku_id_is_required: 'SKU kimliği gerekli',
  ecommerce_id_is_required: 'E-ticaret kimliği gerekli',
  cannot_contain_slash_or_underscore: 'Eğik çizgi veya alt çizgi içeremez',
  no_files_selected: 'Dosya seçilmedi',
  invalid_file_format: 'Geçersiz dosya biçimi (yalnızca .jpeg,.png desteklenir)',
  file_size_exceeds_limit: 'Dosya boyutu sınırı aşıyor (maks. 1mb)',
  total_file_size_exceeds_limit: 'Toplam dosya boyutu sınırı aşıyor (maks. 5 MB)',
  active: 'Aktif',
  archive: 'Arşiv',
  download_QR: "QR'yi indirin",
  no_product_found: 'Ürün bulunamadı',
  these_are_the_claim_which_are_approved_pending_and_rejected_please_take_the_action_as_requested:
    'Bunlar onaylanan, beklemede olan ve reddedilen hak talepleridir. Lütfen istendiği gibi işlem yapın.',
  requested_category: 'İstenilen kategori',
  submit_the_detail_and_wait_for_approval: 'Detayı gönderin ve onay bekleyin',
  submitted: 'Gönderilen',
  set_of_questions_to_be_answered: 'Cevaplanması gereken sorular dizisi',
  verify: 'Doğrulamak',
  categories_to_be_reviewed: 'İncelenecek kategoriler',
  are_you_sure_you_want_to_delete_this_certificate: 'Bu sertifikayı silmek istediğinizden emin misiniz?',
  delete_certificate: 'Sertifikayı Sil',
  select_the_value_you_want_to_claim_to_easily_aggregate_your_data_in_the_section_below: 'Aşağıdaki bölümde verilerinizi kolayca toplamak için talep etmek istediğiniz değeri seçin',
  review_categories: 'Kategorileri İncele',
  review: 'Gözden geçirmek',
  categories: 'Kategoriler',
  categories_and_claims: 'Kategoriler ve hak talepleri',
  note_before_you_start_you_can_save_and_edit_your_questions_anytime_before_submitting: 'Başlamadan önce, sorularınızı göndermeden önce istediğiniz zaman kaydedip düzenleyebileceğinizi unutmayın.',
  enjoy_the_process: 'Sürecin tadını çıkarın.',
  eco_packaging: 'Eko ambalaj',
  submit_the_detail: 'Detayı gönder',
  claim_status: 'Talep Durumu',
  create_the_claim: 'Talebi oluştur',
  save_claim: 'Talebi kaydet',
  reject_document: 'Belgeyi Reddet',
  reject: 'Reddetmek',
  reason_for_rejection: 'Reddedilme nedeni',
  locally_made: 'Yerel Üretim',
  reviewd_category: 'İncelenen Kategori',
  document_already_uploaded: 'Belge zaten yüklendi',
  select_or_enter_a_claim: 'Bir hak talebi seçin veya girin',
  reason: 'Sebep',
  explain_for_selecting_the_particular_status_for_the_claim: 'Talebin belirli durumunu seçmeyi açıklayın',
  assign_a_label: 'Etiket atama',
  label_name: 'Etiket adı',
  label_eg: 'Örn.: Etiket Adı',
  description: 'Tanım',
  assign_document: 'Belgeyi ata',
  document_attach: 'Belge ekleme',

  assigned_document: 'Atanan belge',
  label_assigned: 'Etiket atandı',
  all: 'Tüm',
  supply_chain_management: 'Tedarik zinciri yönetimi',
  supplier: 'Tedarikçi',
  upload_csv: "CSV'yi yükle",
  add_suppliers: 'Tedarikçi Ekle',
  view_claims: 'Talepleri Görüntüle',
  add_proudcts: 'Ürün veya hizmet ekleyin',
  create_supply_chain: 'Tedarik zinciri oluşturun',
  suppliers_have_products_or_services: 'Tedarikçilerin ürün veya hizmetleri var',
  claim_name: 'Talep adı',
  claims: 'İddialar',
  list_of_claims: 'Talep Listesi',
  add_supplier: 'Tedarikçi Ekle',
  name_eg: 'Örn.: Renoon',
  vanity_name: 'Özel Ad',
  products: 'Ürünler',
  website_url: "Web Sitesi URL'si",
  website_eg: 'Örn.: https://renoon.com',
  vat_eg: 'Örn.: KDV',
  vat: 'KDV',
  address: 'Adres',
  state: 'Durum',
  city: 'Şehir',
  region_country: 'Ülke/Bölge',
  street: 'Sokak',
  upload_images: 'Görselleri Yükle',
  contact: 'Temas etmek',
  no_of_employee: 'Çalışan sayısı',
  no_of_factories: 'Fabrika sayısı',
  add_a_new_supplier_by_submitting_this_details: 'Bu ayrıntıları göndererek yeni bir tedarikçi ekleyin',
  add_new_products: 'Yeni ürünler ekle',
  document: 'Belge',
  select_products: 'Ürünleri Seçin',
  more_custom_properties_may_be_added_like_colors_etc: 'Renkler vb. gibi daha fazla özel özellik eklenebilir.',
  they_are_not_always_relevent_and_not_needed_for_the_mvp: 'Bunlar her zaman alakalı değildir ve MVP için gerekli değildir.',
  vanity_name_is_required: 'Özel ad gerekli',
  website_url_is_required: "Web sitesi URL'si gerekli",
  vat_is_required: 'KDV gereklidir',
  description_is_required: 'Açıklama gerekli',
  contact_name_is_required: 'Kişi adı gerekli',
  contact_email_is_required: 'İletişim e-postası gerekli',
  please_enter_a_valid_email: 'lütfen geçerli eposta adresini giriniz',
  street_is_required: 'Sokak gerekli',
  city_name_is_required: 'Şehir adı gerekli',
  country_is_required: 'Ülke gerekli',
  state_is_required: 'Devlet gereklidir',
  add_new: 'Yeni ekle',
  stage: 'Sahne',
  sku: 'Stok Kodu',
  sku_eg: 'Örn.: SKU',
  add_new_product_or_service: 'Yeni ürün veya hizmet ekleyin',
  stage_is_required: 'Aşama gerekli',
  delete_supplier: 'Tedarikçiyi Sil',
  are_you_sure_you_want_to_delete_this_supplier: 'Bu tedarikçiyi silmek istediğinizden emin misiniz ?',
  view_claim: 'Talebi Görüntüle',
  search_products: 'ürünleri ara',
  create_product: 'Ürün Oluştur',
  create_service: 'Hizmet Oluştur',
  image_type: 'png, jpg, jpeg',
  are_you_sure_you_want_to_delete_this_supplier_product: 'Bu tedarikçi ürününü silmek istediğinizden emin misiniz?',
  search_services: 'Arama Hizmetleri',
  edit_supply_chain: 'Tedarik Zincirini Düzenle',
  are_you_sure_you_want_to_delete_this_supply_chain: 'Bu tedarik zincirini silmek istediğinizden emin misiniz?',
  delete_supply_Chain: 'Tedarik Zincirini Sil',
  continue: 'Devam etmek',
  select_the_supplier: 'Tedarikçiyi seçin',
  edit_product: 'Ürünü Düzenle',
  save_draft: 'Taslağı kaydet',
  detailed_view: 'Detaylı görünüm',
  they_are_not_always_relevant_and_not_needed_for_the_mvp: 'Bunlar her zaman alakalı değildir ve MVP için gerekli değildir.',
  add_products: 'Ürün Ekle',
  select_the_supplier_product: 'Tedarikçinin ürününü seçin',
  is_in_supply_chain: 'Tedarik zincirinde',
  country: 'Ülke',
  has_suppliers_products: 'Tedarikçinin ürünleri var',
  sort_by: 'Göre sırala',
  filter: 'Filtre',
  clear_all: 'Hepsini temizle',
  has_products: 'Ürünleri var',
  is_in_a_supply_chain: 'Bir tedarik zincirinde yer alıyor',
  service: 'Hizmet',
  product: 'Ürün',
  type: 'Ürün tipi',
  has_suppliers: 'Tedarikçileri var',
  are_you_sure_you_want_to_delete_this_chain_product: 'Bu zincir Ürünü silmek istediğinizden emin misiniz?',
  are_you_sure_you_want_to_delete_this_chain_supplier: 'Bu Tedarikçi zincirini silmek istediğinizden emin misiniz?',
  zoom_in: 'Yakınlaştır',
  zoom_out: 'Uzaklaştır',
  reset: 'Sıfırla',
  no_certificate_found: 'Sertifika bulunamadı',
  reviewed_claims: 'İNCELENEN İDDİALAR',
  view_all: 'Hepsini gör',
  unique_Id: 'Benzersiz Kimlik',
  view_docs: 'Dokümanları Görüntüle',
  select_the_value_you_want_to_communicate: 'İletmek istediğiniz değeri seçin.',
  edit_supplier: 'Tedarikçiyi Düzenle',
  submit_for_approval: 'Onay için gönder',
  fill_the_forms: 'Formları doldurun',
  the_forms_are_saved_automatically_You_can_submit_the_forms_once_all_the_questions_are_answered: 'Formlar otomatik olarak kaydedilir. Tüm soruları cevapladıktan sonra formları gönderebilirsiniz.',
  opens_the_selected_document_for_reading_with_zoom_and_scroll_functionality_Allows_quick_access_without_making_changes:
    'Seçilen belgeyi yakınlaştırma ve kaydırma işleviyle okumak için açar. Değişiklik yapmadan hızlı erişim sağlar.',
  documents: 'BELGELER',
  create_brand: 'Marka Yarat',

  select_claim: 'Hak Talebini Seçin',
  select_supplier: 'Tedarikçi Seçin',
  select_product: 'Ürün Seçiniz',
  filter_by: 'Filtrele',
  additional_settings: 'Ek Ayarlar',
  stage_vanity_name: 'Sahne Vanity Adı',
  vanity_title: 'Gösteriş Başlığı',
  has_not_product: 'Vanity TiÜrün yok',
  read_more: 'Devamını oku',
  read_less: 'Daha az oku',
  add_user: 'Kullanıcı Ekle',
  files: 'Dosyalar',
  file_type: 'Dosya Türü',
  deselect_all: 'Tümünün Seçimini Kaldır',
  select_all: 'Tümünü Seç',
  is_archived: 'Arşivlendi',
  qr_code: 'QR Kodu',
  date: 'tarih',
  only_image_files_PNG_JPEG_JPG_are_allowed:"Yalnızca resim dosyalarına (PNG, JPG, JPEG) izin verilir.",
  only_PDF_files_are_allowed:"Yalnızca PDF dosyalarına izin verilir",
  download_qr:"QR Kodunu İndirin",

  no_category_found:"Kategori bulunamadı",
  yes:"Evet",
  no:"NoHAYIRn",
  collectionName:"koleksiyonAdı",
  Name:"İsim",
  Date:"Tarih",
  Title:"Başlık",
  Stage:"Sahne",
  PRODUCT:"ÜRÜN",
  SERVICE:"HİZMET",
  Verified:"Doğrulandı",
  Proven:"Kanıtlanmış",
  Declared:"Beyan edildi",
  Rejected:"Reddetmek",
  additional_information:"Ek Bilgiler",
  upload:"Yüklemek",
  SUPPLIER:"TEDARİKÇİ",
  SUPPLIER_CHAIN:"TEDARİKÇİ ZİNCİRİ",
  SUPPLIER_PRODUCT:"TEDARİKÇİ-ÜRÜN",
  BRAND_PRODUCT:"MARKA-ÜRÜN",
  Less_than_9:"9'dan az",
  ten_to_49:"10 ila 49",
  fifty_to_249:"50 ila 249",
  More_than_250:"250'den fazla",
  VERIFIED:"DOĞRULANDI",
  PROVEN:"KANITLANMIŞ",
  DECLARED:"BEYAN EDİLDİ",
  REJECTED:"REDDEDİLMİŞ",
  PENDING:"ASKIDA OLMASI"
};
export default turkishTranslation;
