import * as React from 'react';
import Popover from '@mui/material/Popover';
import { IconButton, InputLabel } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Box, FormControl, MenuItem, Select, Typography, ListItemText, Button } from '@mui/material';
import SortIcon from '@mui/icons-material/Sort';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useTranslation } from 'react-i18next';
import { CountryList } from './CommonList';
import { useDispatch } from 'react-redux';
import { fetchSupplier, fetchSupplierWithCountry, fetchSupplierWithOutProduct, fetchSupplierWithProducts, fetchSupplierWithSort } from '../../features/supplierSlice';

export default function FilterSupplier(props) {
  const { page, itemsPerPage } = props;
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setHasProduct('');
    setIsNotProduct('');
    setCountryName('');
    setSort('');
  };
  const [hasProduct, setHasProduct] = React.useState('');
  const [isNotProduct, setIsNotProduct] = React.useState('');
  const [countryName, setCountryName] = React.useState('');
  const [sort, setSort] = React.useState('');

  const handleHasProducts = (event) => {
    setHasProduct(event.target.value);
    let product = event.target.value;
    
    dispatch(fetchSupplierWithProducts({ page, itemsPerPage, product }));
    setAnchorEl(null);
  };

  const handleSupplierInSupplyChain = (event) => {
    setIsNotProduct(event.target.value);
    let product = event.target.value;
    dispatch(fetchSupplierWithOutProduct({ page, itemsPerPage, product }));
    setAnchorEl(null);
  };

  const handleCountryChange = (event) => {
    setCountryName(event.target.value);
    let country = event.target.value;
    dispatch(fetchSupplierWithCountry({ page, itemsPerPage, country }));
    setAnchorEl(null);
  };

  const handleSortChange = (event) => {
    setSort(event.target.value);
    let sort = event.target.value;
    dispatch(fetchSupplierWithSort({ page, itemsPerPage, sort }));
    setAnchorEl(null);
  };

  const handleClearAll = () => {
    dispatch(fetchSupplier({ page, itemsPerPage }));
    setHasProduct('');
    setIsNotProduct('');
    setCountryName('');
    setSort('');
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <IconButton onClick={handleClick} sx={{ bgcolor: '#0e1090', borderRadius: '30px', ':hover': { bgcolor: '#0e1090' } }}>
        <FilterListIcon sx={{ color: '#fff' }} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ width: 300, padding: 2, border: '1px solid #ddd', borderRadius: 1 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
              {t('filter')} <FilterAltIcon sx={{ fontSize: '20px' }} />
            </Typography>
            <Button onClick={handleClearAll} sx={{ fontSize: '14px', fontWeight: 'bold', textTransform: 'capitalize', color: '#0e1090' }}>
              {t('clear_all')}
            </Button>
          </Box>
          <InputLabel sx={{ fontSize: '14px' }}>{t('has_suppliers_products')}</InputLabel>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <Select variant="standard" size="small" value={hasProduct} onChange={handleHasProducts}>
              {[t('yes'), t('no')].map((option) => (
                <MenuItem key={option} value={option} sx={{ fontSize: '12px' }}>
                  <ListItemText primary={option} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <InputLabel sx={{ fontSize: '14px' }}>{t('is_in_a_supply_chain')}</InputLabel>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <Select variant="standard" size="small" value={isNotProduct} onChange={handleSupplierInSupplyChain}>
              {[t('yes'), t('no')].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <InputLabel sx={{ fontSize: '14px' }}>{t('country')}</InputLabel>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <Select variant="standard" size="small" value={countryName} onChange={handleCountryChange}>
              {CountryList.map((country) => (
                <MenuItem key={country} value={country}>
                  {country}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
            {t('sort_by')} <SortIcon />
          </Typography>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <Select variant="standard" size="small" value={sort} onChange={handleSortChange}>
              {[t('name_'), t('date_')].map((sortOption) => (
                <MenuItem key={sortOption} value={sortOption} sx={{textTransform:"capitalize"}}>
                  {sortOption}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Popover>
    </>
  );
}
