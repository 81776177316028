import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CommonInput from '../utils/CommonInput';
import LanguageSelector from '../utils/LanguageSelector';
import { useEffect, useRef, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import httpService from '../../service/http.service';
import { RestUrlsConstants } from '../../constants/rest-urls.constants';
import { CategoryStatus, CertificateStatus } from '../../constants/common.constants';
import ArticleIcon from '@mui/icons-material/Article';
import SelectClaimSupplier from './ClaimSupplier';
import SeleceClaimProduct from './ClaimProduct';

const CreateClaim = (props) => {
  const {
    setOpenClaimForm,
    selectCategoryId,
    setSelectCategoryId,
    fetchClaim,
    certificate,
    categoryName,
    claimOptions,
    claimFormData,
    setClaimFormData,
    updateClaim,
    setUpdateClaim,
    lang,
    setLang,
    claimId,
    selectedCertificates,
    setSelectedCertificates,
    claimSupplier,
    setClaimSupplier,
    claimProduct,
    setClaimProduct,
  } = props;
  const { t } = useTranslation();
  const [snackBar, setSnackBar] = useState({ open: false, message: '' });
  const scrollContainerRef = useRef(null);
  const [certificates, setCertificates] = useState(certificate);

  const [errors, setErrors] = useState({ claimName: '', label: '', description: '' });
  const handleCertificateClick = (certificate) => {
    setSelectedCertificates((prevSelected) => {
      const isAlreadySelected = prevSelected.find((item) => item.id === certificate.id);
      let updatedSelectedCertificates;
      if (isAlreadySelected) {
        updatedSelectedCertificates = prevSelected.filter((item) => item.id !== certificate.id);
      } else {
        updatedSelectedCertificates = [...prevSelected, certificate];
      }
      setClaimFormData((prevData) => ({
        ...prevData,
        certificates: updatedSelectedCertificates.map((item) => item.id),
      }));

      return updatedSelectedCertificates;
    });
  };
  const isSelected = (id) => selectedCertificates.some((item) => item.id === id);

  const validateForm = () => {
    let valid = true;
    const newErrors = { claimName: '', label: '', description: '' };

    if (!claimFormData.claimName) {
      newErrors.claimName = 'Claim Name is required';
      valid = false;
    }
    if (!claimFormData.label) {
      newErrors.label = 'Label is required';
      valid = false;
    }

    if (claimFormData.claimStatus !== 'REJECTED' && !claimFormData.description) {
      newErrors.description = 'Description is required';
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const createClaim = async () => {
    if (!claimId) {
      if (!validateForm()) return;
      console.log(claimFormData);
      try {
        const payload = {
          claimName: claimFormData.claimName,
          label: claimFormData.label,
          categoryId: selectCategoryId,
          lang: lang,
          claimStatus: claimFormData.claimStatus,
          certificates: claimFormData.certificates,
          // description: claimFormData.description,
        };
        if (claimFormData.claimStatus !== 'REJECTED' || claimFormData.description) {
          payload.description = claimFormData.description;
        }
        if (claimFormData.reason || claimFormData.claimStatus === CategoryStatus.rejected) {
          payload.reason = claimFormData?.reason;
        }
        const response = await httpService.post(`${RestUrlsConstants.claimUrl}`, payload, {
          headers: {
            Authorization: localStorage.getItem('Authorization'),
            'Content-Type': 'application/json',
          },
        });
        if (response?.data?.status) {
          setSnackBar({ open: true, message: response?.data?.message });
          setOpenClaimForm(false);
          setSelectCategoryId('');
          fetchClaim();
          setSelectedCertificates([]);
          setClaimFormData({
            claimName: '',
            label: '',
            claimStatus: '',
            reason: '',
            certificates: [],
            description: '',
          });
        }
      } catch (error) {
        console.error('Error fetching form:', error);
        setSnackBar({ open: true, message: error?.response?.data?.message });
      }
    } else {
      if (!validateForm()) return;
      try {
        const payload = {
          claimName: claimFormData.claimName,
          label: claimFormData.label,
          lang: lang,
          claimStatus: claimFormData.claimStatus,
          certificates: claimFormData.certificates,
          description: claimFormData.description,
          suppliers: claimSupplier,
          products: claimProduct,
        };
        if (claimFormData.reason && claimFormData.claimStatus === CategoryStatus.rejected) {
          payload.reason = claimFormData?.reason;
        }
        const response = await httpService.patch(`${RestUrlsConstants.claimUrl}${claimId}`, payload, {
          headers: {
            Authorization: localStorage.getItem('Authorization'),
            'Content-Type': 'application/json',
          },
        });
        if (response?.data?.status) {
          setSnackBar({ open: true, message: response?.data?.message });
          setOpenClaimForm(false);
          setSelectCategoryId('');
          fetchClaim();
          setSelectedCertificates([]);
          setUpdateClaim(false);
          setClaimFormData({
            claimName: '',
            label: '',
            claimStatus: '',
            reason: '',
            certificates: [],
            description: '',
          });
        }
      } catch (error) {
        console.error('Error fetching claim:', error);
        setSnackBar({ open: true, message: error?.response?.data?.message });
      }
    }
  };

  const snackbarClose = () => {
    setSnackBar({ open: false, message: '' });
  };

  const scroll = (direction) => {
    const { current } = scrollContainerRef;
    if (current) {
      const scrollAmount = 200;
      current.scrollBy({
        left: direction === 'left' ? -scrollAmount : scrollAmount,
        behavior: 'smooth',
      });
    }
  };
  const [searchTerm, setSearchTerm] = useState('');
  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    if (term.trim() === '') {
      setCertificates(certificate);
    } else {
      const filtered = certificate.filter((cert) => cert.name.toLowerCase().includes(term.trim().toLowerCase()));
      setCertificates(filtered);
    }
  };
  function getFileExtension(url) {
    const extensionRegex = /\.([a-zA-Z0-9]+)(?=\?|$)/;
    const match = extensionRegex.exec(url);
    if (match) {
      const extension = match[1].toLowerCase();
      return extension === 'pdf' || extension === 'doc' || extension === 'docx' || extension === 'csv' || extension === 'ppt' || extension === 'pptx' || extension === 'txt' || extension === 'xlsx'
        ? extension
        : '';
    }
    return '';
  }
  const [value, setValue] = useState(1);

  const handleChange = (newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (!updateClaim) {
      setValue(1);
    }
  }, [updateClaim]);

  return (
    <>
      <Grid item xs={8}>
        {updateClaim && (
          <Box sx={{ display: 'flex', gap: '20px', mb: 1 }}>
            <Chip onClick={() => handleChange(1)} label={t('select_claim')} sx={{ bgcolor: value === 1 ? '#000' : 'gray', color: '#fff', px: 2 }} />
            <Chip onClick={() => handleChange(2)} label={t('select_supplier')} sx={{ bgcolor: value === 2 ? '#000' : 'gray', color: '#fff', px: 2 }} />
            <Chip onClick={() => handleChange(3)} label={t('select_product')} sx={{ bgcolor: value === 3 ? '#000' : 'gray', color: '#fff', px: 2 }} />
          </Box>
        )}
        {value === 1 && (
          <Card sx={{ boxShadow: 'none' }}>
            <CardContent>
              <Typography sx={{ fontWeight: '600', fontSize: '16px', display: 'flex', gap: '10px', color: '#0e1090' }}>{categoryName}</Typography>
              <Autocomplete
                fullWidth
                size="small"
                disablePortal
                id="combo-box-demo"
                options={Array.isArray(claimOptions) ? claimOptions?.filter((option) => option !== null) : []}
                defaultValue={[]}
                value={claimFormData.claimName}
                onChange={(event, newValue) => {
                  setClaimFormData({ ...claimFormData, claimName: newValue });
                  setErrors({ ...errors, claimName: '' });
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '40px',
                  },
                  mt: 3,
                }}
                renderInput={(params) => <TextField {...params} placeholder={t('select_or_enter_a_claim')} error={!!errors.claimName} helperText={errors.claimName} />}
              />
              <InputLabel sx={{ mt: 4, color: '#000', fontWeight: '600', fontSize: '14px' }}>{t('claim_status')}</InputLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                onChange={(e) => setClaimFormData({ ...claimFormData, claimStatus: e.target.value })}
                value={claimFormData.claimStatus}
                sx={{
                  '& .MuiRadio-root': {
                    color: 'black',
                  },
                  '& .Mui-checked': {
                    color: 'black',
                  },
                }}
              >
                <FormControlLabel value="VERIFIED" control={<Radio />} label={'Verified'} />
                <FormControlLabel value="PROVEN" control={<Radio />} label={'Proven'} />
                <FormControlLabel value="DECLARED" control={<Radio />} label={'Declared'} />
                <FormControlLabel value="REJECTED" control={<Radio />} label={'Rejected'} />
              </RadioGroup>
              {claimFormData.claimStatus === CategoryStatus.rejected ? (
                <CommonInput
                  type="text"
                  label={t('reason') + '*'}
                  placeholder={t('explain_for_selecting_the_particular_status_for_the_claim')}
                  value={claimFormData.reason}
                  handleChange={(e) => setClaimFormData({ ...claimFormData, reason: e.target.value })}
                  variant="standard"
                />
              ) : null}
              <CommonInput
                type="text"
                label={t('assign_a_label')}
                placeholder={t('label_name')}
                value={claimFormData.label}
                handleChange={(e) => {
                  setClaimFormData({ ...claimFormData, label: e.target.value });
                  setErrors({ ...errors, label: '' });
                }}
                variant="standard"
                helperText={errors.label}
                error={!!errors.label}
              />
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1 }}>
                <InputLabel sx={{ color: '#000', fontWeight: '600', fontSize: '14px' }}>{`${t('description')}${claimFormData.claimStatus === CategoryStatus.rejected ? '' : '*'}`}</InputLabel>
                <LanguageSelector selectLang={lang} setLang={setLang} />
              </Box>
              <CommonInput
                type="text"
                placeholder={t('description')}
                value={claimFormData.description}
                handleChange={(e) => {
                  setClaimFormData({ ...claimFormData, description: e.target.value });
                  setErrors({ ...errors, description: '' });
                }}
                variant="standard"
                error={!!errors.description}
                helperText={errors.description}
              />
              <InputLabel sx={{ mt: 4, color: '#000', fontWeight: '600', fontSize: '16px' }}>{t('assign_document')}</InputLabel>
              <InputLabel sx={{ mt: 2, mb: 2, color: 'gray', fontWeight: '600', fontSize: '12px' }}>{t('document_attach')}</InputLabel>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', width: '100%', gap: '10px' }}>
                {selectedCertificates?.map((item) => (
                  <>
                    {(item?.urls?.toString().includes('.pdf') ||
                      item?.urls?.toString().includes('.doc') ||
                      item?.urls?.toString().includes('.docx') ||
                      item?.urls?.toString().includes('.csv') ||
                      item?.urls?.toString().includes('.txt') ||
                      item?.urls?.toString().includes('.xlsx') ||
                      item?.urls?.toString().includes('.ppt') ||
                      item?.urls?.toString().includes('.pptx')) && (
                      <Box minWidth={'100px'}>
                        <Box
                          sx={{
                            height: '120px',
                            width: '100px',
                            bgcolor: '#fff',
                            borderRadius: '8px',
                            textAlign: 'justify',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'relative',
                            border: '1px solid #0e1090',
                            cursor: 'pointer',
                          }}
                        >
                          <ArticleIcon style={{ fontSize: '50px' }} />
                          <span style={{ fontSize: '14px', color: 'gray', textTransform: 'uppercase' }}>{getFileExtension(item.urls[0])}</span>
                          <Chip
                            label={item?.verificationStatus === CertificateStatus.valid ? 'Valid' : item?.verificationStatus === CertificateStatus.notValid ? 'Not Valid' : 'Yet to be valid'}
                            color={item?.verificationStatus === CertificateStatus.valid ? 'success' : item?.verificationStatus === CertificateStatus.notValid ? 'error' : 'warning'}
                            sx={{
                              height: '20px',
                              color: '#fff',
                              position: 'absolute',
                              bottom: '3px',
                              left: '50%',
                              transform: 'translateX(-50%)',
                              fontSize: '10px',
                            }}
                            // icon={<CheckCircleIcon sx={{ color: '#fff', fontSize: '18px' }} />}
                          />
                          <CancelIcon onClick={() => handleCertificateClick(item)} style={{ color: '#0e1090', position: 'absolute', top: 0, right: 0, fontSize: '20px' }} />
                        </Box>
                        <Tooltip title={item?.name} placement="top">
                          <Typography
                            sx={{
                              fontSize: '12px',
                              color: '#000',
                              textAlign: 'center',
                              px: 1,
                              alignItems: 'center',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              wordWrap: 'break-word',
                              width: '100px',
                            }}
                          >
                            {item?.name}
                          </Typography>
                        </Tooltip>
                      </Box>
                    )}
                    {(item?.urls?.toString().includes('.jpg') || item?.urls?.toString().includes('.png') || item?.urls?.toString().includes('.jpeg')) && (
                      <Box>
                        <Box sx={{ position: 'relative' }}>
                          <img src={item?.urls} alt="" height={'120px'} width={'100px'} style={{ borderRadius: '8px', border: '1px solid #0e1090' }} />
                          <Chip
                            label={item?.verificationStatus === CertificateStatus.valid ? 'Valid' : item?.verificationStatus === CertificateStatus.notValid ? 'Not Valid' : 'Yet to be valid'}
                            color={item?.verificationStatus === CertificateStatus.valid ? 'success' : item?.verificationStatus === CertificateStatus.notValid ? 'error' : 'warning'}
                            sx={{
                              height: '20px',
                              color: '#fff',
                              position: 'absolute',
                              bottom: '3px',
                              left: '50%',
                              transform: 'translateX(-50%)',
                              fontSize: '10px',
                            }}
                            // icon={<CheckCircleIcon sx={{ color: '#fff', fontSize: '18px' }} />}
                          />
                          <CancelIcon onClick={() => handleCertificateClick(item)} style={{ color: '#0e1090', position: 'absolute', top: 0, right: 0, fontSize: '20px' }} />
                        </Box>
                        <Tooltip title={item?.name} placement="top">
                          <Typography
                            sx={{
                              fontSize: '12px',
                              color: '#000',
                              textAlign: 'center',
                              px: 1,
                              alignItems: 'center',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              wordWrap: 'break-word',
                              width: '100px',
                            }}
                          >
                            {item?.name}
                          </Typography>
                        </Tooltip>
                      </Box>
                    )}
                  </>
                ))}
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 4 }}>
                <InputLabel sx={{ color: 'gray', fontSize: '16px' }}>{t('document_already_uploaded')}</InputLabel>
                <TextField
                  value={searchTerm}
                  onChange={(e) => handleSearchChange(e)}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '40px',
                    },
                  }}
                  size="small"
                  variant="outlined"
                  placeholder={t('search')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              {certificate?.length > 0 ? (
                <Box maxWidth={'100%'} display="flex" alignItems="center" position={'relative'} p={0}>
                  <ArrowBackIosIcon sx={{ position: 'absolute', left: 0, zIndex: 1000 }} onClick={() => scroll('left')} />
                  <Box className="example" ref={scrollContainerRef} display="flex" overflow="auto" whiteSpace="nowrap" sx={{ width: '100%', mt: 2 }}>
                    {certificates?.map((item, index) => (
                      <>
                        {(item?.urls?.toString().includes('.pdf') ||
                          item?.urls?.toString().includes('.doc') ||
                          item?.urls?.toString().includes('.docx') ||
                          item?.urls?.toString().includes('.csv') ||
                          item?.urls?.toString().includes('.txt') ||
                          item?.urls?.toString().includes('.ppt') ||
                          item?.urls?.toString().includes('.pptx') ||
                          item?.urls?.toString().includes('.xlsx')) && (
                          <Box mx={1} minWidth={'100px'}>
                            <Box
                              onClick={() => handleCertificateClick(item)}
                              sx={{
                                height: '120px',
                                width: '100px',
                                bgcolor: '#fff',
                                borderRadius: '8px',
                                textAlign: 'justify',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                position: 'relative',
                                border: isSelected(item.id) ? '2px solid #0e1090' : '1px solid gray',
                                cursor: 'pointer',
                              }}
                            >
                              <ArticleIcon style={{ fontSize: '50px' }} />
                              <span style={{ fontSize: '14px', color: 'gray', textTransform: 'uppercase' }}>{getFileExtension(item.urls[0])}</span>
                              <CheckCircleIcon style={{ color: isSelected(item.id) ? '#0e1090' : 'gray', position: 'absolute', top: 0, right: 0, fontSize: '20px' }} />
                            </Box>
                            <Tooltip title={item?.name} placement="top">
                              <Typography
                                sx={{
                                  fontSize: '12px',
                                  color: '#000',
                                  textAlign: 'center',
                                  px: 1,
                                  alignItems: 'center',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  wordWrap: 'break-word',
                                  width: '100px',
                                }}
                              >
                                {item?.name}
                              </Typography>
                            </Tooltip>
                          </Box>
                        )}
                        {(item?.urls?.toString().includes('.jpg') || item?.urls?.toString().includes('.png') || item?.urls?.toString().includes('.jpeg')) && (
                          <Box mx={1} sx={{ minWidth: '100px' }}>
                            <Box sx={{ position: 'relative' }} onClick={() => handleCertificateClick(item)}>
                              <img
                                src={item?.urls[0]}
                                alt=""
                                height={'120px'}
                                width={'100px'}
                                style={{ borderRadius: '8px', border: isSelected(item.id) ? '2px solid #0e1090' : '1px solid gray', cursor: 'pointer' }}
                              />
                              <CheckCircleIcon style={{ color: isSelected(item.id) ? '#0e1090' : 'gray', position: 'absolute', top: 0, right: 0, fontSize: '20px' }} />
                            </Box>
                            <Tooltip title={item?.name} placement="top">
                              <Typography
                                sx={{
                                  fontSize: '12px',
                                  color: '#000',
                                  textAlign: 'center',
                                  px: 1,
                                  alignItems: 'center',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  wordWrap: 'break-word',
                                  width: '100px',
                                }}
                              >
                                {item?.name}
                              </Typography>
                            </Tooltip>
                          </Box>
                        )}
                      </>
                    ))}
                  </Box>
                  <ArrowForwardIosIcon sx={{ position: 'absolute', right: 0, zIndex: 1000 }} onClick={() => scroll('right')} />
                </Box>
              ) : (
                <Typography sx={{ textAlign: 'center', p: 2 }}>{t('no_certificate_found')}</Typography>
              )}
            </CardContent>
          </Card>
        )}
        {value === 2 && <SelectClaimSupplier claimSupplier={claimSupplier} setClaimSupplier={setClaimSupplier} />}
        {value === 3 && <SeleceClaimProduct claimProduct={claimProduct} setClaimProduct={setClaimProduct} />}
        <Card sx={{ borderRadius: '10px', boxShadow: 'none', mt: 3 }}>
          <CardContent>
            <Box>
              <Typography sx={{ fontWeight: '700', fontSize: '16px' }}>{t('create_the_claim')}</Typography>
              <Typography sx={{ fontSize: '12px', color: 'gray' }}>{t('select_the_values_you_want_to_claim_to_easily_aggregate_your_data_in_the_section_below_updates_automatically')}</Typography>
              <Box sx={{ mt: 2 }}>
                <Button
                  onClick={() => createClaim()}
                  sx={{ fontWeight: 'bold', textTransform: 'none', color: '#fff', borderRadius: '30px', background: '#0e1090', ':hover': { background: '#0e1090' } }}
                >
                  {t('save_claim')} <NavigateNextIcon />
                </Button>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        message={snackBar.message}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={snackbarClose}
        className="snackBarColor"
        key="snackbar"
      />
    </>
  );
};

export default CreateClaim;
