import { Box } from '@mui/material';
import SupplierMap from './supplierMap';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSupplier } from '../../features/supplierSlice';
import { useParams } from 'react-router-dom';

const SupplyChain = () => {
  const params = useParams();
  const [page, setPage] = useState(Number(params?.page) ||1);
  const itemsPerPage = 11;
  const dispatch = useDispatch();
  const { suppliers,loading } = useSelector((state) => state.supplier);
  useEffect(() => {
    dispatch(fetchSupplier({page,itemsPerPage}));
  }, [dispatch,page]);
  const pageCount = Math.ceil(suppliers?.totalRecords/itemsPerPage);
  return (
    <Box>
    <SupplierMap
          supplier={suppliers}
          supplierLoading={loading}
          page={page}
          setPage={setPage}
          pageCount={pageCount}
          itemsPerPage={itemsPerPage}
        />
    </Box>
  );
};

export default SupplyChain;
