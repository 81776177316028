import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import i18n from '../i18n';
import { LANGUAGES } from './Language';

export const LanguageSelector=()=> {
    const onChangeLang = (e) => {
        const lang_code = e.target.value;
        i18n.changeLanguage(lang_code);
      };
 
  return (
    <Box sx={{ minWidth: 120 }}>
        <Select
        size='small'
          defaultValue={i18n.language}
           onChange={onChangeLang}
           sx={{
          width: '100%',
        }}
       labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        >
        {LANGUAGES.map((lang) => (
          <MenuItem sx={{ display: 'flex', alignItems: 'center', wordSpacing: '5px' }} key={lang.code} value={lang.code}>
            <span style={{ marginRight: '5px',borderRadius:"5px" }}>{lang.flag}</span>
            {lang.name}
          </MenuItem>
        ))}
        </Select>
    </Box>
  );
}
