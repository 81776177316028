import React, { useState, useEffect } from 'react';
import axios from 'axios';
import httpService from '../service/http.service';
import { RestUrlsConstants } from '../constants/rest-urls.constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Dialog,
  DialogContent,
  IconButton,
  InputAdornment,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { CommonButton } from './utils/CommonButton';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import Loader from './utils/loader';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const Role = () => {
  const { t } = useTranslation();
  const [roles, setRoles] = useState([]);
  const [roleName, setRoleName] = useState('');
  const [resources, setResources] = useState({
    compliance: true,
    measure: true,
    impactLabel: true,
    reports: true,
    settings: true,
  });
  const [loading, setLoading] = useState(false);
  const [roleLoading, setRoleLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [showTable, setShowTable] = useState(true);
  const [selectedRoleId, setSelectedRoleId] = useState(null);
  const [updateMode, setUpdateMode] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const [orderBy, setOrderBy] = useState('asc');

  useEffect(() => {
    fetchRoles();
  }, []);

  const fetchRoles = async () => {
    setRoleLoading(true);
    try {
      const response = await axios.get(`${RestUrlsConstants.roleUrl}`, {
        headers: { Authorization: localStorage.getItem('Authorization') },
      });
      setRoles(response.data.data);
      setShowTable(true);
    } catch (error) {
      console.error('Error fetching roles:', error);
    } finally {
      setRoleLoading(false);
    }
  };
  const searchRoles = async (term) => {
    setRoleLoading(true);
    try {
      const response = await axios.get(`${RestUrlsConstants.roleUrl}?query=${term}`, {
        headers: { Authorization: localStorage.getItem('Authorization') },
      });
      setRoles(response.data.data);
      setShowTable(true);
    } catch (error) {
      console.error('Error fetching roles:', error);
    } finally {
      setRoleLoading(false);
    }
  };
  const handleSort = async () => {
    const newOrderBy = orderBy === 'asc' ? 'desc' : 'asc';
    setOrderBy(newOrderBy);
    setLoading(true);
    try {
      const response = await httpService.get(`${RestUrlsConstants.roleUrl}?sortBy=name&orderBy=${newOrderBy}`, {
        headers: { Authorization: localStorage.getItem('Authorization') },
      });
      setRoles(response.data.data);
      setShowTable(true);
    } catch (error) {
      console.error('Error fetching roles:', error);
    } finally {
      setLoading(false);
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!roleName.trim()) errors.roleName = t('Role Name is required');
    if (!Object.values(resources).includes(true)) errors.resources = t('At least one resource must be selected');
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const createRole = async () => {
    if (!validateForm()) return;
    try {
      setLoading(true);
      setError(null);
      const role = {
        name: roleName,
        ...resources,
      };

      const response = await httpService.post(RestUrlsConstants.roleUrl, role, {
        headers: { Authorization: localStorage.getItem('Authorization') },
      });

      if (!response.data) {
        throw new Error('Failed to create role');
      }

      await fetchRoles();
      setShowForm(false);
      setShowTable(true);
      setUpdateMode(false);
      resetForm();
      handleClose();
    } catch (error) {
      setError(error?.response?.data?.message || 'Failed to create role');
    } finally {
      setLoading(false);
    }
  };

  const updateRole = async () => {
    if (!validateForm()) return;
    try {
      setLoading(true);
      setError(null);

      const updatedRole = {
        name: roleName,
        ...resources,
      };
      delete updatedRole.userManagement;
      if (selectedRoleId) {
        await httpService.patch(`${RestUrlsConstants.roleUrl}${selectedRoleId}`, updatedRole, {
          headers: { Authorization: localStorage.getItem('Authorization') },
        });

        await fetchRoles();
        setShowForm(false);
        setShowTable(true);
        setUpdateMode(false);
        resetForm();
        handleClose();
      } else {
        throw new Error('Selected role ID is invalid or missing.');
      }
    } catch (error) {
      console.log(error?.response?.data?.message);
      setError(error?.response?.data?.message || 'Failed to update role');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateRole = (roleId) => {
    const selectedRole = roles.find((role) => role.id === roleId);
    setRoleName(selectedRole.name);
    setResources(selectedRole.resources);
    setSelectedRoleId(roleId);
    setUpdateMode(true);
    setShowForm(true);
    setShowTable(false);
    handleOpen();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (updateMode) {
      await updateRole();
    } else {
      await createRole();
    }
  };

  const resetForm = () => {
    setRoleName('');
    setResources({
      compliance: true,
      measure: true,
      impactLabel: true,
      reports: true,
      settings: true,
    });
    setValidationErrors({});
  };

  const toggleFormVisibility = () => {
    setShowForm(!showForm);
    setUpdateMode(false);
    setShowTable(!showTable);
    resetForm();
    handleOpen();
  };

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    if (term.trim() === '') {
      fetchRoles();
    } else {
      searchRoles(term);
    }
  };

  const formatResourceName = (name) => {
    return name === 'impactLabel' ? 'Impact Label' : name;
  };

  const [modalOpen, setModalOpen] = useState(false);

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };
  return (
    <>
      <Card className="example" sx={{ borderRadius: '20px' }}>
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <CommonButton buttonName={t('add_role')} handleClick={toggleFormVisibility} />
            <TextField
              size="small"
              variant="outlined"
              placeholder={t('search')}
              value={searchTerm}
              sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '20px',
                  },
                }}
              onChange={handleSearchChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Dialog open={modalOpen} onClose={handleClose}>
            <DialogContent sx={{ width: '500px' }} className="example">
              <Box>
                <Typography variant="h5" sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                  {updateMode ? t('update_role') : t('role_form')}
                </Typography>
                <form onSubmit={handleSubmit}>
                  <Box>
                    <InputLabel>{t('role_name')}:</InputLabel>
                    <TextField required fullWidth size="small" type="text" id="roleName" placeholder={t('role_name')} value={roleName} onChange={(e) => setRoleName(e.target.value)} />
                    {validationErrors.roleName && <span style={{fontSize:"12px",color:"red"}}>{validationErrors.roleName}</span>}
                  </Box>

                  <Box mt={2} sx={{ display: 'flex', gap: '20px' }}>
                    <InputLabel>{t('resources')}:</InputLabel>
                    <Box>
                      {Object.entries(resources).map(
                        ([key, value]) =>
                          key !== 'userManagement' && (
                            <Box sx={{ display: 'flex', gap: '5px' }} key={key}>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id={`resource-${key}`}
                                checked={value}
                                onChange={() => setResources((prevResources) => ({ ...prevResources, [key]: !value }))}
                              />
                              <InputLabel sx={{ textTransform: 'capitalize' }} htmlFor={`resource-${key}`}>
                                {formatResourceName(key)}
                              </InputLabel>
                            </Box>
                          )
                      )}
                      {validationErrors.resources && <span className="text-danger">{validationErrors.resources}</span>}
                    </Box>
                  </Box>

                  <Box mt={2}>
                    <Button onClick={handleSubmit} fullWidth size="small" type="submit" variant="contained" sx={{ background: '#101010', ':hover': { background: '#101010' },borderRadius:"25px",textTransform:"capitalize" }} disabled={loading}>
                      {loading ? (
                        <>
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        </>
                      ) : updateMode ? (
                        t('update_role')
                      ) : (
                        t('create_role')
                      )}
                    </Button>
                    <Button
                      sx={{ mt: 1, background: 'gray', ':hover': { background: '#535353' },borderRadius:"25px",textTransform:"capitalize" }}
                      fullWidth
                      size="small"
                      variant="contained"
                      onClick={() => {
                        setShowForm(false);
                        setShowTable(true);
                        resetForm();
                        handleClose();
                      }}
                    >
                      {t('cancel')}
                    </Button>
                  </Box>
                </form>
              </Box>
            </DialogContent>
          </Dialog>
          <Box>
            <TableContainer>
              <Typography sx={{ fontWeight: 'bold', mt: 4, fontSize: '24px' }}>{t('roles')}</Typography>
              <Table sx={{ cursor: 'pointer' }}>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: '700', fontSize: '12.5px' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {t('role_name')}
                        <IconButton onClick={handleSort}>{orderBy === 'asc' ? <ArrowUpwardIcon sx={{color:'#000'}}/> : <ArrowDownwardIcon sx={{color:'#000'}}/>}</IconButton>
                      </Box>
                    </TableCell>
                    {Object.keys(resources).map((resource) => {
                      if (resource !== 'userManagement') {
                        return (
                          <TableCell sx={{ fontWeight: '700', fontSize: '12px', textTransform: 'capitalize' }} key={resource}>
                            {formatResourceName(resource)}
                          </TableCell>
                        );
                      }
                      return null;
                    })}
                    <TableCell sx={{ fontWeight: '700', fontSize: '12.5px' }}>{t('actions')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {roleLoading ? (
                    <TableRow>
                      <TableCell colSpan={7}>
                        <Loader />
                      </TableCell>
                    </TableRow>
                  ) : (
                    <>
                      {roles?.map((role) => (
                        <TableRow key={role.id}>
                          <TableCell sx={{ fontWeight: '700', fontSize: '12px' }}>{role.name}</TableCell>
                          {Object.entries(role.resources).map(([resource, hasAccess]) => {
                            if (resource !== 'userManagement') {
                              return (
                                <TableCell key={resource}>
                                  <Checkbox size="small" checked={hasAccess} readOnly color="success" />
                                </TableCell>
                              );
                            }
                            return null;
                          })}
                          <TableCell>
                            <Button
                              size="small"
                              onClick={() => handleUpdateRole(role.id)}
                              sx={{
                                display: 'flex',
                                gap: '5px',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: '12px',
                                color: '#000',
                                ':hover': { background: 'lightgray', color: 'red' },
                                borderRadius: '5px',
                                fontWeight: '700',
                              }}
                            >
                              <FontAwesomeIcon style={{ color: '#0e1090' }} icon={faPenToSquare} />
                              {t('edit')}
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default Role;
