import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import { deepOrange } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { removeUser } from '../features/userSlice';
import Popover from '@mui/material/Popover';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
// import SettingsIcon from '@mui/icons-material/Settings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import VisibilityIcon from '@mui/icons-material/Visibility';

export default function BasicList({ name, email, showLogout, setShowLogout,userType }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const firstName = name?.split(' ')[0][0].toUpperCase() + name?.split(' ')[0].slice(1);
  const lastName = name?.split(' ')[1] && name?.split(' ')[1] && name?.split(' ')[1][0].toUpperCase() + name?.split(' ')[1].slice(1);
  const navigation = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem('Authorization');
    localStorage.removeItem('resources');
    dispatch(removeUser());
    navigation('/login');
  };
  const id = showLogout ? 'simple-popover' : undefined;
  return (
    <>
      <Popover
        id={id}
        open={showLogout}
        anchorEl={showLogout}
        onClose={() => setShowLogout(false)}
        sx={{ marginTop: '80px',}}
        PaperProps={{
          sx: { width: '250px',borderRadius:"20px" },
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box
          sx={{
            textAlign: 'center',
            pb:1
          }}
        >
        <Box pt={5} mb={5} sx={{height:"75px",background:"#101010",}}>
        <Avatar sx={{ bgcolor: '#ec895a', width: 70, height: 70, margin: 'auto',border:"2px solid #fff", }} alt={name} src="/broken-image.jpg">
            {name?.split(' ')[0] && name.split(' ')[0][0] && name.split(' ')[0][0].toUpperCase()}
            {name?.split(' ')[1] && name.split(' ')[1][0] && name.split(' ')[1][0].toUpperCase()}
          </Avatar>
        </Box>
         
          <Typography variant="h6" sx={{ marginTop: 1,color:"#000" }}>
            {firstName + ' ' + lastName}
          </Typography>
          <Typography variant="body2" sx={{ color: '#888' }}>
            {email}
          </Typography>
          <Box px={2} sx={{ marginTop: 2,bgcolor:'#fff' }}>
          {
            userType!=="SYSTEM" &&(
              <Button
              onClick={() => {
                setShowLogout(false);
                navigation('/user-profile');
              }}
              startIcon={<VisibilityIcon />}
              sx={{
                width: '100%',
                justifyContent: 'flex-start',
                color: '#888',
                textTransform: 'none',
              }}
            >
              {t('view_profile')}
            </Button>
            )
          }
            
            {/* <Button
              onClick={() => {
                setShowLogout(false);
                navigation('/settings');
              }}
              startIcon={<SettingsIcon />}
              sx={{
                width: '100%',
                justifyContent: 'flex-start',
                color: '#888',
                textTransform: 'none',
              }}
            >
              {t('settings')}
            </Button> */}
            <Button
              onClick={handleLogout}
              startIcon={<ExitToAppIcon />}
              sx={{
                width: '100%',
                justifyContent: 'flex-start',
                color: '#888',
                textTransform: 'none',
              }}
            >
              {t('log_out')}
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
}
