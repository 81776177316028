import { Box, Button, Card, CardContent, Grid, Typography } from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import docImage from '../../images/image 25.png';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PATH from "../utils/path";

const DocumentCard=()=>{
  const navigate=useNavigate();
  const {t}=useTranslation();

    return(
        <Card sx={{ borderRadius: '10px', boxShadow: 'none', mb: 3 }}>
        <CardContent>
          <Grid container>
            <Grid item xs={12} sm={6} md={6}>
              <img src={docImage} alt="" />
            </Grid>
            <Grid item xs={12} sm={6} md={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
              <Box>
                <Typography sx={{ fontWeight: 'bold' }}>{t('documents')}</Typography>
                <Typography sx={{ fontSize: '12px', color: 'gray' }}>
                  {t('opens_the_selected_document_for_reading_with_zoom_and_scroll_functionality_Allows_quick_access_without_making_changes')}
                </Typography>
              </Box>
              <Button
              size="small"
                variant="contained"
                sx={{ textTransform: 'capitalize', borderRadius: '20px', background: '#101010', ':hover': { background: '#101010' }, width: '130px' }}
                onClick={() => {
                  navigate(PATH.CERTIFICATE)
                }}
              >
                {t('view_docs')}
                <NavigateNextIcon />
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    )
}
export default DocumentCard;