import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import { Box, Tooltip } from '@mui/material';

export default function CommonOption(props) {
    const {label,options,handleChange,value,tooltip}=props;
  return (
    <Box sx={{mt:4}} >
   <Tooltip title={tooltip} placement="top">
      <FormLabel sx={{color:"#000",fontWeight:"600",fontSize:"14px"}}>{label}</FormLabel>
      </Tooltip>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-buttons-group"
        value={value}
       
      >
      {
        options.map((val)=>(
            <FormControlLabel sx={{color:"#000", fontSize: "10px",width:"200px"}} value={val} control={<Radio size="small"  onChange={handleChange} />} label={val} />
        ))
      }
      </RadioGroup>
    </Box>
  );
}
