import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '../service/http.service';
import { RestUrlsConstants } from '../constants/rest-urls.constants';

const initialState = {
  claimByStatus: [],
  claimByStatusLoading: false,
  error: null,
};

export const fetchClaimByStatus = createAsyncThunk(
  'claim/fetchClaimByStatus', 
  async (_, { rejectWithValue }) => {
    try {
      const response = await httpService.get(`${RestUrlsConstants.claimUrl}?groupBy=status`, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
        },
      });
      return response?.data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data || error.message); 
    }
  }
);

const claimSlice = createSlice({
  name: 'claim',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchClaimByStatus.pending, (state) => {
        state.claimByStatusLoading = true;
        state.error = null;
      })
      .addCase(fetchClaimByStatus.fulfilled, (state, action) => {
        state.claimByStatusLoading = false;
        state.claimByStatus = action.payload;
      })
      .addCase(fetchClaimByStatus.rejected, (state, action) => {
        state.claimByStatusLoading = false;
        state.error = action.payload;
      });
  },
});

export default claimSlice.reducer;
