const frenchTranslation = {
  home: 'Maison',
  user_management: 'Gestion des utilisateurs',
  user: 'Utilisateur',
  brand: 'Marque',
  role: 'Rôle',
  measures: 'Mesures',
  claim_management: 'Gestion des réclamations',
  order_engine: 'Moteur de commande',
  product_engine: 'Moteur de produit',
  supplier_map: 'Carte des fournisseurs',
  compliance: 'Conformité',
  over_view: 'Aperçu',
  product_compliance: 'Conformité du produit',
  reports: 'Rapports',
  reporting: 'Rapports',
  product_lca: 'ACV produit',
  clustring: 'Regroupement',
  impact_label: "Étiquette d'impact",
  eCom_widget: 'Widget de commerce électronique',
  qr_codes: 'Codes QR',
  analytics: 'Analytique',
  settings: 'Paramètres',
  back: 'Dos',
  demo_account: 'Compte démo',
  your_product: 'Ton produit',
  retailer: 'Détaillante',
  status: 'Statut',
  approved: 'Approuvé',
  approve: 'Approuver',
  submit_for_review: 'Soumettre pour correction',
  submitted_for_review: 'Soumis pour examen',
  verified: 'Vérifié',
  pending: 'En attente',
  percentile: 'centile',
  renoon_score: 'Score de Renoon',
  brand_name: 'Marque',
  contract_start_date: 'Date de début du contrat',
  contract_end_date: 'Date de fin du contrat',
  max_products: 'Produits maximum',
  max_users: "Nombre maximum d'utilisateurs",
  features: 'Caractéristiques',
  contract_files: 'Dossiers contractuels',
  logo: 'Logo',
  actions: 'Actions',
  add_brand: 'Ajouter une marque',
  brands: 'MARQUES',
  page: 'Page',
  download: 'Télécharger',
  update_brand: 'MISE À JOUR DE LA MARQUE',
  brand_onboarding_form: "FORMULAIRE D'INTÉGRATION DE MARQUE",
  update: 'Mise à jour',
  submit: 'Soumettre',
  cancel: 'Annuler',
  delete: 'Supprimer',
  delete_brand: 'Supprimer la marque',
  are_you_sure_you_want_to_delete_this_brand: 'Etes-vous sûr de vouloir supprimer cette marque ?',
  are_you_sure_you_want_to_delete_this_user: 'Etes-vous sûr de vouloir supprimer cette Utilisateur ?',
  brand_name_required: 'Le nom de la marque est requis',
  contract_start_date_required: 'La date de début du contrat est requise',
  contract_end_date_required: 'La date de fin du contrat est requise',
  max_product_required: 'Max Products est requis',
  max_user_required: "Le nombre maximum d'utilisateurs est requis",
  features_required: 'Des fonctionnalités sont requises',
  contract_files_required: 'Les fichiers de contrat sont requis',
  logo_required: 'Le logo est requis',
  add_role: 'Ajouter un rôle',
  role_name: 'Nom de rôle',
  resources: 'Ressources',
  loading: 'Chargement',
  roles: 'LES RÔLES',
  edit: 'Modifier',
  update_role: 'Mettre à jour le rôle',
  create_role: 'Créer un rôle',
  role_form: 'Formulaire de rôle',
  add_users: 'Ajouter des utilisateurs',
  update_user: "Mettre à jour l'utilisateur",
  user_onboarding_form: "Formulaire d'intégration des utilisateurs",
  first_name: 'Prénom',
  last_name: 'Nom de famille',
  email_address: 'Adresse e-mail',
  password: 'Mot de passe',
  select_role: 'Sélectionnez un rôle',
  select_brand: 'Sélectionnez la marque',
  create_user_account: 'Créer un compte utilisateur',
  users: 'UTILISATEURS',
  email: 'E-mail',
  begin: 'Commencer',
  manage: 'Gérer',
  goals_targets: 'Objectifs et cibles',
  your_ongoing_jurney_as_a_transparent_and_commited_companies_continues_here: 'Votre parcours en tant qu’entreprise transparente et engagée se poursuit ici.',
  goals_sets: 'GOALS SETS',
  certifications_and_proof_points: 'Certifications et points de preuve',
  select_category: 'Choisir une catégorie',
  suggested_category: 'Catégorie suggérée',
  discovery_mode: 'Mode découverte',
  questions_answered: 'QUESTIONS RÉPONSES',
  select_the_values_you_want_to_claim_to_easily_aggregate_your_data_in_the_section_below_updates_automatically:
    'Sélectionnez les valeurs que vous souhaitez revendiquer pour agréger facilement vos données dans la section ci-dessous – mises à jour automatiquement.',
  view_and_reply_to_as_many_questions_as_you_can_to_discover_which_values_you_could_claim_based_on_your_data_and_documentation_of_prooof:
    'Consultez et répondez à autant de questions que possible pour découvrir les valeurs que vous pouvez revendiquer sur la base de vos données et de vos documents de preuve.',
  next: 'Suivant',
  prev: 'Précédent',
  uploaded: 'Uploaded',
  you_have_set_this_as_a_goal: 'Vous vous êtes fixé cela comme objectif !',
  set_as_future_goal: 'Définir comme objectif futur',
  choose_file: 'Choisir le fichier',
  file_types: 'pdf, doc, docx, csv, txt, xlsx, ppt, pptx, png, jpg, jpeg',
  extra_notes: 'Notes supplémentaires',
  pick_from_library: 'Choisir dans la bibliothèque',
  create_new: 'Créer un nouveau',
  certificate_details: 'Détails des certifications',
  attachment: 'Pièce jointe',
  insert_name: 'Insérer le nom',
  select_type: 'Sélectionner le genre',
  emitted_by: 'Émis par',
  insert_date_of_emission: "Insérer la date d'émission",
  insert_expiration_date: "Insérer la date d'expiration",
  select_certificate_owner: 'Sélectionnez le propriétaire du certificat',
  create: 'Créer',
  attachment_is_required: 'Une pièce jointe est requise',
  name_is_required: 'Le nom est requis',
  document_type_is_required: 'Le type de document est requis',
  emitted_by_is_required: 'Émis par est requis',
  emission_date_is_required: "La date d'émission est requise",
  expiration_date_is_required: "La date d'expiration est requise",
  certificate_owner_is_required: 'Le propriétaire du certificat est requis',
  pick_certificate: 'Choisir un certificat',
  preview: 'Aperçu',
  name: 'Nom',
  date_of_emission: "Date d'émission",
  expiration_date: "Date d'expiration",
  alerts: 'Alertes',
  thirty_days_before_expiration_date: "30 jours avant la date d'expiration",
  validated: 'Validé',
  form_issuing_today: "Depuis l'émission aujourd'hui",
  certifications_and_proof_points_uploaded: 'Certifications et points de preuve téléchargés',
  verified_date: 'Date de vérification',
  privacy: 'Confidentialité',
  search: 'Recherche',
  view_profile: 'Voir le profil',
  user_name: "Nom d'utilisateur",
  user_profile: "Profil de l'utilisateur",
  log_out: 'Se déconnecter',
  user_details: "Détails de l'utilisateur",
  full_name: 'Nom et prénom',
  form_status: 'Statut du formulaire',
  visibility: 'Visibilité',
  image: 'Image',
  E_commerce_item_code: 'Code article du commerce électronique',
  title: 'Titre',
  supply_chain: "Chaîne d'approvisionnement",
  collection: 'Collection',
  uploaded_products: 'Produits téléchargés',
  upload_product: 'Télécharger des produits',
  new_product: 'New Product',
  additional_product_facts_form: "Formulaire d'information supplémentaire sur le produit",
  total: 'Total',
  product_title: 'Titre du produit',
  enter_sku: 'Entrez le SKU',
  e_commerce_id: 'Identifiant de commerce électronique',
  item_code: "Code de l'article",
  insert_collection: 'Insérer une collection',
  composition: 'Composition',
  care_instruction: "Instructions d'entretien",
  additional_care_info: 'Informations supplémentaires sur les soins',
  weight: 'Poids',
  colors: 'Couleurs',
  choose_category: 'Choisir une catégorie',
  price: 'Prix',
  save: 'Sauvegarder',
  enter_detail_here: 'Entrez les détails ici',
  enter_collection_name: 'Entrez le nom de la collection',
  mandatory_for_product_creation: 'Obligatoire pour la création de produit',
  mandatory_for_qr_generation: 'Obligatoire pour la génération QR',
  create_new_product: 'Créer un nouveau produit',
  attach_image: 'Joindre une image',
  upload_files: 'Télécharger des fichiers',
  upload_CSV_file: 'Télécharger le fichier CSV',
  upload_images_zip: 'Téléchargez des images ou un fichier zip',
  update_product: 'Mettre à jour le produit',
  material: 'Matériel',
  percentage: 'Pourcentage',
  add: 'Ajouter',
  qr_id: 'ID QR',
  mandatory_ecommerceId: 'Identifiant e-commerce obligatoire',
  weave: 'Tisser',
  title_is_required: 'Le titre est requis',
  vanity_title_is_required: 'Le titre de fantaisie est requis',
  weave_is_required: 'Le tissage est obligatoire',
  sku_id_is_required: "L'identifiant SKU est requis",
  ecommerce_id_is_required: 'Un identifiant de commerce électronique est requis',
  cannot_contain_slash_or_underscore: 'Ne peut pas contenir de barre oblique ou de trait de soulignement',
  no_files_selected: 'Aucun fichier sélectionné',
  invalid_file_format: 'Format de fichier invalide (supporte uniquement .jpeg, .png)',
  file_size_exceeds_limit: 'La taille du fichier dépasse la limite (max 1 Mo)',
  total_file_size_exceeds_limit: 'La taille totale du fichier dépasse la limite (max 5 Mo)',
  active: 'Actif',
  archive: 'Archive',
  download_QR: 'Télécharger le code QR',
  no_product_found: 'Aucun produit trouvé',
  these_are_the_claim_which_are_approved_pending_and_rejected_please_take_the_action_as_requested:
    "Il s'agit des réclamations approuvées, en attente et rejetées. Veuillez prendre les mesures demandées.",
  requested_category: 'Catégorie demandée',
  submit_the_detail_and_wait_for_approval: 'Soumettez les détails et attendez l’approbation',
  submitted: 'Soumis',
  set_of_questions_to_be_answered: 'Ensemble de questions auxquelles il faudra répondre',
  verify: 'Vérifier',
  categories_to_be_reviewed: 'Catégories à revoir',
  are_you_sure_you_want_to_delete_this_certificate: 'Etes-vous sûr de vouloir supprimer ce certificat ?',
  delete_certificate: 'Supprimer le certificat',
  select_the_value_you_want_to_claim_to_easily_aggregate_your_data_in_the_section_below:
    'Sélectionnez la valeur que vous souhaitez revendiquer pour agréger facilement vos données dans la section ci-dessous',
  review_categories: "Catégories d'avis",
  review: 'Revoir',
  categories: 'Catégories',
  categories_and_claims: 'Catégories et allégations',
  note_before_you_start_you_can_save_and_edit_your_questions_anytime_before_submitting:
    'Notez avant de commencer que vous pouvez enregistrer et modifier vos questions à tout moment avant de les soumettre.',
  enjoy_the_process: 'Profitez du processus.',
  eco_packaging: 'Emballage écologique',
  submit_the_detail: 'Soumettre le détail',
  claim_status: 'Statut de la réclamation',
  create_the_claim: 'Créer la revendication',
  save_claim: 'Enregistrer la réclamation',
  reject_document: 'Rejeter le document',
  reject: 'Rejeter',
  reason_for_rejection: 'Raison du rejet',
  locally_made: 'Fabriqué localement',
  reviewd_category: 'Catégorie révisée',
  document_already_uploaded: 'Document déjà téléchargé',
  select_or_enter_a_claim: 'Sélectionnez ou saisissez une réclamation',
  reason: 'Raison',
  explain_for_selecting_the_particular_status_for_the_claim: 'Expliquer la sélection du statut particulier de la réclamation',
  assign_a_label: 'Attribuer une étiquette',
  label_name: "Nom de l'étiquette",
  label_eg: "Ex. : Nom de l'étiquette",
  description: 'Description',
  assign_document: 'Attribuer un document',
  document_attach: 'Document à joindre',

  assigned_document: 'Document attribué',
  label_assigned: 'Libellé attribué',
  all: 'Tout',
  supply_chain_management: "Gestion de la chaîne d'approvisionnement",
  supplier: 'Fournisseur',
  upload_csv: 'Télécharger un fichier CSV',
  add_suppliers: 'Ajouter des fournisseurs',
  view_claims: 'Afficher les réclamations',
  add_proudcts: 'Ajouter des biens ou des services',
  create_supply_chain: "Créer une chaîne d'approvisionnement",
  suppliers_have_products_or_services: 'Les fournisseurs ont des produits ou des services',
  claim_name: 'Nom de la revendication',
  claims: 'Réclamations',
  list_of_claims: 'Liste des réclamations',
  add_supplier: 'Ajouter un fournisseur',
  name_eg: 'Ex. : Renoon',
  vanity_name: 'Nom de la vanité',
  products: 'Des produits',
  website_url: 'URL de site web',
  website_eg: 'Par exemple : https://renoon.com',
  vat_eg: 'Ex. : TVA',
  vat: 'T.V.A.',
  address: 'Adresse',
  state: 'État',
  city: 'Ville',
  region_country: 'Pays/Région',
  street: 'Rue',
  upload_images: 'Importer des images',
  contact: 'Contact',
  no_of_employee: "Nombre d'employés",
  no_of_factories: "Nombre d'usines",
  add_a_new_supplier_by_submitting_this_details: 'Ajoutez un nouveau fournisseur en soumettant ces détails',
  add_new_products: 'Ajouter de nouveaux produits',
  document: 'Document',
  select_products: 'Sélectionner des produits',
  more_custom_properties_may_be_added_like_colors_etc: 'Des propriétés plus personnalisées peuvent être ajoutées, comme les couleurs, etc.',
  they_are_not_always_relevent_and_not_needed_for_the_mvp: 'Ils ne sont pas toujours pertinents et ne sont pas nécessaires pour le MVP.',
  vanity_name_is_required: 'Le nom de la vanité est obligatoire',
  website_url_is_required: "L'URL du site Web est requise",
  vat_is_required: 'La TVA est obligatoire',
  description_is_required: 'Une description est requise',
  contact_name_is_required: 'Le nom du contact est requis',
  contact_email_is_required: "L'e-mail de contact est requis",
  please_enter_a_valid_email: 'Veuillez entrer un email valide',
  street_is_required: 'La rue est obligatoire',
  city_name_is_required: 'Le nom de la ville est obligatoire',
  country_is_required: 'Le pays est requis',
  state_is_required: "L'état est requis",
  add_new: 'Ajouter un nouveau',
  stage: 'Scène',
  sku: 'UGS',
  sku_eg: 'Ex. : SKU',
  add_new_product_or_service: 'Ajouter un nouveau produit ou service',
  stage_is_required: 'La scène est obligatoire',
  delete_supplier: 'Supprimer le fournisseur',
  are_you_sure_you_want_to_delete_this_supplier: 'Etes-vous sûr de vouloir supprimer ce fournisseur ?',
  view_claim: 'Voir la réclamation',
  search_products: 'Recherche de produits',
  create_product: 'Créer un produit',
  create_service: 'Créer un service',
  image_type: 'png, jpg, jpeg',
  are_you_sure_you_want_to_delete_this_supplier_product: 'Etes-vous sûr de vouloir supprimer ce produit fournisseur ?',
  search_services: 'Services de recherche',
  edit_supply_chain: "Modifier la chaîne d'approvisionnement",
  are_you_sure_you_want_to_delete_this_supply_chain: "Êtes-vous sûr de vouloir supprimer cette chaîne d'approvisionnement ?",
  delete_supply_Chain: "Supprimer la chaîne d'approvisionnement",
  continue: 'Continuer',
  select_the_supplier: 'Sélectionnez le fournisseur',
  edit_product: 'Modifier le produit',
  save_draft: 'Enregistrer le brouillon',
  detailed_view: 'Vue détaillée',
  they_are_not_always_relevant_and_not_needed_for_the_mvp: 'Ils ne sont pas toujours pertinents et ne sont pas nécessaires pour le MVP.',
  add_products: 'Ajouter des produits',
  select_the_supplier_product: 'Sélectionnez le produit du fournisseur',
  is_in_supply_chain: "Est dans la chaîne d'approvisionnement",
  country: 'Pays',
  has_suppliers_products: 'A les produits du fournisseur',
  sort_by: 'Trier par',
  filter: 'Filtre',
  clear_all: 'Tout effacer',
  has_products: 'A des produits',
  is_in_a_supply_chain: "Est dans une chaîne d'approvisionnement",
  service: 'Service',
  product: 'Produit',
  type: 'Type de produit',
  has_suppliers: 'A des fournisseurs',
  are_you_sure_you_want_to_delete_this_chain_product: 'Etes-vous sûr de vouloir supprimer cette chaîne Product ?',
  are_you_sure_you_want_to_delete_this_chain_supplier: 'Etes-vous sûr de vouloir supprimer cette chaîne Fournisseur ?',
  zoom_in: 'Agrandir',
  zoom_out: 'Agrandir',
  reset: 'Réinitialiser',
  no_certificate_found: 'Aucun certificat trouvé',
  reviewed_claims: 'RÉCLAMATIONS EXAMINÉES',
  view_all: 'Voir tout',
  unique_Id: 'Identifiant unique',
  view_docs: 'Afficher les documents',
  select_the_value_you_want_to_communicate: 'Sélectionnez la valeur que vous souhaitez communiquer.',
  edit_supplier: 'Modifier le fournisseur',
  submit_for_approval: 'Soumettre pour approbation',
  fill_the_forms: 'Remplissez les formulaires',
  the_forms_are_saved_automatically_You_can_submit_the_forms_once_all_the_questions_are_answered:
    'Les formulaires sont enregistrés automatiquement. Vous pouvez soumettre les formulaires une fois que toutes les questions auront été répondues.',
  opens_the_selected_document_for_reading_with_zoom_and_scroll_functionality_Allows_quick_access_without_making_changes:
    'Ouvre le document sélectionné pour la lecture avec les fonctionnalités de zoom et de défilement. Permet un accès rapide sans apporter de modifications.',
  documents: 'DOCUMENTATION',
  create_brand: 'Créer une marque',
  select_claim: 'Sélectionnez une réclamation',
  select_supplier: 'Sélectionnez le fournisseur',
  select_product: 'Sélectionnez un produit',
  filter_by: 'Filtrer par',
  additional_settings: 'Paramètres supplémentaires',
  stage_vanity_name: 'Nom de la scène',
  vanity_title: 'Titre de vanité',
  has_not_product: "N'a pas de produit",
  read_more: 'Read more',
  read_less: 'Lire moins',
  add_user: 'Ajouter un utilisateur',
  files: 'Fichiers',
  file_type: 'Type de fichier',
  deselect_all: 'Désélectionner tout',
  select_all: 'Sélectionner tout',
  is_archived: 'Est archivé',
  qr_code: 'Code QR',
  date: 'date',
  only_image_files_PNG_JPEG_JPG_are_allowed: 'Seuls les fichiers images (PNG, JPG, JPEG) sont autorisés.',
  only_PDF_files_are_allowed: 'Seuls les fichiers PDF sont autorisés',
  download_qr: 'Télécharger le code QR',

  no_category_found:"Aucune catégorie trouvée",
  yes:"Oui",
  no:"Non",
  collectionName:"Nom de la collection",
  Name:"Nom",
  Date:"Date",
  Title:"Titre",
  Stage:"Scène",
  PRODUCT:"PRODUIT",
  SERVICE:"SERVICE",
  Verified:"Vérifié",
  Proven:"Éprouvé",
  Declared:"Déclaré",
  Rejected:"Rejeté",
  additional_information:"Informations Complémentaires",
  upload:"Télécharger",
  SUPPLIER:"FOURNISSEUR",
  SUPPLIER_CHAIN:"CHAÎNE FOURNISSEUR",
  SUPPLIER_PRODUCT:"FOURNISSEUR-PRODUIT",
  BRAND_PRODUCT:"MARQUE-PRODUIT",
  Less_than_9:"Moins de 9",
  ten_to_49:"10 à 49",
  fifty_to_249:"50 à 249",
  More_than_250:"Plus de 250",
  VERIFIED:"VÉRIFIÉ",
  PROVEN:"ÉPROUVÉ",
  DECLARED:"DÉCLARÉ",
  REJECTED:"REJETÉ",
  PENDING:"EN ATTENTE"
};
export default frenchTranslation;
