import React, { useState, useEffect } from 'react';
import { Box, Checkbox, FormControlLabel, FormGroup, InputLabel, Tooltip } from '@mui/material';

function MultiSelectOption(props) {
  const { tooltip, label, options, value, handleChange } = props;

  const [checkedItems, setCheckedItems] = useState(
    options.reduce((acc, option) => {
      acc[option] = value?.includes(option);
      return acc;
    }, {})
  );

  useEffect(() => {
    setCheckedItems(
      options.reduce((acc, option) => {
        acc[option] = value?.includes(option);
        return acc;
      }, {})
    );
  }, [value, options]);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    setCheckedItems((prevCheckedItems) => {
      const updatedCheckedItems = {
        ...prevCheckedItems,
        [name]: checked,
      };

      const selectedValues = Object.keys(updatedCheckedItems).filter(
        (key) => updatedCheckedItems[key]
      );
      handleChange(selectedValues);
      return updatedCheckedItems;
    });
  };

  return (
    <Box mt={4}>
      <Tooltip title={tooltip} placement="top-start">
        <InputLabel sx={{ color: '#000', fontWeight: '600', fontSize: '14px', cursor: 'pointer' }}>
          {label}
        </InputLabel>
      </Tooltip>
      <FormGroup>
        {options.map((option) => (
          <FormControlLabel
            key={option}
            control={
              <Checkbox
                checked={checkedItems[option] || false}
                onChange={handleCheckboxChange}
                name={option}
                color="primary"
              />
            }
            label={option}
          />
        ))}
      </FormGroup>
    </Box>
  );
}

export default MultiSelectOption;
