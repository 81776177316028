import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Login from './components/login';
import Home from './components/Dashboard/home';
import Nav from './components/nav';
import User from './components/user';
import Report from './components/report';
import ClaimManagement from './components/claim_management/ClaimManagement';
import OrderEngine from './components/orderEngine';
import ProductEngine from './components/product_engine/productEngine';
import Role from './components/role';
import Brand from './components/brand';
import { Provider } from 'react-redux';
import appStore from './Store/app.store';
import ResetPassword from './components/resetPassword';
import ForgotPassword from './components/forgotPassword';
import { Box } from '@mui/material';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import PATH from './components/utils/path';
import UserProfile from './components/UserProfile';
import Settings from './components/settings';
import CreateProduct from './components/product_engine/CreateProduct';
import SupplyChain from './components/supply_chain_management/supplyChain';
import Node from './components/supply_chain_management/supplyChainGraph';
import SupplyForm from './components/supply_chain_management/SupplyForm';
import ClaimCertification from './components/claim_management/ClaimCertification';
import QuestionForm from './components/claim_management/questionForm';
import { ClientQueForm } from './components/claim_management/ClientQueForm';
import ClaimQuestions from './components/claim_management/ClaimQuestions';
import ImpactLabel from './components/impactLabel/ImpactLabel';
import CSVFiles from './components/Files/CSVFiles';
import AddProduct from './components/supply_chain_management/AddProduct';
import AddSupplierForm from './components/supply_chain_management/addSupplierForm';
import BrandProduct from './components/product_engine/BrandProduct';
import PageNotFound from './components/PageNotFound';
function App() {
  console.log(process.env.REACT_APP_DASHBOARD_API_URL);

  const router = createBrowserRouter([
    {
      path: PATH.LOGIN,
      element: <Login />,
    },
    {
      path: PATH.FORGOTPASSWORD,
      element: <ForgotPassword />,
    },
    {
      path: PATH.RESETPASSWORD,
      element: <ResetPassword />,
    },
    {
      path: PATH.NAV,
      element: <Nav />,
      children: [
        // {
        //   path: PATH.HOME,
        //   element: <Home />,
        // },
        {
          path: PATH.ROLE,
          element: <Role />,
        },
        {
          path: PATH.BRAND,
          element: <Brand />,
        },
        {
          path: PATH.USER,
          element: <User />,
        },
        {
          path: PATH.FILES,
          element: <CSVFiles />,
        },
        {
          path: PATH.USERPROFILE,
          element: <UserProfile />,
        },
        {
          path: PATH.HOME,
          element: <ClaimManagement />,
        },
        {
          path: `${PATH.QUESTIONFORM}/:id`,
          element: <QuestionForm />,
        },
        {
          path: `${PATH.CATEGORYQUESTIONS}/:id`,
          element: <ClaimQuestions />,
        },
        {
          path: PATH.CLAIMMANAGEMENTFORM,
          element: <ClientQueForm />,
        },
        {
          path: PATH.CERTIFICATE,
          element: <ClaimCertification />,
        },
        {
          path: PATH.ORDERENGINE,
          element: <OrderEngine />,
        },
        {
          path: `${PATH.PRODUCTENGINE}/:page`,
          element: <BrandProduct />,
          children: [
            {
              path: '',
              element: <ProductEngine />,
            },
            {
              path: `${PATH.CREATEPRODUCT}`,
              element: <CreateProduct />,
            },
            {
              path: `${PATH.UPDATEPRODUCT}/:id/:page`,
              element: <CreateProduct />,
            },
          ],
        },
        {
          path: `${PATH.SUPPLYCHAIN}/:page`,
          element: <SupplyChain />,
        },
        {
          path: `${PATH.ADDSUPPLIER}/:supplierId/:page`,
          element: <SupplyForm />,
          children: [
            {
              path: `${PATH.ADDSUPPLIER}/:supplierId/:page`,
              element: <AddSupplierForm />,
            },
            {
              path: `${PATH.ADDSUPPLIERPRODUCT}/:productId`,
              element: <AddProduct />,
            },
          ],
        },
        {
          path: `${PATH.SUPPLYCHAINGRAPH}/:id`,
          element: <Node />,
        },
        {
          path: PATH.IMPACTLABEL,
          element: <ImpactLabel />,
        },
        {
          path: PATH.REPORT,
          element: <Report />,
        },
        {
          path: PATH.SETTINGS,
          element: <Settings />,
        },
      ],
    },
    {
      path: '*',
      element: <PageNotFound />,
    },
  ]);

  return (
    <Box>
      <Provider store={appStore}>
        <RouterProvider router={router} />
      </Provider>
    </Box>
  );
}

export default App;
