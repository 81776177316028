import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import httpService from '../service/http.service';
import { RestUrlsConstants } from '../constants/rest-urls.constants';
import { RolesStatus } from '../constants/common.constants';
import PATH from '../components/utils/path';

const useLoginHook = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [snackBar, setsnackBar] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    message: '',
  });
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClose = () => setsnackBar({ ...snackBar, open: false });
  const handleMouseDownPassword = (event) => event.preventDefault();
  const email = useRef(null);
  const password = useRef(null);
  const navigate = useNavigate();

  const signIn = async () => {
    if (email.current.value && password.current.value) {
      try {
        const login = await httpService.post(RestUrlsConstants.loginUrl, {
          email: email.current.value,
          password: password.current.value,
        });
        setsnackBar({ ...snackBar, open: true, message: login?.data?.message });
        localStorage.setItem('Authorization', login?.data?.data?.token);
        localStorage.setItem('resources', JSON.stringify(login?.data?.data?.resources));
        navigate(`${PATH.HOME}`);
        // if (login?.data?.data?.userType === RolesStatus.system) {
        //   navigate('/');
        // } else {
        //   navigate(`${PATH.HOME}`);
        // }
      } catch (e) {
        setsnackBar({ ...snackBar, open: true, message: e?.response?.data?.message });
      }
    }
  };

  return { showPassword, snackBar, email, password, handleClickShowPassword, handleClose, handleMouseDownPassword, signIn };
};

export default useLoginHook;
