// apiSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '../service/http.service';
import { RestUrlsConstants } from '../constants/rest-urls.constants';

const initialState = {
  chainById: {},
  loading: false,
  error: null,
};

export const fetchChainById = createAsyncThunk('supply-chain/byId', async (id, { rejectWithValue }) => {
  try {
    const response = await httpService.get(`${RestUrlsConstants.supplyChain}/${id}`, {
      headers: {
        Authorization: localStorage.getItem('Authorization'),
      },
    });
    return response?.data?.data;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

const supplyChaiById = createSlice({
  name: 'chainId',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchChainById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchChainById.fulfilled, (state, action) => {
        state.loading = false;
        state.chainById = action.payload;
      })
      .addCase(fetchChainById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default supplyChaiById.reducer;
